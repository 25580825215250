import React, { useState, useEffect, useMemo, useRef } from "react";
import Select from "react-select";
import { getBankDetails, deleteCashTransaction } from "../../../actions/shg";
import { connect } from "react-redux";

const BankDetTab = ({
  membersMeetingData,
  activeBank,
  PrevCashInHand,
  batchData,
  NextBackBtn,
  formData,
  newBankBalanceTotal,
  setNewTrailBankBalances,
  setNewBankBalance,
  prevBankBalance,
  setFormData,
  getBankDetails,
  deleteCashTransaction,
}) => {
  let {
    cashInHand,
    //Expense
    travellingExpenses, //bc
    paidToMahasangha, //bc
    stationaryExpenses, //bc
    serviceCharges, //bc
    batchDividendDistributed, //bc
    otherExpenses, //bc
    loanGivenToOtherSHG, //bc
    batchSavingWithdrawals, //bc
    batchSecurityDeposit, //bc
    donationToOutsider, //bc
    otherLoanBatchTransaction, //Array //Exp batchOtherLoanInterestDonor subsidyDistributed otherLoanPaidToDonor   //Income otherLoanSanctionedToShg
    sdSHGToUnion,

    //Income
    batchSavingAmt, //bc
    batchSubAmt, //bc
    batchOtherContribution, //bc
    batchMembershipFees, //bc
    batchLoanPaid, //bc
    batchLoanAmt,
    batchInterestPaid, //bc

    otherLoanPaidToShg, //only
    batchOtherLoanInterestShg, //only

    deactiveMemberSaving, //bc
    subsidyReceived, //bc
    batchOtherIncome, //bc
    seedMoney, //bc
    loanRepaidByOtherSHG, //bc
    batchSecurityDepositMemToShg, //bc
    sdUnionToSHG, //bc
    otherLoanSanctionedToMember,
  } = formData;

  ///////////////////////////////////////tooltip for income & expense start////////////////////////////////////////////////
  // Creating arrays to hold income and expense details
  const [showTooltip, setShowTooltip] = useState(false);
  const [curNewBankBalanceTotal, setCurNewBankBalanceTotal] = useState(0);

  const incomeArray = [
    {
      fieldName: "Interest Paid",
      bankAmt: formData.batchInterestPaid.bankAmt,
      cash: formData.batchInterestPaid.cash,
      bankName: formData.batchInterestPaid.bankName || "",
    },
    {
      fieldName: "Loan Paid",
      bankAmt: formData.batchLoanPaid.bankAmt,
      cash: formData.batchLoanPaid.cash,
      bankName: formData.batchLoanPaid.bankName || "",
    },
    {
      fieldName: "Membership Fees",
      bankAmt: formData.batchMembershipFees.bankAmt,
      cash: formData.batchMembershipFees.cash,
      bankName: formData.batchMembershipFees.bankName || "",
    },
    {
      fieldName: "Other Contribution",
      bankAmt: formData.batchOtherContribution.bankAmt,
      cash: formData.batchOtherContribution.cash,
      bankName: formData.batchOtherContribution.bankName || "",
    },
    {
      fieldName: "OL Paid To SHG",
      bankAmt: otherLoanBatchTransaction.reduce(
        (acu, cur) => (acu += Number(cur.otherLoanPaidToShg.bankAmt || 0)),
        0
      ),
      cash: otherLoanBatchTransaction.reduce(
        (acu, cur) => (acu += Number(cur.otherLoanPaidToShg.cash || 0)),
        0
      ),
      bankName: "",
    },
    {
      fieldName: "OL Interest Paid To SHG",
      bankAmt: otherLoanBatchTransaction.reduce(
        (acu, cur) => (acu += Number(cur.otherLoanInterestShg.bankAmt || 0)),
        0
      ),
      cash: otherLoanBatchTransaction.reduce(
        (acu, cur) => (acu += Number(cur.otherLoanInterestShg.cash || 0)),
        0
      ),
      bankName: "",
    },
    {
      fieldName: "Other Income",
      bankAmt: formData.batchOtherIncome.bankAmt,
      cash: formData.batchOtherIncome.cash,
      bankName: formData.batchOtherIncome.bankName || "",
    },
    {
      fieldName: "Saving Amount",
      bankAmt: formData.batchSavingAmt.bankAmt,
      cash: formData.batchSavingAmt.cash,
      bankName: formData.batchSavingAmt.bankName || "",
    },
    {
      fieldName: "SD Member-SHG",
      bankAmt: formData.batchSecurityDepositMemToShg.bankAmt,
      cash: formData.batchSecurityDepositMemToShg.cash,
      bankName: formData.batchSecurityDepositMemToShg.bankName || "",
    },
    {
      fieldName: "Subscription Amount",
      bankAmt: formData.batchSubAmt.bankAmt,
      cash: formData.batchSubAmt.cash,
      bankName: formData.batchSubAmt.bankName || "",
    },
    {
      fieldName: "Deactive Member Saving",
      bankAmt: formData.deactiveMemberSaving.bankAmt,
      cash: formData.deactiveMemberSaving.cash,
      bankName: formData.deactiveMemberSaving.bankName || "",
    },
    {
      fieldName: "Loan Repaid By Other SHG",
      bankAmt: formData.loanRepaidByOtherSHG.bankAmt,
      cash: formData.loanRepaidByOtherSHG.cash,
      bankName: formData.loanRepaidByOtherSHG.bankName || "",
    },
    {
      fieldName: "SD Union-SHG",
      bankAmt: formData.sdUnionToSHG.bankAmt,
      cash: formData.sdUnionToSHG.cash,
      bankName: formData.sdUnionToSHG.bankName || "",
    },
    {
      fieldName: "Seed Money",
      bankAmt: formData.seedMoney.bankAmt,
      cash: formData.seedMoney.cash,
      bankName: formData.seedMoney.bankName || "",
    },
    {
      fieldName: "Subsidy Received",
      bankAmt: 0,
      bankName: "",
      cash: formData.subsidyReceived,
    },
    {
      fieldName: "Loan Repaid By Other SHG",
      bankAmt:  formData.loanRepaidByOtherSHG.bankAmt,
      bankName: formData.loanRepaidByOtherSHG.bankName || "",
      cash: formData.loanRepaidByOtherSHG.cash,
    },
    // Assuming subsidyReceived has no bankAmt
  ];

  const expenseArray = [
    {
      fieldName: "Dividend Distributed",
      bankAmt: formData.batchDividendDistributed.bankAmt,
      cash: formData.batchDividendDistributed.cash,
      bankName: formData.batchDividendDistributed.bankName || "",
    },
    {
      fieldName: "IL Sanctioned Principal",
      bankAmt: formData.batchLoanAmt.bankAmt,
      cash: formData.batchLoanAmt.cash,
      bankName: formData.batchLoanAmt.bankName || "",
    },
    {
      fieldName: "Loan Given To Other SHG",
      bankAmt: formData.loanGivenToOtherSHG.bankAmt,
      cash: formData.loanGivenToOtherSHG.cash,
      bankName: formData.loanGivenToOtherSHG.bankName || "",
    },
    {
      fieldName: "Saving Withdrawals",
      bankAmt: formData.batchSavingWithdrawals.bankAmt,
      cash: formData.batchSavingWithdrawals.cash,
      bankName: formData.batchSavingWithdrawals.bankName || "",
    },
    {
      fieldName: "SD SHG-Member ",
      bankAmt: formData.batchSecurityDeposit.bankAmt,
      cash: formData.batchSecurityDeposit.cash,
      bankName: formData.batchSecurityDeposit.bankName || "",
    },
    {
      fieldName: "Donation To Outsider",
      bankAmt: formData.donationToOutsider.bankAmt,
      cash: formData.donationToOutsider.cash,
      bankName: formData.donationToOutsider.bankName || "",
    },
    {
      fieldName: "Other Expense",
      bankAmt: formData.otherExpenses.bankAmt,
      cash: formData.otherExpenses.cash,
      bankName: formData.otherExpenses.bankName || "",
    },
    {
      fieldName: "Paid To Mahasanga",
      bankAmt: formData.paidToMahasangha.bankAmt,
      cash: formData.paidToMahasangha.cash,
      bankName: formData.paidToMahasangha.bankName || "",
    },
    {
      fieldName: "SD SHG-Union",
      bankAmt: formData.sdSHGToUnion.bankAmt,
      cash: formData.sdSHGToUnion.cash,
      bankName: formData.sdSHGToUnion.bankName || "",
    },
    {
      fieldName: "Service Charges",
      bankAmt: formData.serviceCharges.bankAmt,
      cash: formData.serviceCharges.cash,
      bankName: formData.serviceCharges.bankName || "",
    },
    {
      fieldName: "Stationary Expense",
      bankAmt: formData.stationaryExpenses.bankAmt,
      cash: formData.stationaryExpenses.cash,
      bankName: formData.stationaryExpenses.label || "",
    },
    {
      fieldName: "Subsidy Distributed",
      bankAmt: 0,
      cash: formData.subsidyDistributed,
      bankName: "",
    }, // Assuming subsidyDistributed has no bankAmt
    {
      fieldName: "Travelling Expense",
      bankAmt: formData.travellingExpenses.bankAmt,
      cash: formData.travellingExpenses.cash,
      bankName: formData.travellingExpenses.label || "",
    },
    {
      fieldName: "OL Principal Paid To Donor",
      bankAmt: 0,
      cash: formData.otherLoanPaidToDonor,
      bankName: formData.travellingExpenses.label || "",
    },
    {
      fieldName: "OL Interest Paid To Donor",
      bankAmt: 0,
      cash: formData.batchOtherLoanInterestDonor,
      bankName: "",
    },
  ];

  const [isVisible, setIsVisible] = useState(false);
  const [isExpenseVisible, setIsExpenseVisible] = useState(false);
  const tooltipRef = useRef(null);

  // Close tooltip if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current) {
        setIsVisible(false);
        setIsExpenseVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const showDetails = () => {
    setIsVisible(!isVisible);
  };
  const showExpenseDetails = () => {
    setIsExpenseVisible(!isExpenseVisible);
  };

  const Incomearray = (data) => {
    if (data.data === "Income") {
      return (
        <div className="tooltipFroIncome_Expense-Income_Expensecontainer">
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            onClick={showDetails}
            title="Income Transaction Breakdown"
          />
          {isVisible && (
            <div className="tooltipFroIncome_Expense">
              <table
                className="tabllll table table-bordered table-striped table-hover"
                id="datatable2"
              >
                <thead>
                  <th colSpan={4}>Income</th>
                  <tr>
                    <th>Fields</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {incomeArray.map((item, index) => {
                    if (item.bankAmt != 0 || item.cash != 0) {
                      return (
                        <tr key={index}>
                          <td>{item.fieldName}</td>
                          <td>
                            {(Number(item.bankAmt) || 0) +
                              Number(item.cash || 0)}
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="tooltipFroIncome_Expense-Income_Expensecontainer">
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            onClick={showExpenseDetails}
            title="Expense Transaction Breakdown"
          />
          {isExpenseVisible && (
            <div className="tooltipFroIncome_Expense">
              <table
                className="tabllll table table-bordered table-striped table-hover"
                id="datatable2"
              >
                <thead>
                  <th colSpan={4}>Expense</th>
                  <tr>
                    <th>Fields</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {expenseArray.map((item, index) => {
                    if (item.bankAmt != 0 || item.cash != 0) {
                      return (
                        <tr key={index}>
                          <td>{item.fieldName}</td>
                          <td>
                            {Number(item.bankAmt || 0) + Number(item.cash || 0)}
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      );
    }
  };

  // useEffect(()=>{
  //   getBankDetails({mdBatchId:formData.batchId, mdDate:formData.batchMeetingHeldOnDate})
  // },[])

  const [DBBankArray, setDBBankArray] = useState(
    formData.bankTransactions || []
  );

 

  const [localBankDet, setlocalBankDet] = useState({
    // batchBankNameId: null,
    // batchBankName: null,
    batchBankNameObj: "",
    indBankInterest: "",
    indBankCommission: "",
    indBatchBankDeposit: "",
    indBatchBankWithdrawals: "",
    transactionType: "C",
    otherLoanTypeId: "",
  });
  let {
    batchBankNameObj,
    indBankInterest,
    indBankCommission,
    indBatchBankDeposit,
    indBatchBankWithdrawals,
  } = localBankDet;

  const totalLoans = (MemberLoanArray) => {
    try {
      let intLoanBank = 0;
      let intLoanCash = 0;
      let othLoanBank = 0;
      let othLoanCash = 0;

      let othPriPaidBank = 0;
      let othPriPaidCash = 0;

      let othIntPaidBank = 0;
      let othIntPaidCash = 0;

      MemberLoanArray.map((ele) => {
        intLoanBank += ele.loanTakenAmt.bankAmt;
        intLoanCash += ele.loanTakenAmt.cash;

        othLoanBank += ele.otherLoan.reduce(
          (acu, cur) => (acu += Number(cur.otherLoanTakenAmt.bankAmt)),
          0
        );

        othLoanCash += ele.otherLoan.reduce(
          (acu, cur) => (acu += Number(cur.otherLoanTakenAmt.cash)),
          0
        );

        othPriPaidBank += ele.otherLoan.reduce(
          (acu, cur) => (acu += Number(cur.otherLoanPaid.bankAmt)),
          0
        );
        othPriPaidCash += ele.otherLoan.reduce(
          (acu, cur) => (acu += Number(cur.otherLoanPaid.cash)),
          0
        );

        othIntPaidBank += ele.otherLoan.reduce(
          (acu, cur) => (acu += Number(cur.otherInterestPaid.bankAmt)),
          0
        );

        othIntPaidCash += ele.otherLoan.reduce(
          (acu, cur) => (acu += Number(cur.otherInterestPaid.cash)),
          0
        );
      });

      return {
        intLoanBank: intLoanBank,
        intLoanCash: intLoanCash,

        othLoanBank: othLoanBank,
        othLoanCash: othLoanCash,

        othLoanPriPaidBank: othPriPaidBank,
        othLoanPriPaidCash: othPriPaidCash,
        otherLoanIntPaidBank: othIntPaidBank,
        otherLoanIntPaidCash: othIntPaidCash,
      };
      //   MemberLoanArray.
    } catch (er) {
      console.log(er);
    }
  };

  const Loandata = totalLoans(membersMeetingData);

  const [deletedData, setDeletedData] = useState("");
  const onInputChange = (e) => {
    if (deletedData) {
      setDBBankArray(
        DBBankArray &&  DBBankArray.filter(
          (ele) =>
            !(
              ele.transactionType === "C" &&
              ele.batchBankName === deletedData.batchBankName &&
              ele.indBankInterest === deletedData.indBankInterest &&
              ele.indBankCommission === deletedData.indBankCommission &&
              ele.indBatchBankDeposit === deletedData.indBatchBankDeposit &&
              ele.indBatchBankWithdrawals ===
                deletedData.indBatchBankWithdrawals
            )
        )
      );
      setFormData({
        ...formData,
        bankTransactions: DBBankArray,
        [e.target.name]: e.target.value,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };



  const totExpenseCash =
  Number(batchLoanAmt ? batchLoanAmt.cash : 0)+
    Number(travellingExpenses ? travellingExpenses.cash : 0) +
    Number(paidToMahasangha ? paidToMahasangha.cash : 0) +
    Number(stationaryExpenses ? stationaryExpenses.cash : 0) +
    Number(serviceCharges ? serviceCharges.cash : 0) +
    Number(batchDividendDistributed ? batchDividendDistributed.cash : 0) +
    Number(otherExpenses ? otherExpenses.cash : 0) +
    Number(loanGivenToOtherSHG ? loanGivenToOtherSHG.cash : 0) +
    Number(batchSavingWithdrawals ? batchSavingWithdrawals.cash : 0) +
    Number(batchSecurityDeposit ? batchSecurityDeposit.cash : 0) +
    Number(donationToOutsider ? donationToOutsider.cash : 0) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanInterestDonor.cash)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.subsidyDistributed.cash)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanPaidToDonor.cash)),
      0
    ) +
    Number(sdSHGToUnion ? sdSHGToUnion.cash : 0);

  const totExpenseBank =
    Number(batchLoanAmt ? batchLoanAmt.bankAmt : 0)+
    Number(travellingExpenses ? travellingExpenses.bankAmt : 0) +
    Number(paidToMahasangha ? paidToMahasangha.bankAmt : 0) +
    Number(stationaryExpenses ? stationaryExpenses.bankAmt : 0) +
    Number(serviceCharges ? serviceCharges.bankAmt : 0) +
    Number(batchDividendDistributed ? batchDividendDistributed.bankAmt : 0) +
    Number(otherExpenses ? otherExpenses.bankAmt : 0) +
    Number(loanGivenToOtherSHG ? loanGivenToOtherSHG.bankAmt : 0) +
    Number(batchSavingWithdrawals ? batchSavingWithdrawals.bankAmt : 0) +
    Number(batchSecurityDeposit ? batchSecurityDeposit.bankAmt : 0) +
    Number(donationToOutsider ? donationToOutsider.bankAmt : 0) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanInterestDonor.bankAmt || 0)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.subsidyDistributed.bankAmt || 0)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanPaidToDonor.bankAmt || 0)),
      0
    ) +
    Number(sdSHGToUnion ? sdSHGToUnion.bankAmt : 0);

  const totIncomeCash =
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanPaidToShg.cash || 0)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanInterestShg.cash || 0)),
      0
    ) +
    Number(batchSavingAmt ? batchSavingAmt.cash : 0) +
    Number(batchSubAmt ? batchSubAmt.cash : 0) +
    Number(
      batchOtherContribution
        ? batchOtherContribution.cash != undefined
          ? batchOtherContribution.cash
          : 0
        : 0
    ) +
    Number(batchMembershipFees ? batchMembershipFees.cash : 0) +
    Number(batchLoanPaid ? batchLoanPaid.cash : 0) +
    Number(batchInterestPaid ? batchInterestPaid.cash : 0) +
    Number(deactiveMemberSaving ? deactiveMemberSaving.cash : 0) +
    Number(
      otherLoanBatchTransaction.reduce(
        (acu, cur) => (acu += Number(cur.subsidyReceived.cash || 0)),
        0
      )
    ) +
    Number(
      batchOtherIncome
        ? batchOtherIncome.cash != undefined
          ? batchOtherIncome.cash
          : 0
        : 0
    ) +
    Number(seedMoney ? (seedMoney.cash != undefined ? seedMoney.cash : 0) : 0) +
    Number(loanRepaidByOtherSHG ? loanRepaidByOtherSHG.cash : 0) +
    Number(
      batchSecurityDepositMemToShg ? batchSecurityDepositMemToShg.cash : 0
    ) +
    Number(sdUnionToSHG ? sdUnionToSHG.cash : 0);

  const totIncomeBank =
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanPaidToShg.bankAmt || 0)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanInterestShg.bankAmt || 0)),
      0
    ) +
    Number(batchSavingAmt ? batchSavingAmt.bankAmt : 0) +
    Number(batchSubAmt ? batchSubAmt.bankAmt : 0) +
    Number(
      batchOtherContribution
        ? batchOtherContribution.bankAmt != undefined
          ? batchOtherContribution.bankAmt
          : 0
        : 0
    ) +
    Number(batchMembershipFees ? batchMembershipFees.bankAmt : 0) +
    Number(batchLoanPaid ? batchLoanPaid.bankAmt : 0) +
    Number(batchInterestPaid ? batchInterestPaid.bankAmt : 0) +
    Number(deactiveMemberSaving ? deactiveMemberSaving.bankAmt : 0) +
    Number(
      otherLoanBatchTransaction.reduce(
        (acu, cur) => (acu += Number(cur.subsidyReceived.bankAmt || 0)),
        0
      )
    ) +
    Number(
      batchOtherIncome
        ? batchOtherIncome.bankAmt != undefined
          ? batchOtherIncome.bankAmt
          : 0
        : 0
    ) +
    Number(
      seedMoney ? (seedMoney.bankAmt != undefined ? seedMoney.bankAmt : 0) : 0
    ) +
    Number(loanRepaidByOtherSHG ? loanRepaidByOtherSHG.bankAmt : 0) +
    Number(
      batchSecurityDepositMemToShg ? batchSecurityDepositMemToShg.bankAmt : 0
    ) +
    Number(sdUnionToSHG ? sdUnionToSHG.bankAmt : 0);

  const [errorForBankTab, setErrorForBankTab] = useState("");

  const finAddBankDet = () => {
    // if (CashInHand !== 0 && DBBankArray.length == 0) {
    //   setErrorForBankTab(
    //     "Please update the cash in hand and/or bank details"
    //   );
    // } else {
    setErrorForBankTab("");
    setFormData({
      ...formData,
      cashInHand: CashInHand,
      bankTransactions: DBBankArray,
      bankInterest: DBBankArray && DBBankArray.reduce(
        (ac, cur) => (ac += Number(cur.indBankInterest)),
        0
      ),
      bankCommission:DBBankArray &&  DBBankArray.reduce(
        (ac, cur) => (ac += Number(cur.indBankCommission)),
        0
      ),
      batchBankDeposit:DBBankArray &&  DBBankArray.reduce(
        (ac, cur) => (ac += Number(cur.indBatchBankDeposit)),
        0
      ),
      batchBankWithdrawals: DBBankArray && DBBankArray.reduce(
        (ac, cur) => (ac += Number(cur.indBatchBankWithdrawals)),
        0
      ),
    });

    NextBackBtn(9);
    // }
    localStorage.removeItem("DeletedOLData");
  };

  let bankDeposits =DBBankArray &&  DBBankArray.filter((ele) => ele.transactionType === "C" || ele.transactionType === "" || ele.transactionType === undefined)
    .map((ele) => ele.indBatchBankDeposit)
    .reduce((acc, curr) => Number(acc) + Number(curr), 0);

  let bankWithdrawals = DBBankArray && DBBankArray.filter((ele)=>ele.transactionType==="C").map((ele) => ele.indBatchBankWithdrawals)
    .reduce((acc, curr) => Number(acc) + Number(curr), 0);  //applying this for withdraw < finalBankBalance condition bcz we only need to compare the C transaction typoe with new bankBalance

  //creating new bankWithsra bcz the above condition is not satisfying for matching only C transaction type
  let bankWithDraw = DBBankArray && DBBankArray.map((ele) => ele.indBatchBankWithdrawals)
    .reduce((acc, curr) => Number(acc) + Number(curr), 0);//.filter((ele)=>ele.transactionType==="C") removing filter bcz iam not able to insert the withdraw amount that us bcz iam caluclting only C type and matching it with new BankBlance

  const [CashInHand, setCashInhand] = useState(
    formData.cashInHand
      ? formData.cashInHand
      :DBBankArray &&  DBBankArray.find(
          (ele) =>
            (ele.transactionType === "C" || ele.transactionType === "" || ele.transactionType === undefined) &&
            ele.indBatchBankDeposit ==
              (PrevCashInHand || 0) +
                (Number(totIncomeCash) - Number(totExpenseCash))
        )
      ? 0
      : (PrevCashInHand || 0) + (Number(totIncomeCash) - Number(totExpenseCash))
  );


  useEffect(() => {
    const mergeBankData = () => {
      const combinedMap = new Map();

      // Function to add data to the map
      const addDataToMap = (array, transactionType) => {
        array.forEach((entry) => {
          if (
            !entry.bankId ||
            !entry.batchBankName ||
            !entry.batchBankNameId ||
            (!entry.indBatchBankDeposit && entry.indBatchBankDeposit !== 0) ||
            (!entry.indBatchBankWithdrawals && entry.indBatchBankWithdrawals !== 0 )
          ) {
            return;
          }

          const key = `${entry.batchBankNameId}-${transactionType}`;
          if (combinedMap.has(key)) {
            const existingEntry = combinedMap.get(key);
            if (

              existingEntry.bankName !== entry.bankName ||
              existingEntry.indBatchBankDeposit !== entry.indBatchBankDeposit ||
              existingEntry.indBatchBankWithdrawals !==
                entry.indBatchBankWithdrawals
            ) {
              combinedMap.set(key, {
                ...entry,
                transactionType, // Maintain original transaction type
              });
            }
          } else {
            combinedMap.set(key, {
              ...entry,
              transactionType,
            });
          }
        });
      };

      const {
        bankTransactions,
        batchIncomeAndExpenseArray,
        memberBankDetails,
        combinedOLRepaymentArray,
      } = formData;
      if (bankTransactions) {
        bankTransactions.forEach((entry) => {
          if (
            !entry.batchBankName ||
            !entry.batchBankNameId ||
            (!entry.indBatchBankDeposit && entry.indBatchBankDeposit !== 0)
            //  ||
            // (!entry.indBatchBankWithdrawals )
          ) {
            return;
          }
          const key = `${entry.batchBankNameId}-${entry.transactionType}-${entry._id}-${entry.indBatchBankDeposit}-${entry.indBatchBankWithdrawals}`;
          combinedMap.set(key, {
            ...entry,
            transactionType: entry.transactionType, // Maintain original transaction type
          });
        });
      }

      if (batchIncomeAndExpenseArray) {
        addDataToMap(batchIncomeAndExpenseArray, "B (I/E)");
      }
      if (memberBankDetails) {
        addDataToMap(memberBankDetails, "M");
      }
      if (combinedOLRepaymentArray) {
        addDataToMap(combinedOLRepaymentArray, "B (OL)");
      }
      const mergedArray = Array.from(combinedMap.values());

      ////////////////////////////////////////new bankBalace calculation start/////////////////////////
      // Now, calculate the amounts from the mergedArray
      let totalDeposit = 0;
      let totalWithdrawals = 0;
      let totalInterest = 0; // Add more if needed
      let totalCommission = 0;

      mergedArray.forEach((entry) => {
        if (entry.indBatchBankDeposit) {
          totalDeposit += Number(entry.indBatchBankDeposit || 0);
        }
        if (entry.indBatchBankWithdrawals) {
          totalWithdrawals += Number(entry.indBatchBankWithdrawals || 0);
        }
        if (entry.indBankInterest) {
          totalInterest += Number(entry.indBankInterest || 0); // Assuming Interest is part of the entry
        }
        if (entry.indBankCommission) {
          totalCommission += Number(entry.indBankCommission || 0); // Assuming Commission is part of the entry
        }
      });

      const calculatedNewBankBalanceTotal = (
        (Number(totalDeposit || 0)  + Number(totalInterest || 0) ) + (Number(prevBankBalance || 0)) - (totalCommission  + totalWithdrawals )
      ); 

      // Set the calculated balance in the state  
        setNewTrailBankBalances(calculatedNewBankBalanceTotal || 0);   
        setCurNewBankBalanceTotal(calculatedNewBankBalanceTotal || 0);
      //////////////////////////////////////new bankbalanace calculation end/////////////////////////
      setDBBankArray(mergedArray);
    };

    mergeBankData();
    let bankWithdr = DBBankArray && DBBankArray.filter((ele)=>ele.transactionType === "C").map((ele) => ele.indBatchBankWithdrawals)
    .reduce((acc, curr) => Number(acc) + Number(curr), 0); //this is required bca to calculate the CashInHand properly
    

    let netTotal =
    Number(totIncomeCash) -
    Number(totExpenseCash) +
    Number(PrevCashInHand || 0);



    setCashInhand((Number(netTotal) - Number(bankDeposits || 0)) + Number(bankWithdr));

  }, [formData]);

  const onAddBankDet = async () => {
    setDBBankArray([
      ...DBBankArray,
      {
        bankId:localBankDet.batchBankNameObj.bankId,
        batchBankNameId: localBankDet.batchBankNameObj.bankId,
        batchBankName: localBankDet.batchBankNameObj.label,
        indBankInterest:localBankDet.indBankInterest ? localBankDet.indBankInterest : 0 ,
        indBankCommission:localBankDet.indBankCommission ? localBankDet.indBankCommission : 0 ,
        indBatchBankDeposit:localBankDet.indBatchBankDeposit ? localBankDet.indBatchBankDeposit : 0 ,
        indBatchBankWithdrawals:localBankDet.indBatchBankWithdrawals ? localBankDet.indBatchBankWithdrawals : 0 ,
        transactionType:localBankDet.transactionType
        // ...localBankDet,
      },
    ]);

    let Interest = Number(localBankDet.indBankInterest || 0);
    let Commission = Number(localBankDet.indBankCommission || 0);
    let BankDeposit = Number(localBankDet.indBatchBankDeposit || 0);
    let BankWithdrawals = Number(localBankDet.indBatchBankWithdrawals || 0);
    let bankDeposits =DBBankArray &&  DBBankArray.filter((ele) => ele.transactionType === "C")

    let bankWithdrawals = DBBankArray && DBBankArray.map((ele) => ele.indBatchBankWithdrawals)
    .reduce((acc, curr) => Number(acc) + Number(curr), 0);

    let comm = DBBankArray && DBBankArray.map((ele) => ele.indBankCommission)
    .reduce((acc, curr) => Number(acc) + Number(curr), 0);
    let inter = DBBankArray && DBBankArray.map((ele) => ele.indBankInterest)
    .reduce((acc, curr) => Number(acc) + Number(curr), 0);
    let depost = DBBankArray && DBBankArray.map((ele) => ele.indBatchBankDeposit)
    .reduce((acc, curr) => Number(acc) + Number(curr), 0);

    let newDeposite = Number(depost) + Number(BankDeposit);
    let newWithdraw = Number(bankWithdrawals) + Number(BankWithdrawals);
    let newInterest = Number(inter) + Number(Interest);
    let newCommission = Number(comm) + Number(Commission)
    
    setNewTrailBankBalances(
      (
        // Number(bankDeposits || 0) +  //commented this bcz of new bank total calculation
       (
        Number(prevBankBalance)
        + 
        newDeposite + Number(newInterest)) -(Number(newCommission) + Number(newWithdraw))) || 0
    );
    setCurNewBankBalanceTotal(
      Number(prevBankBalance) +
        Number(BankDeposit) +
        Number(Interest) +
        Number(Commission) -
        Number(BankWithdrawals)
    );
    let bankWithdr = DBBankArray && DBBankArray.filter((ele)=>ele.transactionType === "C").map((ele) => ele.indBatchBankWithdrawals)
    .reduce((acc, curr) => Number(acc) + Number(curr), 0); //this is required bca to calculate the CashInHand properly
    

    setCashInhand((PrevCashInHand || 0)  + (Number(totIncomeCash) - Number(totExpenseCash)) + Number(bankWithdr) +  Number(localBankDet.indBatchBankWithdrawals || 0) - Number(BankDeposit || 0)  )


    setlocalBankDet({
      batchBankNameObj: "",
      indBankInterest: 0,
      indBankCommission: 0,
      indBatchBankDeposit: 0,
      indBatchBankWithdrawals: 0,
    });
  };


  const [withdrawDisable, setWithDrawDisable] = useState(false);
  let bankWith = DBBankArray && DBBankArray.map((ele) => ele.indBatchBankWithdrawals)
  .reduce((acc, curr) => Number(acc) + Number(curr), 0); //this is needed bcz to update the withdrawable amount as per new bankBanalance
  const [finalBankBalance,setFinalBankBalance] = useState(Number(prevBankBalance || 0) + Number(bankDeposits || 0) - Number(bankWith) )


  const inputBankDetChange = (e, selectedName = null) => {
    let netTotal =
      Number(totIncomeCash) -
      Number(totExpenseCash) +
      Number(PrevCashInHand || 0);


      
  let bankWithdr = DBBankArray && DBBankArray.filter((ele) => ele.transactionType === "C")
  .map((ele) => ele.indBatchBankWithdrawals)
  .reduce((acc, curr) => Number(acc) + Number(curr), 0);


    let withdrawTotal = Number(finalBankBalance || 0);

    if (selectedName) {
      setlocalBankDet({
        ...localBankDet,
        batchBankNameObj: e,
        transactionType: "C",
        // batchBankNameId: e.bankId,
        // batchBankName: e.label,
      });
      document.getElementById("selBanks").style = "none";
    } else {

      const regex = /^\d*\.?\d{0,2}$/;

      if (regex.test(e.target.value)) {
        setlocalBankDet({
          ...localBankDet,
          [e.target.name]: Number(e.target.value || 0) ,
        });
      }
     
      if (e.target.name === "indBatchBankDeposit") {
        if(CashInHand < 0){

          setCashInhand((Number(netTotal) - Number(bankDeposits || 0) + Number(bankWithdr || 0) - Number(e.target.value)));
        }else{
          setCashInhand(((Number(netTotal) - Number(bankDeposits || 0)) + Number(bankWithdr || 0) - Number(e.target.value)));

        }
      }

      if (e.target.name === "indBatchBankWithdrawals") {
        if (e.target.value >= withdrawTotal) {
          setWithDrawDisable(true);
        } else {
          setWithDrawDisable(false);
        }
      }
    }
  };

  const onDeleteBankDet = (data) => {
    let Interest = Number(data.indBankInterest);
    let Commission = Number(data.indBankCommission);
    let BankDeposit = Number(data.indBatchBankDeposit);
    let BankWithdrawals = Number(data.indBatchBankWithdrawals);
    let transactionType = data.transactionType;

    let bankDeposits =
      DBBankArray &&
      DBBankArray.map((ele) => ele.indBatchBankDeposit).reduce(
        (acc, curr) => Number(acc) + Number(curr),
        0
      );
    let withdraw =
      DBBankArray &&
      DBBankArray.map((ele) => ele.indBatchBankWithdrawals).reduce(
        (acc, curr) => Number(acc) + Number(curr),
        0
      );
    let commision =
      DBBankArray &&
      DBBankArray.map((ele) => ele.indBankCommission).reduce(
        (acc, curr) => Number(acc) + Number(curr),
        0
      );
    let interest =
      DBBankArray &&
      DBBankArray.map((ele) => ele.indBankInterest).reduce(
        (acc, curr) => Number(acc) + Number(curr),
        0
      );

    if (data.transactionType === undefined) {
      setDBBankArray(
        DBBankArray &&
          DBBankArray.filter(
            (ele) =>
              !(
                ele.batchBankNameId === data.batchBankNameId &&
                ele.batchBankName === data.batchBankName &&
                ele.indBankInterest === data.indBankInterest &&
                ele.indBankCommission === data.indBankCommission &&
                ele.indBatchBankDeposit === data.indBatchBankDeposit &&
                ele.indBatchBankWithdrawals === data.indBatchBankWithdrawals
              )
          )
      );
    } else {
      setDeletedData(data);
      setDBBankArray(
        DBBankArray &&
          DBBankArray.filter(
            (ele) =>
              !(
                ele.transactionType === "C" &&
                ele.batchBankName === data.batchBankName &&
                ele.indBankInterest === data.indBankInterest &&
                ele.indBankCommission === data.indBankCommission &&
                ele.indBatchBankDeposit === data.indBatchBankDeposit &&
                ele.indBatchBankWithdrawals === data.indBatchBankWithdrawals
              )
          )
      );

      let finalData = {
        batchId: formData.batchId,
        meetingHeldOnDate: formData.batchMeetingHeldOnDate,
        trasanctionType: "C",
        indBankInterest: data.indBankInterest,
        indBankCommission: data.indBankCommission,
        indBatchBankDeposit: data.indBatchBankDeposit,
        indBatchBankWithdrawals: data.indBatchBankWithdrawals,
      };

      deleteCashTransaction(finalData);
    }

    let depositeTot = Number(bankDeposits) - Number(BankDeposit);
    let withdawtot = Number(withdraw) - Number(BankWithdrawals);
    let interestTot = Number(interest) - Number(Interest);
    let commissionTot = Number(commision) - Number(Commission);

    let bankWith =
      DBBankArray &&
      DBBankArray.map((ele) => ele.indBatchBankWithdrawals).reduce(
        (acc, curr) => Number(acc) + Number(curr),
        0
      ); //this is needed bcz to update the withdrawable amount as per new bankBanalance
    let newBal = Number(prevBankBalance || 0) + Number(BankDeposit || 0);

    setFinalBankBalance(newBal);
    setNewTrailBankBalances(
      Number(prevBankBalance) +
        (depositeTot + interestTot) -
        (withdawtot + commissionTot)
    );

    if (data.transactionType !== undefined) {
      if (transactionType === "C") {
        if (CashInHand < 0) {
          setCashInhand(
            CashInHand + Number(BankDeposit || 0) + Number(BankWithdrawals || 0)
          );
        } else {
          setCashInhand(
            CashInHand + Number(BankDeposit || 0) - Number(BankWithdrawals || 0)
          );
        }
      } else {
        setCashInhand(
          Number(totIncomeCash || 0) -
            Number(totExpenseCash) -
            (Number(bankDeposits || 0) - BankDeposit)
        );
      }
    }
  };

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };


  // console.log("DBBankArray",DBBankArray)



  return (
    <div ref={tooltipRef}>
      {activeBank.length > 0 ? (
        <>
          <div className="row card-new  py-3">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <h5>Bank Details</h5>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12 col-12 py-2">
              <h5>
                Income&nbsp;:&nbsp;
                <Incomearray data={"Income"} />
              </h5>
              Bank&nbsp;:
              <span className="mx-2">{totIncomeBank}</span>
              <br />
              Cash&nbsp;:
              <span className="mx-2">{totIncomeCash}</span>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12 col-12 py-2">
              <h5>
                Expense&nbsp;:&nbsp;
                <Incomearray data={"expense"} />
              </h5>
              Bank&nbsp;:
              <span className="mx-2">{totExpenseBank}</span>
              <br />
              Cash&nbsp;:
              <span className="mx-2">{totExpenseCash}</span>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12 col-12 py-2">
              <h5>Net Total&nbsp;:&nbsp;</h5>
              <label>Bank&nbsp;:</label>
              <span className="mx-2">
                {Number(totIncomeBank) - Number(totExpenseBank)}
              </span>
              <br />
              <label className="">Cash&nbsp;:</label>
              <span className="mx-2">
                {Number(totIncomeCash) - Number(totExpenseCash)}
              </span>
              <br />
            </div>
            {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12 py-2">
              <h5>OL Details:</h5>
              <b>Sanctioned:</b>
              <br />
              <span className="mx-2">Cash: {Loandata.othLoanCash}</span>
              <span className="mx-2">Bank: {Loandata.othLoanBank}</span>
              <br />
              <b>Repayment:</b>
              <br />
              <span className="mx-2">
                Cash:{" "}
                {Number(Loandata.othLoanPriPaidCash) +
                  Number(Loandata.otherLoanIntPaidCash)}
              </span>
              <span className="mx-2">
                Bank:{" "}
                {Number(Loandata.othLoanPriPaidBank) +
                  Number(Loandata.otherLoanIntPaidBank)}
              </span>
            </div> */}

            {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12 "> */}
            <div className="col-lg-3 col-md-12 col-sm-12 col-12 ">
              <label className="label-control">
                Cash In Hand*&nbsp;:&nbsp;
              </label>
              <input
                type="number"
                name="cashInHand"
                value={CashInHand}
                className="form-control"
                disabled
                // onChange={(e) => onInputChange(e)}
                onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                onKeyDown={(e) => funcKeyDown(e)}
              />
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12 "></div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12 "></div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12 "></div>
            {/* </div> */}

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <label
                className="label-control"
                // style={bankErrorStyle}
              >
                Select Bank&nbsp;:&nbsp;
              </label>
              <Select
                id="selBanks"
                name="selBanks"
                options={activeBank}
                isSearchable={true}
                placeholder="Select Bank"
                onChange={(e) => inputBankDetChange(e, "selBanks")}
                value={batchBankNameObj}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <label className="label-control">
                Bank Interest&nbsp;:&nbsp;{" "}
              </label>
              <input
                type="number"
                name="indBankInterest"
                value={indBankInterest}
                className="form-control"
                onChange={(e) => inputBankDetChange(e)}
                onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
              />
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <label className="label-control">
                Bank Commission&nbsp;:&nbsp;{" "}
              </label>
              <input
                type="number"
                name="indBankCommission"
                value={indBankCommission}
                className="form-control"
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => inputBankDetChange(e)}
                onKeyDown={(e) => funcKeyDown(e)}
              />
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <label
                // style={myerror}
                className="label-control"
              >
                Bank Deposit&nbsp;:&nbsp;
              </label>
              <input
                type="number"
                name="indBatchBankDeposit"
                value={indBatchBankDeposit}
                className="form-control"
                onChange={(e) => inputBankDetChange(e)}
                onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                onKeyDown={(e) => funcKeyDown(e)}
                required
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <label className="label-control">
                Bank Withdrawal&nbsp;:&nbsp;
              </label>

              <input
                type="number"
                name="indBatchBankWithdrawals"
                value={indBatchBankWithdrawals}
                className="form-control"
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => inputBankDetChange(e)}
              />
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              {Number(totIncomeCash) -
                Number(totExpenseCash) +
                Number(PrevCashInHand || 0) ==
              Number(CashInHand) + Number(indBatchBankDeposit) + Number(bankDeposits || 0) - Number(bankWithdrawals || 0) ? (
                <>
                  {
                  CashInHand < 0 && (indBatchBankDeposit !== 0 && indBatchBankDeposit !== '')   ?

                  (   
                    <input
                      type="button"
                      name="Submit"
                      value="ADD"
                      style={{background:"white",color:"black",cursor:"no-drop"}}
                      className="btn sub_form btn_continue blackbrd Save float-right"
                      disabled={true}
                    />
                  ) :
                  withdrawDisable ? (   //CashInHand < 0 || removed because we can withdraw amount even if cash in hand is < 0
                    <input
                      type="button"
                      name="Submit"
                      style={{background:"white",color:"black",cursor:"no-drop"}}
                      value="ADD"

                      className="btn sub_form btn_continue blackbrd Save float-right"
                      disabled={true}
                    />
                  ) : 
                  bankWithdrawals <= finalBankBalance ? (
                    <input
                      type="button"
                      name="Submit"
                      value="ADD"
                      className="btn sub_form btn_continue blackbrd Save float-right"
                      onClick={() => {
                        onAddBankDet();
                      }}
                      style={
                        DBBankArray && DBBankArray.map((ele) => ele.batchBankNameId).includes(
                          batchBankNameObj.bankId
                        )
                          ? {}
                          : {}
                      }
                      disabled={batchBankNameObj == ""}
                    />
                  ) : (
                     
                    CashInHand >= 0 && (indBatchBankDeposit !== 0 || indBatchBankDeposit !== '') ? //&& (indBatchBankWithdrawals <= finalBankBalance) REMOVED BCZ NOT ABLE TO INSERT DEPOSITE AMOUNT
                     <>
                      <input
                      type="button"
                      name="Submit"
                      value="ADD"
                      className="btn sub_form btn_continue blackbrd Save float-right"
                      onClick={() => {
                        onAddBankDet();
                      }}
                      style={
                        DBBankArray && DBBankArray.map((ele) => ele.batchBankNameId).includes(
                          batchBankNameObj.bankId
                        )
                          ? {}
                          : {
                        }
                      }
                      disabled={batchBankNameObj == ""}
                    />
                     </> :
                      <>
                      <input
                      type="button"
                      name="Submit"
                      style={{background:"white",color:"black",cursor:"no-drop"}}
                      value="ADD"
                      className="btn sub_form btn_continue blackbrd Save float-right"
                      disabled={true}
                    />
                      </>
                    
                  )}
                </>
              ) : (
                <input
                  type="button"
                  name="Submit"
                  style={{background:"white",color:"black",cursor:"no-drop"}}
                  value="ADD"
                  className="btn sub_form btn_continue blackbrd Save float-right"
                  disabled={true}
                />
              )}
            </div>
          </div>

          <div className="body-inner no-padding  table-responsive">
            <table
              className="tabllll table table-bordered table-striped table-hover"
              id="datatable2"
            >
              <thead>
                <tr>
                  <th>Page</th>
                  <th>Bank Name</th>
                  <th>Bank Interest</th>
                  <th>Bank Commission</th>
                  <th>Bank Deposit Amount</th>
                  <th>Bank Withdrawal Amount</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {DBBankArray && DBBankArray.map((ele, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="text-center">{ele.transactionType}</td>
                      <td>{ele.batchBankName}</td>
                      <td>{ele.indBankInterest}</td>
                      <td>{ele.indBankCommission}</td>
                      <td>{ele.indBatchBankDeposit}</td>
                      <td>{ele.indBatchBankWithdrawals}</td>
                      <td className="text-center">
                        {ele.transactionType === "C" ||
                        ele.transactionType === undefined ? (
                          <img
                            className="img_icon_size log"
                            src={require("../../../static/images/delete.png")}
                            alt="Delete"
                            title="Delete"
                            onClick={() => onDeleteBankDet(ele)}
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          <div className="row card-new  py-3">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <h5>Bank Details</h5>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12 py-2">
              <h5>
                Income&nbsp;:&nbsp;
                <Incomearray data={"Income"} />
              </h5>
              Bank&nbsp;:
              <span className="mx-2">{totIncomeBank}</span>
              <br />
              Cash&nbsp;:
              <span className="mx-2">{totIncomeCash}</span>
            </div>
           

            <div className="col-lg-4 col-md-12 col-sm-12 col-12 py-2">
              <h5>
                Expense&nbsp;:&nbsp;
                <Incomearray data={"expense"} />
              </h5>
              Bank&nbsp;:
              <span className="mx-2">{totExpenseBank}</span>
              <br />
              Cash&nbsp;:
              <span className="mx-2">{totExpenseCash}</span>
            </div>
            
            <div className="col-lg-4 col-md-12 col-sm-12 col-12 py-2">
              <h5>Net Total&nbsp;:&nbsp;</h5>
              <label>Bank&nbsp;:</label>
              <span className="mx-2">
                {Number(totIncomeBank) - Number(totExpenseBank)}
              </span>
              <br />
              <label className="">Cash&nbsp;:</label>
              <span className="mx-2">
                {Number(totIncomeCash) - Number(totExpenseCash)}
              </span>
              <br />
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <label className="label-control">Cash In Hand* :</label>
              <input
                type="number"
                name="cashInHand"
                value={cashInHand}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                //   onChange={(e) => onInputChange(e)}
                onKeyDown={(e) => funcKeyDown(e)}
              />
            </div>
          </div>
        </>
      )}

      <span style={{ color: "red" }}>{errorForBankTab}</span>
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        {CashInHand >= 0 ? (
          <button
            className="btn sub_form btn_continue Save float-right"
            // onClick={() => onAddOLRSingle(8)}
            onClick={() => finAddBankDet()}
            type="button"
          >
            Next
          </button>
        ) : (
          <>
            <label className=" col-12 text-right" style={{ color: "red" }}>
              Please update net cash / bank.
            </label>
            <button
              className="btn sub_form btn_continue Save float-right"
              disabled
              // onClick={() => onAddOLRSingle(8)}
              //  onClick={() => finAddBankDet()}
              type="button"
            >
              Next
            </button>
          </>
        )}

        <button
          className="btn sub_form btn_continue Save float-right"
          type="button"
          onClick={() => NextBackBtn(7)}
        >
          Previous
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getBankDetails,
  deleteCashTransaction,
})(BankDetTab);
