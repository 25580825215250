import React, { useState, Fragment, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import {
  AddMember,
  getBatchDropdown,
  getBatchInfo,
  getShgMembersFamilyOccupation,
  getOcccupationsDropdown,
  getSubOccupation,
} from "../../actions/shg";
import {
  getReligions,
  getCaste,
  getSubcasteDetails,
} from "../../actions/religionSetting";
// import { RadioGroup, RadioButton } from "react-radio-buttons";
import Spinner from "../layout/Spinner";
import { Modal } from "react-bootstrap";
// import Autocomplete from "./Autocomplete";
import GovtCards from "./GovtCards.js";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const AddMemberDetails = ({
  auth: { isAuthenticated, user, users, loading },
  religionSetting: { religionsData, activeCaste, getSubcaste },
  shg: {
    batchdropdown,
    shgbatchinfo,
    membersFamilyOccupation,
    activeOccupation,
    getAllSubOccupation,
  },
  getSubcasteDetails,
  getSubOccupation,
  getCaste,
  getReligions,
  getBatchDropdown,
  getBatchInfo,
  AddMember,
  getShgMembersFamilyOccupation,
  getOcccupationsDropdown,
}) => {
  useEffect(() => {
    getBatchDropdown();
  }, [getBatchDropdown]);
  useEffect(() => {
    getOcccupationsDropdown();
  }, [getOcccupationsDropdown]);
  useEffect(() => {
    getReligions();
  }, [getReligions]);
  // useEffect(() => {
  //   getShgMembersFamilyOccupation();
  // }, [getShgMembersFamilyOccupation]);
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );
  const allAccess = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.access &&
    loggedUserInstitutionData.access.map((access) =>
      allAccess.push(access.accessName)
    );
  // const allOccupation = [];
  // membersFamilyOccupation &&
  //   membersFamilyOccupation.map((occupation) => {
  //     if (occupation.familyMembers.familyMemberOcupation)
  //       allOccupation.push(occupation.familyMembers.familyMemberOcupation);
  //   });
  const RationCardMethods = [
    { value: "BPL", label: "BPL" },
    { value: "APL", label: "APL" },
    { value: "AAP", label: "AAP" },
    { value: "PHH", label: "PHH" },
    { value: "AAY", label: "AAY" },
  ];

  const GenderOpt = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];

  const EducationOpt = [
    { value: "Primary", label: "Primary" },
    { value: "Higher primary", label: "Higher primary" },
    { value: "High School", label: "High School" },
    { value: "PUC", label: "PUC" },
    { value: "Degree", label: "Degree" },
    { value: "PG", label: "PG" },
    { value: "Uneducated", label: "Uneducated" },
    { value: "Others", label: "Others" },
  ];

  const MemDesigMethods = [
    { value: "Member", label: "Member" },
    { value: "Secretary", label: "Secretary" },
    { value: "Treasurer", label: "Treasurer" },
    { value: "President", label: "President" },
    { value: "Representative", label: "Representative" },
    { value: "Animator", label: "Animator" },
    { value: "Children", label: "Children" },
    { value: "Student", label: "Student" },
    { value: "Normal employee", label: "Normal employee" },
    { value: "Volunteer ", label: "Volunteer " },
  ];

  const MemMaritalStatus = [
    { value: "Married", label: "Married" },
    { value: "Unmarried", label: "Unmarried" },
    { value: "Widow", label: "Widow" },
    { value: "Single", label: "Single" },
    { value: "Separated ", label: "Separated" },
    { value: "Abandoned", label: "Abandoned" },
    { value: "other", label: "other" },
  ];

  const HealthVulnerability = [
    { value: "None", label: "None" },
    { value: "Disabled", label: "Disabled" },
    { value: "Chronic Sickness", label: "Chronic Sickness" },
    {
      value: "Sick or Disabled in Family",
      label: "Sick or Disabled in Family",
    },
    { value: "Other", label: "Other" },
  ];

  const MemDisabilityList = [
    { value: "Blindness", label: "Blindness", DlId: "DL01" },
    { value: "Low-vision", label: "Low-vision", DlId: "DL02" },
    {
      value: "Leprosy Cured persons",
      label: "Leprosy Cured persons",
      DlId: "DL03",
    },
    {
      value: "Hearing Impairment (deaf and hard of hearing)",
      label: "Hearing Impairment (deaf and hard of hearing)",
      DlId: "DL04",
    },
    {
      value: "Locomotor Disability",
      label: "Locomotor Disability",
      DlId: "DL05",
    },
    { value: "Dwarfism", label: "Dwarfism", DlId: "DL06" },
    {
      value: "Intellectual Disability",
      label: "Intellectual Disability",
      DlId: "DL07",
    },
    { value: "Mental Illness", label: "Mental Illness", DlId: "DL08" },
    {
      value: "Autism Spectrum Disorder",
      label: "Autism Spectrum Disorder",
      DlId: "DL09",
    },
    { value: "Cerebral Palsy", label: "Cerebral Palsy", DlId: "DL10" },
    { value: "Muscular Dystrophy", label: "Muscular Dystrophy", DlId: "DL11" },
    {
      value: "Chronic Neurological conditions",
      label: "Chronic Neurological conditions",
      DlId: "DL12",
    },
    {
      value: "Specific Learning Disabilities",
      label: "Specific Learning Disabilities",
      DlId: "DL13",
    },
    { value: "Multiple Sclerosis", label: "Multiple Sclerosis", DlId: "DL14" },
    {
      value: "Speech and Language disability",
      label: "Speech and Language disability",
      DlId: "DL15",
    },
    { value: "Thalassemia", label: "Thalassemia", DlId: "DL16" },
    { value: "Hemophilia", label: "Hemophilia", DlId: "DL17" },
    {
      value: "Sickle Cell disease",
      label: "Sickle Cell disease",
      DlId: "DL18",
    },
    {
      value: "Multiple Disabilities including deafblindness",
      label: "Multiple Disabilities including deafblindness",
      DlId: "DL19",
    },
    { value: "Acid Attack victim", label: "Acid Attack victim", DlId: "DL20" },
    {
      value: "Parkinson's disease",
      label: "Parkinson's disease",
      DlId: "DL21",
    },
  ];
  const [showHide, setShowHide] = useState({
    showotherEducationSection: false,
    showmaritalotherstatus: false,
  });
  const { showotherEducationSection, showmaritalotherstatus } = showHide;

  const [showFamHide, setFamShowHide] = useState({
    showFamOtherEducationSection: false,
  });
  const { showFamOtherEducationSection } = showFamHide;
  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };
  // code for next previous tabing starts
  const [tabIndex, setTabIndex] = useState(0);
  const NextBackBtn = (e, tabIndex) => {
    e.preventDefault();
    if (checkErrors()) {
      setTabIndex(tabIndex);
    }
  };
  //formData
  const [formData, setFormData] = useState({
    memberName: "",
    memberPhone: "",
    memberAddress: "",
    memberAadharNo: "",
    memberPanNo: "",
    memberUdidNo: "",
    otherStatus: "",
    memberDisabilityType: "",
    memberDisabilityId: "",
    memberDOB: "",
    memberAge: 0,
    guardianName: "",
    memberDesg: "",
    memberEducation: "",
    otherEducation: "",
    rationCardType: "",
    healthVulnerabilityType: "",
    memberJoiningDate: "",
    memberCode: "",
    memberGender: "",
    memberReligion: "",
    memberCaste: "",
    memberSubOccupation: "",
    memberSubCaste: "",
    memberOcupationId: "",
    memberOcupation: "",
    memberMaritalStatus: "",
    memberMonthlyIncome: "",
    memberAnnualIncome,
    memberContactPersonName: "",
    memberVocationalSkill: "",
    isSubmitted: false,
  });
  const {
    memberName,
    memberPhone,
    rationCardType,
    healthVulnerabilityType,
    memberAddress,
    memberAadharNo,
    memberPanNo,
    memberUdidNo,
    otherStatus,
    memberDisabilityType,
    memberDisabilityId,
    memberDOB,
    memberAge,
    guardianName,
    memberDesg,
    memberEducation,
    otherEducation,
    memberCode,
    memberGender,
    memberReligion,
    memberCaste,
    memberSubOccupation,
    memberSubCaste,
    memberOcupationId,
    memberOcupation,
    memberMaritalStatus,
    memberMonthlyIncome,
    memberAnnualIncome,
    memberContactPersonName,
    memberVocationalSkill,
    isSubmitted,
  } = formData;
  const [addData, setFormDatas] = useState({
    familyMemberName: "",
    familyMemberAge: "",
    familyMemberPhone: "",
    familyMemberGender: "",
    familyMemberRelationship: "",
    familyMemberMaritalStatus: "",
    familyMemberEducation: "",
    otherFamilyEducation: "",
    familyMemberOcupation: "",
    familyMemberMonthlyIncome: "",
    familyMemberAnnualIncome: "",

    familyUdidNo: "",
    familyMemberDisabilityType: "",
    familyMemberDisabilityId: "",
    familyDisabilityId: "",
  });
  const {
    familyMemberName,
    familyMemberAge,
    familyMemberPhone,
    familyMemberGender,
    familyMemberRelationship,
    familyMemberMaritalStatus,
    familyMemberEducation,
    otherFamilyEducation,
    familyMemberOcupation,
    familyMemberMonthlyIncome,
    familyMemberAnnualIncome,
    familyUdidNo,
    familyMemberDisabilityType,
    familyMemberDisabilityId,
    familyDisabilityId,
  } = addData;
  const onInputChange1 = (e) => {
    setFormDatas({ ...addData, [e.target.name]: e.target.value });
  };
  const onFamilyMemberGenderChange = (e) => {
    if (e) {
      setFormDatas({ ...addData, familyMemberGender: e });
    }
  };
  const [showAlertMsg, setshowAlertMsg] = useState(false);
  const onUpdateALertMsg = () => {
    setshowAlertMsg(false);
  };
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  let addBatchInfo = JSON.parse(localStorage.getItem("selBatchInfo"));
  const [startSelectedDate, setJoinDate] = useState("");
  const onDateChange = (e) => {
    var bFormationDate = addBatchInfo[0].batchFormationDate;
    var currentDate = e.target.value;

    if (currentDate < bFormationDate) {
      if (Number(e.target.value.split("-")[0].split("")[0]) > 0) {
        setshowAlertMsg(true);
      }
      setJoinDate(e.target.value);
    } else {
      setJoinDate(e.target.value);
    }
  };

  const [MemberMaritalStatus, setMaritalStatus] = useState("");
  const onMaritalStatusChange = (e) => {
    setMaritalStatus(e);
    if (e.value === "other") {
      setShowHide({
        ...showHide,
        showmaritalotherstatus: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showmaritalotherstatus: false,
      });
    }
  };
  const onDateChangeDOB = (e) => {
    var age_dt = new Date(Date.now() - new Date(e.target.value).getTime());
    setFormData({
      ...formData,
      memberDOB: e.target.value,
      memberAge: Math.abs(age_dt.getUTCFullYear() - 1970),
    });
  };
  const onMemDesigChange = (e) => {
    setError({
      ...error,
      DesgIdChecker: true,
      DesgErrorStyle: { color: "#000" },
    });

    if (e) {
      setFormData({
        ...formData,
        memberDesg: e,
      });
    }
  };
  const onMemmemberEducationChange = (e) => {
    if (e) {
      setFormData({
        ...formData,
        memberEducation: e,
      });
    }
    if (e.value === "Others") {
      setShowHide({
        ...showHide,
        showotherEducationSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showotherEducationSection: false,
      });
    }
  };
  const onFammemberEducationChange = (e) => {
    if (e) {
      setFormDatas({
        ...addData,
        familyMemberEducation: e,
      });
    }
    if (e.value === "Others") {
      setFamShowHide({
        ...showFamHide,
        showFamOtherEducationSection: true,
      });
    } else {
      setFamShowHide({
        ...showFamHide,
        showFamOtherEducationSection: false,
      });
    }
  };
  const onMemDisabilityChange = (e) => {
    if (e) {
      setFormData({
        ...formData,
        memberDisabilityType: e,
      });
      setError({
        ...error,
        DisabilityTypeErrorStyle: { color: "#000" },
      });
    }
  };
  const onFamDisabilityChange = (e) => {
    if (e) {
      setFormDatas({
        ...addData,
        familyMemberDisabilityType: e,
        familyMemberDisabilityId: e.DlId,
      });
      // setError({
      //   ...error,
      //   DisabilityTypeErrorStyle: { color: "#000" },
      // });
    }
  };
  //Required Validation Starts
  const [error, setError] = useState({
    DesgIdChecker: false,
    DesgErrorStyle: {},
    memberNameChecker: false,
    MemErrorStyle: {},
    aadharChecker: false,
    adharErrorStyle: {},
    UDIDNumberErrorStyle: {},
    DisabilityTypeErrorStyle: {},
    guardianNameErrorStyle: {},
  });
  const {
    DesgIdChecker,
    DesgErrorStyle,
    memberNameChecker,
    MemErrorStyle,
    aadharChecker,
    adharErrorStyle,
    UDIDNumberErrorStyle,
    DisabilityTypeErrorStyle,
    guardianNameErrorStyle,
  } = error;
  //for family member
  //Required Validation Starts
  const [error1, setError1] = useState({
    fammemberNameChecker: false,
    famMemErrorStyle: {},
  });
  const { fammemberNameChecker, famMemErrorStyle } = error1;
  ////////////////////////
  const [disabilitiesChecker, setDisabilitiesChecker] = useState(false);
  const [familyDisabilitiesChecker, setFamilyDisabilitiesChecker] = useState(
    false
  );
  const checkErrors = () => {
    if (!memberName) {
      setError({
        ...error,
        MemErrorStyle: { color: "#F00" },
      });
      return false;
    } else {
      setError({
        ...error,
        memberNameChecker: true,
        MemErrorStyle: { color: "#000" },
      });
    }
    if (!DesgIdChecker) {
      setError({
        ...error,
        DesgErrorStyle: { color: "#F00" },
      });
      return false;
    }
    if (allPermission && allPermission.includes("AadharNumber")) {
      if (!memberAadharNo) {
        setError({
          ...error,
          adharErrorStyle: { color: "#F00" },
        });
        return false;
      } else {
        setError({
          ...error,
          aadharChecker: true,
          adharErrorStyle: { color: "#000" },
        });
      }
    }
    if (disabilitiesChecker === true) {
      if (!memberDisabilityType) {
        setError({
          ...error,
          DisabilityTypeErrorStyle: { color: "#F00" },
        });
        return false;
      }
      if (!memberUdidNo) {
        setError({
          ...error,
          UDIDNumberErrorStyle: { color: "#F00" },
        });
        return false;
      }
    }
    if (memberAge < 18 && memberAge !== 0 && !guardianName) {
      setError({
        ...error,
        guardianNameErrorStyle: { color: "#F00" },
      });
      return false;
    }
    return true;
  };
  //for family member
  const checkErrors1 = () => {
    if (!familyMemberName) {
      setError1({
        ...error1,
        famMemErrorStyle: { color: "#F00" },
      });
      return false;
    } else {
      setError1({
        ...error1,
        fammemberNameChecker: true,
        famMemErrorStyle: { color: "#000" },
      });
    }
    return true;
  };
  const [batch, getbatchData] = useState();
  const [batchId, setbatchId] = useState();
  const [batchName, setbatchName] = useState();
  const [batchCode, setbatchCode] = useState();
  const [memberCounter, setMemberCounter] = useState();
  const [batchFormationDate, setBatchFormationDate] = useState("");
  let anualIncomeVal = 0;
  anualIncomeVal = familyMemberMonthlyIncome * 12;
  let anualmemIncomeVal = 0;
  anualmemIncomeVal = memberMonthlyIncome * 12;
  if (addBatchInfo && addBatchInfo[0] && addBatchInfo[0]._id && !batchId) {
    setbatchId(addBatchInfo[0] && addBatchInfo[0]._id);
    setbatchName(addBatchInfo[0] && addBatchInfo[0].batchName);
    setbatchCode(addBatchInfo[0] && addBatchInfo[0].batchCode);
    setMemberCounter(addBatchInfo[0] && addBatchInfo[0].memberCounter);
    setBatchFormationDate(
      addBatchInfo[0] && addBatchInfo[0].batchFormationDate
    );
  }
  const mCode = batchCode + memberCounter;
  var ED = batchFormationDate.split(/\D/g);
  var bFormationDate = [ED[2], ED[1], ED[0]].join("-");
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);
  const [userData, setUserData] = useState(null);
  const onEdit = (e) => {
    setShowUpdateModal(true);
    setUserData(e);
  };
  const onUpdateModalChange = (e) => {
    if (e) {
      handleUpdateModalClose();
    }
  };
  const onrationCardTypeChange = (e) => {
    //Required Validation starts
    setError({
      ...error,
      TranscationIdChecker: true,
      TranscationIdErrorStyle: { color: "#000" },
    });
    //Required Validation ends
    if (e) {
      setFormData({
        ...formData,
        rationCardType: e,
      });
    }
  };
  const onHealthVulnerabilityTypeChange = (e) => {
    if (e) {
      setFormData({
        ...formData,
        healthVulnerabilityType: e,
      });
    }
  };
  const [selectionType1, setSelectionType1] = useState();
  const onRadioGenderChange = (e) => {
    if (e) {
      setFormData({ ...formData, memberGender: e });
    }
  };
  const handleOnChange = () => {
    setDisabilitiesChecker(!disabilitiesChecker);
  };
  const handleOnFamilyChange = () => {
    setFamilyDisabilitiesChecker(!familyDisabilitiesChecker);
    if (!familyDisabilitiesChecker) {
      setFormDatas({
        ...addData,
        familyMemberDisabilityType: "",
        familyUdidNo: "",
      });
    }
  };
  // Religion start
  const allReligionsData = [];
  religionsData.map((religion) =>
    allReligionsData.push({
      rId: religion._id,
      label: religion.religionName,
      value: religion.religionName,
    })
  );
  const [religion, getReligionData] = useState();
  const [religionId, setReligionID] = useState();
  const [religionName, setReligionName] = useState();
  const onReligionChange = (e) => {
    //Required Validation end
    getReligionData(e);
    setReligionID(e.rId ? e.rId : null);
    setReligionName(e.value ? e.value : "");
    getCaste({
      religionInfo: e.rId,
    });
    getCasteData("");
    setCasteID(null);
    setCasteName("");
    setSubCaste("");
    setSubCasteID(null);
    setSubCasteName("");
  };
  // Religion end
  const allCasteData = [];
  activeCaste.map((caste) =>
    allCasteData.push({
      cId: caste._id,
      label: caste.casteName,
      value: caste.casteName,
    })
  );
  const [caste, getCasteData] = useState();
  const [casteId, setCasteID] = useState();
  const [casteName, setCasteName] = useState();
  const onCasteChange = (e) => {
    getCasteData(e);
    setCasteID(e.cId ? e.cId : null);
    setCasteName(e.value ? e.value : "");
    getSubcasteDetails({ cIdVal: e.cId });
    setSubCaste("");
    setSubCasteID(null);
    setSubCasteName("");
  };
  //SubCaste START
  const allSubCaste = [];
  getSubcaste.map((subCaste) =>
    allSubCaste.push({
      subCasteId: subCaste._id,
      label: subCaste.subcasteName,
      value: subCaste.subcasteName,
    })
  );
  const [subCaste, setSubCaste] = useState();
  const [subCasteId, setSubCasteID] = useState();
  const [subCasteName, setSubCasteName] = useState();
  const onSubCasteChange = (e) => {
    setSubCaste(e);
    setSubCasteID(e.subCasteId ? e.subCasteId : null);
    setSubCasteName(e.value ? e.value : "");
  };
  //SubCaste END
  //Family Religion
  const allFamilyReligionsData = [];
  religionsData.map((religion) =>
    allFamilyReligionsData.push({
      rId: religion._id,
      label: religion.religionName,
      value: religion.religionName,
    })
  );
  const [familyReligion, getFamilyReligionData] = useState();
  const [familyReligionId, setFamilyReligionID] = useState();
  const [familyReligionName, setFamilyReligionName] = useState();
  const onFamilyReligionChange = (e) => {
    //Required Validation end
    getFamilyReligionData(e);
    setFamilyReligionID(e.rId ? e.rId : null);
    setFamilyReligionName(e.value ? e.value : "");
    getCaste({
      religionInfo: e.rId,
    });
    getFamilyCasteData("");
    setFamilyCasteID(null);
    setFamilyCasteName("");
    setFamilySubCaste("");
    setFamilySubCasteID(null);
    setFamilySubCasteName("");
  };
  //Family Caste
  const allFamilyCasteData = [];
  activeCaste.map((caste) =>
    allFamilyCasteData.push({
      cId: caste._id,
      label: caste.casteName,
      value: caste.casteName,
    })
  );
  const [familyCaste, getFamilyCasteData] = useState();
  const [familyCasteId, setFamilyCasteID] = useState();
  const [familyCasteName, setFamilyCasteName] = useState();
  const onFamilyCasteChange = (e) => {
    getFamilyCasteData(e);
    setFamilyCasteID(e.cId ? e.cId : null);
    setFamilyCasteName(e.value ? e.value : "");
    getSubcasteDetails({ cIdVal: e.cId });
    setFamilySubCaste("");
    setFamilySubCasteID(null);
    setFamilySubCasteName("");
  };
  //Family Sub Caste
  const allFamilySubCaste = [];
  getSubcaste.map((subCaste) =>
    allFamilySubCaste.push({
      subCasteId: subCaste._id,
      label: subCaste.subcasteName,
      value: subCaste.subcasteName,
    })
  );
  const [familySubCaste, setFamilySubCaste] = useState();
  const [familySubCasteId, setFamilySubCasteID] = useState();
  const [familySubCasteName, setFamilySubCasteName] = useState();
  const onFamilySubCasteChange = (e) => {
    setFamilySubCaste(e);
    setFamilySubCasteID(e.subCasteId ? e.subCasteId : null);
    setFamilySubCasteName(e.value ? e.value : "");
  };
  //Occupation
  const allOccupation = [];
  activeOccupation.map((occupation) =>
    allOccupation.push({
      oId: occupation._id,
      label: occupation.occcupationName,
      value: occupation.occcupationName,
    })
  );
  const [occupation, getOccupationData] = useState();
  const [occupationId, setOccupationID] = useState();
  const [occupationName, setOccupationName] = useState();
  const [memberOccupation, getMemberOccupationData] = useState();
  const [memberOccupationId, setMemberOccupationId] = useState();
  const [memberOccupationName, setMemberOccupationName] = useState();
  const onOccupationChange = (e) => {
    getOccupationData(e);
    setOccupationID(e.oId ? e.oId : null);
    setOccupationName(e.value ? e.value : "");
    getSubOccupation({
      occupationInfo: e.oId,
    });
  };
  const onMemberOccupationChange = (e) => {
    getMemberOccupationData(e);
    setMemberOccupationId(e.oId ? e.oId : null);
    setMemberOccupationName(e.value ? e.value : "");
    getSubOccupation({
      occupationInfo: e.oId,
    });
  };
  //SubOccupation START
  const allSubOccupation = [];
  getAllSubOccupation.map((subOccupation) =>
    allSubOccupation.push({
      subOccupationId: subOccupation._id,
      label: subOccupation.subOccupationName,
      value: subOccupation.subOccupationName,
    })
  );
  const [membersubOccupation, setSubOccupation] = useState();
  const [memberSubOccupationId, setSubOccupationID] = useState();
  const [memberSubOccupationName, setSubOccupationName] = useState();
  const [familySubOccupation, setFamilySubOccupationData] = useState();
  const [familySubOccupationId, setFamilySubOccupationID] = useState();
  const [familySubOccupationName, setFamilySubOccupationName] = useState();
  const onSubOccupationChange = (e) => {
    setSubOccupation(e);
    setSubOccupationID(e.subOccupationId ? e.subOccupationId : null);
    setSubOccupationName(e.value ? e.value : "");
  };
  const onFamilySubOccupationChange = (e) => {
    setFamilySubOccupationData(e);
    setFamilySubOccupationID(e.subOccupationId ? e.subOccupationId : null);
    setFamilySubOccupationName(e.value ? e.value : "");
  };
  //SubOccupation END
  const [AddedDetails, AddDetails] = useState([]);
  const onAdd = (e) => {
    if (familyMemberName !== "") {
      setError1({
        ...error1,
        fammemberNameChecker: true,
        famMemErrorStyle: { color: "#000" },
      });
    }
    const familyMemberList = AddedDetails.filter(
      (AddDetails) => AddDetails.familyMemberName === familyMemberName
    );
    e.preventDefault();
    if (checkErrors1()) {
      if (familyMemberList.length === 0 && AddedDetails.length <= 7) {
        if (addData && addData.familyMemberName) {
          const addData = {
            familyMemberName: familyMemberName,
            familyMemberAge: familyMemberAge,
            familyMemberPhone: familyMemberPhone,
            familyMemberGender: familyMemberGender.value,
            familyMemberRelationship: familyMemberRelationship,
            familyMemberMaritalStatus: familyMemberMaritalStatus,
            familyMemberEducation: familyMemberEducation.value,
            otherFamilyEducation: otherFamilyEducation,
            familyMemberOcupationId: occupationId,
            familyMemberOcupation: occupationName,
            familyMemberSubOccupationId: familySubOccupationId,
            familyMemberSubOccupation: familySubOccupationName,
            familyMemberMonthlyIncome: familyMemberMonthlyIncome,
            isFamDisable: familyDisabilitiesChecker,
            familyMemberDisabilityId: familyMemberDisabilityId,
            familyMemberDisabilityType: familyMemberDisabilityType.value,
            familyUdidNo: familyUdidNo,
            familyMemberAnnualIncome: anualIncomeVal,
            familyCards: SelectedCardsFamily,
            familyMemberReligionId: familyReligionId,
            familyMemberReligion: familyReligionName,
            familyMemberCasteId: familyCasteId,
            familyMemberCaste: familyCasteName,
            familyMemberSubCasteId: familySubCasteId,
            familyMemberSubCaste: familySubCasteName,
          };
          setFormDatas({
            ...addData,
            familyMemberName: "",
            familyMemberAge: "",
            familyMemberPhone: "",
            familyMemberGender: "",
            familyMemberRelationship: "",
            familyMemberMaritalStatus: "",
            familyMemberEducation: "",
            otherFamilyEducation: "",
            familyMemberOcupationId: "",
            familyMemberOcupation: "",
            familyMemberSubOccupationId: "",
            familyMemberSubOccupation: "",
            familyMemberMonthlyIncome: "",
            familyMemberAnnualIncome: "",
            familyMemberDisabilityId: "",
            familyMemberDisabilityType: "",
            familyUdidNo: "",
            familyMemberReligionId: "",
            familyMemberReligion: "",
            familyMemberCasteId: "",
            familyMemberCaste: "",
            familyMemberSubCasteId: "",
            familyMemberSubCaste: "",
          });
          let temp = [];
          temp.push(...AddedDetails, addData);
          getOccupationData("");
          setOccupationID(null);
          setOccupationName("");
          setFamilySubOccupationData("");
          setFamilySubOccupationID(null);
          setFamilySubOccupationName("");
          getFamilyReligionData("");
          setFamilyReligionID(null);
          setFamilyReligionName("");
          getFamilyCasteData("");
          setFamilyCasteID(null);
          setFamilyCasteName("");
          setFamilySubCaste("");
          setFamilySubCasteID(null);
          setFamilySubCasteName("");
          setFamilyDisabilitiesChecker(false);
          AddDetails(temp);
          setSelectedCardsFamily([]);
          // setOcc("");
        }
      }
    }
  };
  const onRemoveChange = (familyMemberName) => {
    const removeList = AddedDetails.filter(
      (AddDetails) => AddDetails.familyMemberName !== familyMemberName
    );
    AddDetails(removeList);
  };
  //FOR PREVIEW
  const onSubmit = (e) => {
    e.preventDefault();
    if (allPermission && allPermission.includes("AadharNumber")) {
      if (memberName !== "" && memberUdidNo !== "") {
        setError({
          ...error,
          memberNameChecker: true,
          MemErrorStyle: { color: "#000" },
          aadharChecker: true,
          adharErrorStyle: { color: "#000" },
          UDIDNumberErrorStyle: { color: "#000" },
        });
      }
    } else {
      setError({
        ...error,
        memberNameChecker: true,
        MemErrorStyle: { color: "#000" },
        UDIDNumberErrorStyle: { color: "#000" },
      });
    }
    //Required Validation Starts
    if (checkErrors()) {
      const finalData = {
        memberName: memberName,
        memberPhone: memberPhone,
        memberAddress: memberAddress,
        memberAadharNo: memberAadharNo,
        memberPanNo: memberPanNo,
        memberDesg: memberDesg.value,
        memberEducation: memberEducation.value,
        otherEducation: otherEducation,
        memberGender: memberGender.value,
        rationCardType: rationCardType.value,
        healthVulnerabilityType: healthVulnerabilityType.value,
        memberJoiningDate: startSelectedDate,
        memberCode: mCode,
        institutionId: user.institutionId,
        batchId: batchId,
        batchName: batchName,
        memberCounter: memberCounter,
        memberReligionId: religionId,
        memberReligion: religionName,
        memberCasteId: casteId,
        memberCaste: casteName,
        memberSubCasteId: subCasteId,
        memberSubCaste: subCasteName,
        isDisable: disabilitiesChecker,
        udidNo: memberUdidNo,
        otherStatus: otherStatus,
        familyMembers: AddedDetails,
        memberDisabilityType: memberDisabilityType.value,
        memberDisabilityId: memberDisabilityType.DlId,
        memberOcupationId: memberOccupationId,
        memberOcupation: memberOccupationName,
        memberSubOccupationId: memberSubOccupationId,
        memberSubOccupation: memberSubOccupationName,
        memberDOB: memberDOB,
        guardianInfo: guardianName,
        cards: SelectedCards,
        memberMaritalStatus: memberMaritalStatus
          ? memberMaritalStatus
          : MemberMaritalStatus.value,
        memberMonthlyIncome: memberMonthlyIncome,
        memberAnnualIncome: anualmemIncomeVal,
        memberContactPersonName: memberContactPersonName,
        memberVocationalSkill: memberVocationalSkill,
      };

      setShowUpdateModal(true);
      setUserData(finalData);
    }
  };
  //SAVE
  const onSaveSubmit = () => {
    const finalData = {
      memberName: memberName,
      memberPhone: memberPhone,
      memberAddress: memberAddress,
      memberAadharNo: memberAadharNo,
      memberPanNo: memberPanNo,
      memberDesg: memberDesg.value,
      memberEducation: memberEducation.value,
      otherEducation: otherEducation,
      rationCardType: rationCardType.value,
      healthVulnerabilityType: healthVulnerabilityType.value,
      memberJoiningDate: startSelectedDate,
      memberCode: mCode,
      institutionId: user.institutionId,
      batchId: batchId,
      batchName: batchName,
      memberCounter: memberCounter,
      memberGender: memberGender.value,
      memberReligionId: religionId,
      memberReligion: religionName,
      memberCasteId: casteId,
      memberCaste: casteName,
      memberSubCasteId: subCasteId,
      memberSubCaste: subCasteName,
      isDisable: disabilitiesChecker,
      udidNo: memberUdidNo,
      otherStatus: otherStatus,
      memberDisabilityType: memberDisabilityType
        ? memberDisabilityType.value
        : "",
      memberDisabilityId: memberDisabilityType ? memberDisabilityType.DlId : "",
      memberDOB: memberDOB,
      guardianInfo: guardianName,
      memberOcupationId: memberOccupationId,
      memberOcupation: memberOccupationName,
      memberSubOccupation: memberSubOccupation,
      memberSubOccupationId: memberSubOccupationId,
      memberSubOccupation: memberSubOccupationName,
      familyMembers: AddedDetails,
      cards: SelectedCards,
      memberMaritalStatus: memberMaritalStatus
        ? memberMaritalStatus
        : MemberMaritalStatus.value,
      memberMonthlyIncome: memberMonthlyIncome,
      memberAnnualIncome: anualmemIncomeVal,
      memberContactPersonName: memberContactPersonName,
      memberVocationalSkill: memberVocationalSkill,
      memberEnteredById: user._id,
      memberEnteredByName: user.userName,
      memberEnteredByDateTime: new Date().toLocaleString("en-GB"),
    };
    AddMember(finalData);
    setFormData({
      ...formData,
      memberName: "",
      memberPhone: "",
      memberAddress: "",
      memberAadharNo: "",
      memberPanNo: "",
      memberDesg: "",
      memberEducation: "",
      rationCardType: "",
      healthVulnerabilityType: "",
      memberReligion: "",
      memberCaste: "",
      memberSubCaste: "",
      memberJoiningDate: "",
      memberCode: "",
      memberMaritalStatus: "",
      memberMonthlyIncome: "",
      memberAnnualIncome: "",
      memberContactPersonName: "",
      memberVocationalSkill: "",
      isSubmitted: true,
    });
    setJoinDate("");
  };
  //GOVT CARDS
  const [SelectedCards, setSelectedCards] = useState([]);
  const [showCardsModal, setshowCardsModal] = useState(false);
  const handleCardsModalClose = () => setshowCardsModal(false);
  const onClickHandler = () => {
    setshowCardsModal(true);
  };
  //FAMILY
  const [SelectedCardsFamily, setSelectedCardsFamily] = useState([]);
  const [showCardsFamilyModal, setshowCardsFamilyModal] = useState(false);
  const handleCardsFamilyModalClose = () => setshowCardsFamilyModal(false);
  const onFamilyClickHandler = () => {
    setshowCardsFamilyModal(true);
  };
  //GOVT CARDS END
  {
    var ED = startSelectedDate.split(/\D/g);
    var previewSelectedDate = [ED[2], ED[1], ED[0]].join("-");
  }
  if (isSubmitted) {
    return <Redirect to="/all-members" />;
  }

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">Add Member Details </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <Tabs selectedIndex={tabIndex}>
            <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
              <TabList>
                <Tab tabfor="0">Member Info</Tab>
                {allPermission && allPermission.includes("Family") && (
                  <Tab tabfor="1">Family Details</Tab>
                )}
              </TabList>
            </div>
            <TabPanel tabId="0">
              <div className=" col-md-12 col-lg-12 col-sm-12 col-12 ">
                <form>
                  <div className="row col-lg-12 col-md-12 col-sm-12 col-12 ">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12 py-3">
                      <div className="row card-new  py-3">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <h5>Personal Info</h5>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label
                            className="label-control"
                            style={MemErrorStyle}
                          >
                            Member Name* :
                          </label>
                          <input
                            type="text"
                            name="memberName"
                            value={memberName}
                            className="form-control"
                            onChange={(e) => onInputChange(e)}
                            required
                          />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Member Phone :
                          </label>
                          <input
                            type="number"
                            min="0"
                            name="memberPhone"
                            value={memberPhone}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                        {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Member Religion :
                          </label>
                          <input
                            type="text"
                            name="memberReligion"
                            value={memberReligion}
                            className="form-control"
                            onChange={(e) => onInputChange(e)}
                          />
                        </div> */}
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label className="label-control">Gender :</label>
                          <Select
                            name="memberGender"
                            options={GenderOpt}
                            isSearchable={false}
                            value={memberGender}
                            placeholder="Select Gender"
                            onChange={(e) => onRadioGenderChange(e)}
                          />
                          {/* <RadioGroup
                            onChange={(e) => onRadioGenderChange(e)}
                            className="radio-group patient-page"
                            horizontal
                            value={selectionType1}
                            required
                          >
                            <RadioButton
                              padding={10}
                              rootColor="#144c5a"
                              pointColor="rgb(255,0,0)"
                              iconSize={20}
                              iconInnerSize={10}
                              value="M"
                            >
                              Male
                            </RadioButton>
                            <RadioButton
                              padding={10}
                              rootColor="#144c5a"
                              pointColor="rgb(255,0,0)"
                              iconSize={20}
                              iconInnerSize={10}
                              value="F"
                            >
                              Female
                            </RadioButton>
                          </RadioGroup>*/}
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Ration Card Type :
                          </label>
                          <Select
                            name="rationCardType"
                            options={RationCardMethods}
                            isSearchable={false}
                            value={rationCardType}
                            placeholder="Select Ration Card"
                            onChange={(e) => onrationCardTypeChange(e)}
                          />
                        </div>
                        {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Aadhaar Card No :
                          </label>
                          <input
                            type="text"
                            name="memberAadharNo"
                            maxLength="12"
                            value={memberAadharNo}
                            className="form-control"
                            onChange={(e) => onInputChange(e)}
                          />
                        </div> */}
                        {allPermission &&
                        allPermission.includes("AadharNumber") ? (
                          <>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                              <label
                                className="label-control"
                                style={adharErrorStyle}
                              >
                                Aadhaar Card No*&nbsp;:
                              </label>
                              <input
                                type="text"
                                name="memberAadharNo"
                                maxLength="12"
                                value={memberAadharNo}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                                required
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                              <label className="label-control">
                                Aadhaar Card No :
                              </label>
                              <input
                                type="text"
                                name="memberAadharNo"
                                maxLength="12"
                                value={memberAadharNo}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                              />
                            </div>
                          </>
                        )}
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label className="label-control">Pan Card No :</label>
                          <input
                            type="text"
                            name="memberPanNo"
                            value={memberPanNo}
                            className="form-control"
                            onChange={(e) => onInputChange(e)}
                          />
                        </div>
                        {allPermission && allPermission.includes("Disability") && (
                          <>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">
                                Have any disabilities? :
                              </label>
                              <input
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  borderRadius: "50%",
                                  display: "block",
                                }}
                                type="checkbox"
                                id="Unconfirmed"
                                onChange={handleOnChange}
                              />
                            </div>
                            {disabilitiesChecker && (
                              <>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                  <label
                                    className="label-control"
                                    style={DisabilityTypeErrorStyle}
                                  >
                                    Disability Type* :
                                  </label>
                                  <Select
                                    name="memberDisabilty"
                                    options={MemDisabilityList}
                                    isSearchable={false}
                                    value={memberDisabilityType}
                                    placeholder="Select"
                                    onChange={(e) => onMemDisabilityChange(e)}
                                    theme={(theme) => ({
                                      ...theme,
                                      height: 26,
                                      minHeight: 26,
                                      borderRadius: 1,
                                      colors: {
                                        ...theme.colors,
                                        primary: "black",
                                      },
                                    })}
                                  />
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                  <label
                                    className="label-control"
                                    style={UDIDNumberErrorStyle}
                                  >
                                    UDID Number* :
                                  </label>
                                  <input
                                    type="text"
                                    name="memberUdidNo"
                                    value={memberUdidNo}
                                    className="form-control"
                                    onChange={(e) => onInputChange(e)}
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Health Vulnerability :
                          </label>
                          <Select
                            name="healthVulnerabilityType"
                            options={HealthVulnerability}
                            isSearchable={false}
                            value={healthVulnerabilityType}
                            placeholder="Select Health Vulnerability"
                            onChange={(e) => onHealthVulnerabilityTypeChange(e)}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <label className="label-control">
                            Contact Person Name :
                          </label>
                          <input
                            type="text"
                            name="memberContactPersonName"
                            value={memberContactPersonName}
                            className="form-control"
                            onChange={(e) => onInputChange(e)}
                            required
                          />
                        </div>
                        {/* <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <label className="label-control">
                            Marital Status1:
                          </label>
                          <input
                            type="text"
                            name="memberMaritalStatus"
                            value={memberMaritalStatus}
                            className="form-control"
                            onChange={(e) => onInputChange(e)}
                            required
                          />
                        </div> */}
                        {allPermission &&
                        allPermission.includes("MemberPermission_AWAKE") ? (
                          <>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">
                                Marital Status&nbsp;:
                              </label>
                              <Select
                                name="MemberMaritalStatus"
                                options={MemMaritalStatus}
                                isSearchable={false}
                                value={MemberMaritalStatus}
                                placeholder="Select"
                                onChange={(e) => onMaritalStatusChange(e)}
                                theme={(theme) => ({
                                  ...theme,
                                  height: 26,
                                  minHeight: 26,
                                  borderRadius: 1,
                                  colors: {
                                    ...theme.colors,
                                    primary: "black",
                                  },
                                })}
                              />
                            </div>
                            {showmaritalotherstatus && (
                              <>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                  <label className="label-control">
                                    Other status :
                                  </label>
                                  <input
                                    type="text"
                                    name="otherStatus"
                                    value={otherStatus}
                                    className="form-control"
                                    onChange={(e) => onInputChange(e)}
                                  />
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">
                                Marital Status:
                              </label>
                              <input
                                type="text"
                                name="memberMaritalStatus"
                                value={memberMaritalStatus}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                                required
                              />
                            </div>
                          </>
                        )}
                        {/* <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <label className="label-control">
                            Monthly Income :
                          </label>
                          <input
                            type="text"
                            name="memberMonthlyIncome"
                            value={memberMonthlyIncome}
                            className="form-control"
                            onChange={(e) => onInputChange(e)}
                            required
                          />
                        </div> */}
                        {user &&
                        user.institutionId !== "649bce7b7d4662f26845606d" ? (
                          <>
                            {allPermission &&
                            allPermission.includes(
                              "MemberPermission_MonthlyIncome"
                            ) ? (
                              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <label className="label-control">
                                  Family Income:
                                </label>
                                <input
                                  type="text"
                                  name="memberMonthlyIncome"
                                  value={memberMonthlyIncome}
                                  className="form-control"
                                  onChange={(e) => onInputChange(e)}
                                  required
                                />
                              </div>
                            ) : (
                              <>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                  <label className="label-control">
                                    Monthly Income :
                                  </label>
                                  <input
                                    type="text"
                                    name="memberMonthlyIncome"
                                    value={memberMonthlyIncome}
                                    className="form-control"
                                    onChange={(e) => onInputChange(e)}
                                    required
                                  />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                  <label className="label-control">
                                    Annual Income :
                                  </label>
                                  <input
                                    type="text"
                                    name="memberAnnualIncome"
                                    value={anualmemIncomeVal}
                                    // value={familyMemberAnnualIncome}
                                    className="form-control"
                                    onChange={(e) => onInputChange1(e)}
                                    disabled
                                  />
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <label className="label-control">
                            Vocational Skill :
                          </label>
                          <input
                            type="text"
                            name="memberVocationalSkill"
                            value={memberVocationalSkill}
                            className="form-control"
                            onChange={(e) => onInputChange(e)}
                            required
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-6 col-12">
                          <label className="label-control">Address :</label>
                          <textarea
                            name="memberAddress"
                            value={memberAddress}
                            id="memberAddress"
                            className="textarea form-control"
                            rows="4"
                            placeholder="Address"
                            onChange={(e) => onInputChange(e)}
                            style={{ width: "100%" }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12 py-3">
                      <div className="row card-new py-3">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <h5>Other Info</h5>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label className="label-control">Member SHG :</label>
                          <input
                            type="text"
                            name="batchName"
                            value={batchName}
                            className="form-control"
                            onChange={(e) => onInputChange(e)}
                            disabled
                          />
                        </div>
                        {allPermission && allPermission.includes("Occupation") && (
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label className="label-control">
                              Occupation :
                            </label>
                            <Select
                              name="memberOccupationName"
                              options={allOccupation}
                              isSearchable={true}
                              value={memberOccupation}
                              placeholder="Select Occupation"
                              onChange={(e) => onMemberOccupationChange(e)}
                              theme={(theme) => ({
                                ...theme,
                                height: 26,
                                minHeight: 26,
                                borderRadius: 1,
                                colors: {
                                  ...theme.colors,
                                  primary: "black",
                                },
                              })}
                            />
                          </div>
                        )}
                        {allPermission &&
                          allPermission.includes("Sub Occupation") && (
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">
                                Sub Occupation :
                              </label>
                              <Select
                                name="membersubOccupation"
                                options={allSubOccupation}
                                isSearchable={true}
                                value={membersubOccupation}
                                placeholder="Select Sub Occupation"
                                onChange={(e) => onSubOccupationChange(e)}
                                theme={(theme) => ({
                                  ...theme,
                                  height: 26,
                                  minHeight: 26,
                                  borderRadius: 1,
                                  colors: {
                                    ...theme.colors,
                                    primary: "black",
                                  },
                                })}
                              />
                            </div>
                          )}
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label className="label-control">Member Code :</label>
                          <input
                            type="text"
                            name="memberCode"
                            value={mCode}
                            className="form-control"
                            onChange={(e) => onInputChange(e)}
                            required
                          />
                        </div>
                        <div className="col-lg-6 col-md-7 col-sm-7 col-12">
                          <label className="label-control">
                            SHG Formation Date :
                          </label>
                          <input
                            type="text"
                            name="bFormationDate"
                            value={bFormationDate}
                            className="form-control"
                            onChange={(e) => onInputChange(e)}
                            disabled
                          />
                        </div>
                        <div className="col-lg-6 col-md-7 col-sm-7 col-12">
                          <label className="label-control">Joined Date :</label>
                          <input
                            type="date"
                            placeholder="dd/mm/yyyy"
                            className="form-control cpp-input datevalidation"
                            name="memberJoiningDate"
                            value={startSelectedDate}
                            onChange={(e) => onDateChange(e)}
                            style={{
                              width: "75%",
                            }}
                            required
                          />
                        </div>
                        {allPermission && allPermission.includes("Religion") && (
                          <>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                              <label className="label-control">
                                Religion :
                              </label>
                              <Select
                                name="memberReligion"
                                options={allReligionsData}
                                isSearchable={false}
                                value={religion}
                                placeholder="Select"
                                onChange={(e) => onReligionChange(e)}
                                theme={(theme) => ({
                                  ...theme,
                                  height: 26,
                                  minHeight: 26,
                                  borderRadius: 1,
                                  colors: {
                                    ...theme.colors,
                                    primary: "black",
                                  },
                                })}
                              />
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                              <label className="label-control">Caste :</label>
                              <Select
                                name="memberCaste"
                                options={allCasteData}
                                isSearchable={false}
                                value={caste}
                                placeholder="Select"
                                onChange={(e) => onCasteChange(e)}
                                theme={(theme) => ({
                                  ...theme,
                                  height: 26,
                                  minHeight: 26,
                                  borderRadius: 1,
                                  colors: {
                                    ...theme.colors,
                                    primary: "black",
                                  },
                                })}
                              />
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                              <label className="label-control">
                                Sub Caste :
                              </label>
                              <Select
                                name="memberSubCaste"
                                options={allSubCaste}
                                isSearchable={false}
                                value={subCaste}
                                placeholder="Select"
                                onChange={(e) => onSubCasteChange(e)}
                                theme={(theme) => ({
                                  ...theme,
                                  height: 26,
                                  minHeight: 26,
                                  borderRadius: 1,
                                  colors: {
                                    ...theme.colors,
                                    primary: "black",
                                  },
                                })}
                              />
                            </div>
                          </>
                        )}
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label
                            className="label-control"
                            style={DesgErrorStyle}
                          >
                            Designation* :
                          </label>
                          <Select
                            name="memberDesg"
                            options={MemDesigMethods}
                            isSearchable={false}
                            value={memberDesg}
                            placeholder="Select"
                            onChange={(e) => onMemDesigChange(e)}
                            theme={(theme) => ({
                              ...theme,
                              height: 26,
                              minHeight: 26,
                              borderRadius: 1,
                              colors: {
                                ...theme.colors,
                                primary: "black",
                              },
                            })}
                          />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label className="label-control">Education :</label>
                          <Select
                            name="memberEducation"
                            options={EducationOpt}
                            isSearchable={false}
                            value={memberEducation}
                            placeholder="Select"
                            onChange={(e) => onMemmemberEducationChange(e)}
                            theme={(theme) => ({
                              ...theme,
                              height: 26,
                              minHeight: 26,
                              borderRadius: 1,
                              colors: {
                                ...theme.colors,
                                primary: "black",
                              },
                            })}
                          />
                        </div>
                        {showotherEducationSection && (
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Education :
                            </label>
                            <input
                              type="text"
                              name="otherEducation"
                              value={otherEducation}
                              className="form-control"
                              onChange={(e) => onInputChange(e)}
                              // onKeyDown={(e) => funcKeyDown(e)}
                              required
                            />
                          </div>
                        )}
                        {allPermission && allPermission.includes("GovtCards") && (
                          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Govt Cards : &emsp;
                            </label>
                            <img
                              className="img_icon_size4 log underLine"
                              onClick={() => onClickHandler()}
                              src={require("../../static/images/cards.png")}
                              alt="Govt Cards"
                              title="Add Govt Cards"
                            />
                          </div>
                        )}
                      </div>
                      {allPermission && allPermission.includes("MemberAge") && (
                        <div className="row card-new py-3">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <h5>Age Info</h5>
                          </div>
                          <div className="col-lg-6   col-md-7 col-sm-7 col-12">
                            <label className="label-control">
                              Date Of Birth :
                            </label>
                            <input
                              type="date"
                              placeholder="dd/mm/yyyy"
                              className="form-control cpp-input datevalidation"
                              name="memberDOB"
                              value={memberDOB}
                              onChange={(e) => onDateChangeDOB(e)}
                              style={{
                                width: "75%",
                              }}
                            />
                          </div>
                          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <label className="label-control">Age&nbsp;:</label>
                            <input
                              type="text"
                              name="memberAge"
                              value={memberAge}
                              className="form-control"
                              onChange={(e) => onInputChange(e)}
                              disabled
                            />
                          </div>
                          {memberAge < 18 && memberAge !== 0 && (
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                              <label
                                className="label-control"
                                style={guardianNameErrorStyle}
                              >
                                Guardian Name* :
                              </label>
                              <input
                                type="text"
                                name="guardianName"
                                value={guardianName}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                                required
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      {allPermission && allPermission.includes("Family") ? (
                        <>
                          <input
                            type="button"
                            name="Next"
                            value="Next"
                            className="btn sub_form btn_continue Save float-right"
                            onClick={(e) => NextBackBtn(e, 1)}
                          />
                        </>
                      ) : (
                        <>
                          <input
                            type="submit"
                            name="submit"
                            value="Submit"
                            className="btn sub_form btn_continue Save float-right"
                            onClick={(e) => onSubmit(e)}
                          />
                        </>
                      )}
                      <Link
                        className="btn sub_form btn_continue Save float-right"
                        to="/all-members"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </TabPanel>
            <TabPanel tabId="1">
              <form onSubmit={(e) => NextBackBtn(e, 2)}>
                <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                    <div className="row card-new  py-3">
                      <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                        <label
                          className="label-control"
                          style={famMemErrorStyle}
                        >
                          Family Member Name* :
                        </label>
                        <input
                          type="text"
                          name="familyMemberName"
                          value={familyMemberName}
                          className="form-control"
                          onChange={(e) => onInputChange1(e)}
                          required
                        />
                      </div>
                      <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                        <label className="label-control">
                          Family Member Phone :
                        </label>
                        <input
                          type="text"
                          name="familyMemberPhone"
                          value={familyMemberPhone}
                          className="form-control"
                          onChange={(e) => onInputChange1(e)}
                          required
                        />
                      </div>
                      <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                        <label className="label-control">
                          Family Member Age :
                        </label>
                        <input
                          type="text"
                          name="familyMemberAge"
                          value={familyMemberAge}
                          className="form-control"
                          onChange={(e) => onInputChange1(e)}
                          required
                        />
                      </div>
                      <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                        <label className="label-control">
                          Family Member Gender&nbsp;:
                        </label>
                        {/* <input
                          type="text"
                          name="familyMemberGender"
                          value={familyMemberGender}
                          className="form-control"
                          onChange={(e) => onInputChange1(e)}
                          required
                        /> */}
                        <Select
                          name="familyMemberGender"
                          options={GenderOpt}
                          isSearchable={false}
                          value={familyMemberGender}
                          placeholder="Select Gender"
                          onChange={(e) => onFamilyMemberGenderChange(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                        <label className="label-control">
                          Relationship to the Member :
                        </label>
                        <input
                          type="text"
                          name="familyMemberRelationship"
                          value={familyMemberRelationship}
                          className="form-control"
                          onChange={(e) => onInputChange1(e)}
                          required
                        />
                      </div>
                      <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                        <label className="label-control">Marital Status&nbsp;:</label>
                        <input
                          type="text"
                          name="familyMemberMaritalStatus"
                          value={familyMemberMaritalStatus}
                          className="form-control"
                          onChange={(e) => onInputChange1(e)}
                          required
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                        <label className="label-control">Education :</label>
                        {/* <input
                          type="text"
                          name="familyMemberEducation"
                          value={familyMemberEducation}
                          className="form-control"
                          onChange={(e) => onInputChange1(e)}
                          required
                        /> */}
                        <Select
                          name="familyMemberEducation"
                          options={EducationOpt}
                          isSearchable={false}
                          value={familyMemberEducation}
                          placeholder="Select"
                          onChange={(e) => onFammemberEducationChange(e)}
                          theme={(theme) => ({
                            ...theme,
                            height: 26,
                            minHeight: 26,
                            borderRadius: 1,
                            colors: {
                              ...theme.colors,
                              primary: "black",
                            },
                          })}
                        />
                      </div>
                      {showFamOtherEducationSection && (
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Other Education :
                          </label>
                          <input
                            type="text"
                            name="otherFamilyEducation"
                            value={otherFamilyEducation}
                            className="form-control"
                            onChange={(e) => onInputChange1(e)}
                            required
                          />
                        </div>
                      )}
                      {allPermission && allPermission.includes("Occupation") && (
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                          <label className="label-control">Occupation :</label>
                          <Select
                            name="occupationName"
                            options={allOccupation}
                            isSearchable={true}
                            value={occupation}
                            placeholder="Select Occupation"
                            onChange={(e) => onOccupationChange(e)}
                            theme={(theme) => ({
                              ...theme,
                              height: 26,
                              minHeight: 26,
                              borderRadius: 1,
                              colors: {
                                ...theme.colors,
                                primary: "black",
                              },
                            })}
                          />
                          {/* <Autocomplete
                          suggestions={allOccupation}
                          setOcc={setOcc}
                          occ={occ}
                        /> */}
                          {/* <input
                          type="text"
                          name="familyMemberOcupation"
                          value={familyMemberOcupation}
                          className="form-control"
                          onChange={(e) => onInputChange1(e)}
                          required
                        /> */}
                        </div>
                      )}
                      {allPermission &&
                        allPermission.includes("Sub Occupation") && (
                          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <label className="label-control">
                              Sub Occupation :
                            </label>
                            <Select
                              name="familyMemberSubOccupation"
                              options={allSubOccupation}
                              isSearchable={true}
                              value={familySubOccupation}
                              placeholder="Select Sub Occupation"
                              onChange={(e) => onFamilySubOccupationChange(e)}
                              theme={(theme) => ({
                                ...theme,
                                height: 26,
                                minHeight: 26,
                                borderRadius: 1,
                                colors: {
                                  ...theme.colors,
                                  primary: "black",
                                },
                              })}
                            />
                          </div>
                        )}
                      <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                        <label className="label-control">
                          Monthly Income :
                        </label>
                        <input
                          type="number"
                          name="familyMemberMonthlyIncome"
                          value={familyMemberMonthlyIncome}
                          className="form-control"
                          onChange={(e) => onInputChange1(e)}
                        />
                      </div>
                      <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                        <label className="label-control">Annual Income :</label>
                        <input
                          type="text"
                          name="familyMemberAnnualIncome"
                          value={anualIncomeVal}
                          // value={familyMemberAnnualIncome}
                          className="form-control"
                          onChange={(e) => onInputChange1(e)}
                          disabled
                        />
                      </div>
                      {allPermission && allPermission.includes("Religion") && (
                        <>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">Religion :</label>
                            <Select
                              name="familyMemberReligion"
                              options={allFamilyReligionsData}
                              isSearchable={false}
                              value={familyReligion}
                              placeholder="Select"
                              onChange={(e) => onFamilyReligionChange(e)}
                              theme={(theme) => ({
                                ...theme,
                                height: 26,
                                minHeight: 26,
                                borderRadius: 1,
                                colors: {
                                  ...theme.colors,
                                  primary: "black",
                                },
                              })}
                            />
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">Caste :</label>
                            <Select
                              name="familyMemberCaste"
                              options={allFamilyCasteData}
                              isSearchable={false}
                              value={familyCaste}
                              placeholder="Select"
                              onChange={(e) => onFamilyCasteChange(e)}
                              theme={(theme) => ({
                                ...theme,
                                height: 26,
                                minHeight: 26,
                                borderRadius: 1,
                                colors: {
                                  ...theme.colors,
                                  primary: "black",
                                },
                              })}
                            />
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">Sub Caste :</label>
                            <Select
                              name="familyMemberSubCaste"
                              options={allFamilySubCaste}
                              isSearchable={false}
                              value={familySubCaste}
                              placeholder="Select"
                              onChange={(e) => onFamilySubCasteChange(e)}
                              theme={(theme) => ({
                                ...theme,
                                height: 26,
                                minHeight: 26,
                                borderRadius: 1,
                                colors: {
                                  ...theme.colors,
                                  primary: "black",
                                },
                              })}
                            />
                          </div>
                        </>
                      )}
                      {allPermission && allPermission.includes("Disability") && (
                        <>
                          <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                            <label className="label-control">
                              Have any disabilities? :
                            </label>
                            <input
                              style={{
                                height: "20px",
                                width: "20px",
                                borderRadius: "50%",
                                display: "block",
                              }}
                              type="checkbox"
                              checked={familyDisabilitiesChecker}
                              id="Unconfirmed"
                              onChange={handleOnFamilyChange}
                            />
                          </div>
                          {familyDisabilitiesChecker && (
                            <>
                              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <label
                                  className="label-control"
                                  style={DisabilityTypeErrorStyle}
                                >
                                  Disability Type* :
                                </label>
                                <Select
                                  name="familyMemberDisabilityType"
                                  options={MemDisabilityList}
                                  isSearchable={false}
                                  value={familyMemberDisabilityType}
                                  placeholder="Select"
                                  onChange={(e) => onFamDisabilityChange(e)}
                                  theme={(theme) => ({
                                    ...theme,
                                    height: 26,
                                    minHeight: 26,
                                    borderRadius: 1,
                                    colors: {
                                      ...theme.colors,
                                      primary: "black",
                                    },
                                  })}
                                />
                              </div>
                              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <label className="label-control">
                                  UDID Number* :
                                </label>
                                <input
                                  type="text"
                                  name="familyUdidNo"
                                  value={familyUdidNo}
                                  className="form-control"
                                  onChange={(e) => onInputChange1(e)}
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {allPermission && allPermission.includes("GovtCards") && (
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Govt Cards : &emsp;
                          </label>
                          <img
                            className="img_icon_size4 log underLine"
                            onClick={() => onFamilyClickHandler()}
                            src={require("../../static/images/cards.png")}
                            alt="Govt Cards"
                            title="Add Govt Cards"
                          />
                        </div>
                      )}
                      <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                        <button
                          className="btn sub_form btn_continue Save"
                          onClick={(e) => onAdd(e)}
                        >
                          ADD
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                    <div className="row card-new py-3">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className=" body-inner no-padding  table-responsive">
                          <table
                            className="tabllll table table-bordered table-striped table-hover"
                            id="datatable2"
                          >
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Age </th>
                                <th>Gender</th>
                                <th>Relationship</th>
                                <th>Marital Status</th>
                                <th>Education</th>
                                <th>Other Education</th>
                                {allPermission &&
                                  allPermission.includes("Occupation") && (
                                    <th>Occupation </th>
                                  )}
                                {allPermission &&
                                  allPermission.includes("Sub Occupation") && (
                                    <th>Sub Occupation </th>
                                  )}
                                <th>Monthly Income</th>
                                <th>Annual Income</th>
                                {allPermission &&
                                  allPermission.includes("Religion") && (
                                    <>
                                      <th>Religion</th>
                                      <th>Caste</th>
                                      <th>Sub Caste</th>
                                    </>
                                  )}
                                {allPermission &&
                                  allPermission.includes("Disability") && (
                                    <>
                                      <th>Disability Type</th>
                                      <th>UDID Number</th>
                                    </>
                                  )}
                                {allPermission &&
                                  allPermission.includes("GovtCards") && (
                                    <>
                                      <th>Govt Cards</th>
                                    </>
                                  )}
                                <th>Remove</th>
                              </tr>
                            </thead>
                            <tbody>
                              {AddedDetails &&
                                AddedDetails.map((AddDetail, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>{AddDetail.familyMemberName}</td>
                                      <td>{AddDetail.familyMemberPhone}</td>
                                      <td>{AddDetail.familyMemberAge}</td>
                                      <td>{AddDetail.familyMemberGender}</td>
                                      <td>
                                        {AddDetail.familyMemberRelationship}
                                      </td>
                                      <td>
                                        {AddDetail.familyMemberMaritalStatus}
                                      </td>
                                      <td>{AddDetail.familyMemberEducation}</td>
                                      <td>{AddDetail.otherFamilyEducation}</td>
                                      {allPermission &&
                                        allPermission.includes(
                                          "Occupation"
                                        ) && (
                                          <td>
                                            {AddDetail.familyMemberOcupation}
                                          </td>
                                        )}
                                      {allPermission &&
                                        allPermission.includes(
                                          "Sub Occupation"
                                        ) && (
                                          <td>
                                            {
                                              AddDetail.familyMemberSubOccupation
                                            }
                                          </td>
                                        )}
                                      <td>
                                        {AddDetail.familyMemberMonthlyIncome}
                                      </td>
                                      <td>
                                        {AddDetail.familyMemberAnnualIncome}
                                      </td>
                                      {allPermission &&
                                        allPermission.includes("Religion") && (
                                          <>
                                            <td>
                                              {AddDetail.familyMemberReligion}
                                            </td>
                                            <td>
                                              {AddDetail.familyMemberCaste}
                                            </td>
                                            <td>
                                              {AddDetail.familyMemberSubCaste}
                                            </td>
                                          </>
                                        )}
                                      {allPermission &&
                                        allPermission.includes(
                                          "Disability"
                                        ) && (
                                          <>
                                            <td>
                                              {
                                                AddDetail.familyMemberDisabilityType
                                              }
                                            </td>
                                            <td>{AddDetail.familyUdidNo}</td>
                                          </>
                                        )}
                                      {allPermission &&
                                        allPermission.includes("GovtCards") && (
                                          <>
                                            <td title={AddDetail.familyCards}>
                                              <img
                                                className="img_icon_size log"
                                                src={require("../../static/images/info.png")}
                                                alt="Govt Cards"
                                                title={AddDetail.familyCards}
                                              />
                                            </td>
                                          </>
                                        )}
                                      <td>
                                        <img
                                          className="img_icon_size log"
                                          onClick={() =>
                                            onRemoveChange(
                                              AddDetail.familyMemberName
                                            )
                                          }
                                          src={require("../../static/images/close-buttonRed.png")}
                                          alt="Remove"
                                          title="Remove"
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <form className="row" onSubmit={(e) => onSubmit(e)}>
                <div className="col-lg-11 col-md-12 col-sm-12 col-12">
                  <input
                    type="submit"
                    name="Save"
                    value="Submit"
                    className="btn sub_form btn_continue Save float-right"
                  />
                  <button
                    className="btn sub_form btn_continue Save float-right"
                    onClick={(e) => NextBackBtn(e, 0)}
                  >
                    Previous
                  </button>
                </div>
              </form>
            </TabPanel>
          </Tabs>
          <div className="col-lg-12 col-md-6 col-sm-12 col-12">
            <label className="label-control colorRed">
              * Indicates mandatory fields, Please fill mandatory fields before
              Submit
            </label>
          </div>
        </section>
        <Modal
          show={showUpdateModal}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">
                Preview of Add Member Details
              </h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleUpdateModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="container container_align">
              <section className="sub_reg">
                <div className="row col-lg-12 col-md-11 col-sm-12 col-12 ">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12 py-3">
                    <div className="row card-new  pb-3">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5>Personal Info </h5>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Member Name : {memberName}
                        </label>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Member Phone : {memberPhone}
                        </label>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Gender : {memberGender.value}
                        </label>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Ration Card : {rationCardType.value}
                        </label>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Aadhaar Card No : {memberAadharNo}
                        </label>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Pan Card No : {memberPanNo}
                        </label>
                      </div>
                      {allPermission && allPermission.includes("Occupation") && (
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <label className="label-control-sm">
                            Occupation : {memberOccupationName}
                          </label>
                        </div>
                      )}
                      {allPermission &&
                        allPermission.includes("Sub Occupation") && (
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label className="label-control-sm">
                              Sub Occupation : {memberSubOccupationName}
                            </label>
                          </div>
                        )}
                      {allPermission && allPermission.includes("Disability") && (
                        <>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label className="label-control-sm">
                              Have any disabilities :
                              {disabilitiesChecker ? "Yes" : "No"}
                            </label>
                          </div>
                          {disabilitiesChecker && (
                            <>
                              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <label className="label-control-sm">
                                  UDID Number : {memberUdidNo}
                                </label>
                              </div>
                              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <label className="label-control-sm">
                                  Disability Type : {memberDisabilityType.value}
                                </label>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Health Vulnerability : {healthVulnerabilityType.value}
                        </label>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Contact Person Name : {memberContactPersonName}
                        </label>
                      </div>
                      {/* <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Marital Status : {memberMaritalStatus}
                        </label>
                      </div> */}
                      {allPermission &&
                      allPermission.includes("MemberPermission_AWAKE") ? (
                        <>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label className="label-control-sm">
                              Marital Status :{" "}
                              {MemberMaritalStatus && MemberMaritalStatus.value}
                            </label>
                          </div>
                          {showmaritalotherstatus && (
                            <>
                              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <label className="label-control-sm">
                                  Other Status : {otherStatus}
                                </label>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label className="label-control-sm">
                              Marital Status : {memberMaritalStatus}
                            </label>
                          </div>
                        </>
                      )}
                      {/* <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Monthly Income : {memberMonthlyIncome}
                        </label>
                      </div> */}
                      {allPermission &&
                      allPermission.includes(
                        "MemberPermission_MonthlyIncome"
                      ) ? (
                        <>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label className="label-control-sm">
                              Family Income : {memberMonthlyIncome}
                            </label>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label className="label-control-sm">
                              Monthly Income : {memberMonthlyIncome}
                            </label>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label className="label-control-sm">
                              Annual Income : {anualmemIncomeVal}
                            </label>
                          </div>
                        </>
                      )}
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Vocational Skill : {memberVocationalSkill}
                        </label>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Address : {memberAddress}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3 no_padding">
                      <div className="row card-new pb-3">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <h5>SHG Info & Age Info </h5>
                        </div>
                        <div className="row ccol-lg-12 col-md-12 col-sm-12 col-12 no_padding ">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label className="label-control-sm">
                              Member SHG : {batchName}
                            </label>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label className="label-control-sm">
                              Member Code : {mCode}
                            </label>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label className="label-control-sm">
                              Joined Date : {previewSelectedDate}
                            </label>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label className="label-control-sm">
                              Designation : {memberDesg.value}
                            </label>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label className="label-control-sm">
                              Education : {memberEducation.value}
                            </label>
                          </div>
                          {allPermission && allPermission.includes("Religion") && (
                            <>
                              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <label className="label-control-sm">
                                  Religion : {religionName}
                                </label>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <label className="label-control-sm">
                                  Cast : {casteName}
                                </label>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <label className="label-control-sm">
                                  Sub Cast : {subCasteName}
                                </label>
                              </div>
                            </>
                          )}
                          {allPermission &&
                            allPermission.includes("MemberAge") && (
                              <>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                  <label className="label-control-sm">
                                    Date Of Birth :
                                    {memberDOB
                                      ? memberDOB
                                          .split("-")
                                          .reverse()
                                          .join("-")
                                      : ""}
                                  </label>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                  <label className="label-control-sm">
                                    Age: {memberAge}
                                  </label>
                                </div>
                                {memberAge < 18 && memberAge !== 0 && (
                                  <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                                    <label className="label-control-sm">
                                      Guardian Name : {guardianName}
                                    </label>
                                  </div>
                                )}
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {allPermission && allPermission.includes("Family") && (
                    <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3 no_padding">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <h5>Family Members Info </h5>
                          </div>
                          <div className=" body-inner no-padding  table-responsive">
                            <table
                              className="tabllll table table-bordered table-striped table-hover"
                              id="datatable2"
                            >
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Phone</th>
                                  <th>Age </th>
                                  <th>Gender</th>
                                  <th>Relationship</th>
                                  <th>Marital Status</th>
                                  <th>Education</th>
                                  <th>Other Education</th>
                                  {allPermission &&
                                    allPermission.includes("Occupation") && (
                                      <th>Occupation </th>
                                    )}
                                  {allPermission &&
                                    allPermission.includes(
                                      "Sub Occupation"
                                    ) && <th>Sub Occupation </th>}
                                  <th>Monthly Income</th>
                                  <th>Annual Income</th>
                                  {allPermission &&
                                    allPermission.includes("Religion") && (
                                      <>
                                        <th>Religion</th>
                                        <th>Caste</th>
                                        <th>Sub Caste</th>
                                      </>
                                    )}
                                  {allPermission &&
                                    allPermission.includes("Disability") && (
                                      <>
                                        <th>Disability Type</th>
                                        <th>UDID Number</th>
                                      </>
                                    )}
                                </tr>
                              </thead>
                              <tbody>
                                {AddedDetails &&
                                  AddedDetails.map((AddDetail, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <td>{AddDetail.familyMemberName}</td>
                                        <td>{AddDetail.familyMemberPhone}</td>
                                        <td>{AddDetail.familyMemberAge}</td>
                                        <td>{AddDetail.familyMemberGender}</td>
                                        <td>
                                          {AddDetail.familyMemberRelationship}
                                        </td>
                                        <td>
                                          {AddDetail.familyMemberMaritalStatus}
                                        </td>
                                        <td>
                                          {AddDetail.familyMemberEducation}
                                        </td>
                                        <td>
                                          {AddDetail.otherFamilyEducation}
                                        </td>
                                        {allPermission &&
                                          allPermission.includes(
                                            "Occupation"
                                          ) && (
                                            <td>
                                              {AddDetail.familyMemberOcupation}
                                            </td>
                                          )}
                                        {allPermission &&
                                          allPermission.includes(
                                            "Sub Occupation"
                                          ) && (
                                            <td>
                                              {
                                                AddDetail.familyMemberSubOccupation
                                              }
                                            </td>
                                          )}
                                        <td>
                                          {AddDetail.familyMemberMonthlyIncome}
                                        </td>
                                        <td>
                                          {AddDetail.familyMemberAnnualIncome}
                                        </td>
                                        {allPermission &&
                                          allPermission.includes(
                                            "Religion"
                                          ) && (
                                            <>
                                              <td>
                                                {AddDetail.familyMemberReligion}
                                              </td>
                                              <td>
                                                {AddDetail.familyMemberCaste}
                                              </td>
                                              <td>
                                                {AddDetail.familyMemberSubCaste}
                                              </td>
                                            </>
                                          )}
                                        {allPermission &&
                                          allPermission.includes(
                                            "Disability"
                                          ) && (
                                            <>
                                              <td>
                                                {
                                                  AddDetail.familyMemberDisabilityType
                                                }
                                              </td>
                                              <td>{AddDetail.familyUdidNo}</td>
                                            </>
                                          )}
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="row col-lg-12 col-md-11 col-sm-12 col-12 Savebutton no_padding"
                  size="lg"
                >
                  <div className="col-lg-12 col-md-6 col-sm-12 col-12">
                    {loading ? (
                      <button
                        className="btn sub_form btn_continue blackbrd Save float-right"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        variant="success"
                        className="btn sub_form btn_continue blackbrd Save float-right"
                        onClick={() => onSaveSubmit()}
                      >
                        Save
                      </button>
                    )}
                    <Link
                      className="btn sub_form btn_continue blackbrd float-right"
                      onClick={handleUpdateModalClose}
                      to="#"
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </section>
            </div>
          </Modal.Body>
        </Modal>
        {showCardsModal && (
          <GovtCards
            showCardsModal={showCardsModal}
            handleCardsModalClose={handleCardsModalClose}
            setSelectedCards={setSelectedCards}
            SelectedCards={SelectedCards}
          />
        )}
        {showCardsFamilyModal && (
          <GovtCards
            showCardsModal={showCardsFamilyModal}
            handleCardsModalClose={handleCardsFamilyModalClose}
            setSelectedCards={setSelectedCardsFamily}
            SelectedCards={SelectedCardsFamily}
          />
        )}
        <Modal
          show={showAlertMsg}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="logout-modal"
        >
          <Modal.Header className="confirmbox-heading">
            <h4 className="mt-0">Alert</h4>
          </Modal.Header>
          <Modal.Body>
            <h5>
              Please Select a Date which is greater than Batch Formation Date{" "}
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn contact_reg "
              style={{ backgroundColor: "#144c5a" }}
              onClick={() => {
                onUpdateALertMsg();
              }}
            >
              OK
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </Fragment>
  );
};

AddMemberDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  religionSetting: PropTypes.object.isRequired,
  AddMember: PropTypes.func.isRequired,
  getSubcasteDetails: PropTypes.func.isRequired,
  getSubOccupation: PropTypes.func.isRequired,
  getCaste: PropTypes.func.isRequired,
  getBatchDropdown: PropTypes.func.isRequired,
  getBatchInfo: PropTypes.func.isRequired,
  getOcccupationsDropdown: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  religionSetting: state.religionSetting,
});

export default connect(mapStateToProps, {
  AddMember,
  getBatchInfo,
  getBatchDropdown,
  getShgMembersFamilyOccupation,
  getOcccupationsDropdown,
  getReligions,
  getCaste,
  getSubcasteDetails,
  getSubOccupation,
})(AddMemberDetails);
