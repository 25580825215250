import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getTestData, getLoanRepaymentOfMeber } from "../../actions/shg";
import Spinner from "../layout/Spinner";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const TestReport = ({
  auth: { isAuthenticated, user, users },
  shg: { test_data, curMemMettingLoanRepayment },
  getTestData,
  getLoanRepaymentOfMeber,
}) => {
  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("bId"));
    getTestData({ batchId: data });
    getLoanRepaymentOfMeber({ batchId: data });
  }, []);
  const location = useLocation();
  const scanData = location.state ? location.state.batchName : "";

  let InternalLoanArray = [];
  let InternalRepaymentArray = [];
  let internalLoanDifferenceArray = [];
  let OtherLoanArray = [];
  let OtherLoanRepaymentArray = [];
  let OtherLoanBatchInfo = [];
  let otherLoanSanctionedInfo = [];

  // console.log(test_data)
  const calculateInternalLoan = async (
    test_data,
    calculateOtherLoan,
    calculateInternalRepayment,
    calculateOtherLoanRepayment
  ) => {
    let missingPrincipalArray = [];

    // Iterate over each parent record (batch)
    test_data.forEach((batchRecord) => {
      let meetingHeldOnDate = batchRecord.batchMeetingHeldOnDate;

      let totalCashAmount = 0;
      let totalPayableAmt = 0;
      let allCashTransactions = true;

      // Iterate over each child record (member)
      batchRecord.childRecords.forEach((child) => {
        if (child.internalLoan && child.internalLoan.length > 0) {
          let memberCashAmount = 0;
          let memberPayableAmt = 0;

          // Iterate over each loan in the internalLoan array
          child.internalLoan.forEach((loan) => {
            let cashAmount = parseFloat(loan.loanPayablePrincipal.cash) || 0; // Ensure cash is a number
            let bankAmount = parseFloat(loan.loanPayablePrincipal.bankAmt) || 0; // Ensure bankAmt is a number
            let payableAmt = parseFloat(loan.TotalPayableAmt) || 0; // Ensure TotalPayableAmt is a number

            // Check if the principal amount is missing or zero
            if (
              !loan.loanPayablePrincipal ||
              !loan.loanPayablePrincipal.cash ||
              loan.loanPayablePrincipal.cash === "0" ||
              loan.loanPayablePrincipal.cash === ""
            ) {
              missingPrincipalArray.push({
                memberId: child.memberId,
                memberName: child.memberName,
                meetingHeldOnDate: meetingHeldOnDate,
                loan: loan,
                message: "Missing principal amount or amount is zero",
              });
            }

            // Accumulate totals for the current member
            memberCashAmount += cashAmount;
            memberPayableAmt += payableAmt;

            // Accumulate total cash amounts and check if there are bank transactions
            totalCashAmount += cashAmount;
            totalPayableAmt += payableAmt;

            if (bankAmount !== 0) {
              allCashTransactions = false; // If any transaction is bank-related, flag as false
            }
          });

          // Add entry for the member to InternalLoanArray
          InternalLoanArray.push({
            totalPayableAmt: memberPayableAmt, // Add member-specific payable amount
            totalCashAmount: memberCashAmount, // Add member-specific cash amount
            memberId: child.memberId,
            memberName: child.memberName,
            meetingHeldOnDate: meetingHeldOnDate,
          });
        }
      });

      // If all transactions for this meeting date are cash transactions, add a combined entry
      if (allCashTransactions) {
        InternalLoanArray.push({
          totalPayableAmt: totalPayableAmt,
          totalCashAmount: totalCashAmount,
          memberId: "All Members",
          memberName: "All Members",
          meetingHeldOnDate: meetingHeldOnDate,
        });
      }
    });

    // Call next function after processing internal loans
    await calculateOtherLoan(
      test_data,
      calculateInternalRepayment,
      calculateOtherLoanRepayment
    );
  };

  const calculateOtherLoan = async (
    test_data,
    calculateInternalRepayment,
    calculateOtherLoanRepayment
  ) => {
    test_data.forEach((batchRecord) => {
      let meetingHeldOnDate = batchRecord.batchMeetingHeldOnDate;
      let totalOtherLoanTakenAmtCash = 0;
      let totalOtherLoanPrincipalSum = 0;
      let allOtherLoanCashTransactions = true;

      let memberCashData = [];

      batchRecord.childRecords.forEach((child) => {
        if (child.otherLoan && child.otherLoan.length > 0) {
          child.otherLoan.forEach((otherLoan) => {
            let otherLoanTakenAmtCash = parseFloat(
              otherLoan.otherLoanTakenAmt.cash
            );
            let otherLoanTakenAmtBank = parseFloat(
              otherLoan.otherLoanTakenAmt.bankAmt
            );

            totalOtherLoanTakenAmtCash += otherLoanTakenAmtCash;

            if (otherLoanTakenAmtBank !== 0) {
              allOtherLoanCashTransactions = false;
            }

            let otherLoanPayablePrincipalCash = parseFloat(
              otherLoan.otherloanPayablePrincipal.cash
            );
            let otherLoanPayablePrincipalBank = parseFloat(
              otherLoan.otherloanPayablePrincipal.bankAmt
            );
            totalOtherLoanPrincipalSum +=
              otherLoanPayablePrincipalCash + otherLoanPayablePrincipalBank;

            memberCashData.push({
              loanAmountOtherCash: otherLoanTakenAmtCash,
              loanAmountOtherBank: otherLoanTakenAmtBank,
              othLoanID: otherLoan.othLoanID,
              memberId: child.memberId,
              memberName: child.memberName,
              meetingHeldOnDate: meetingHeldOnDate,
            });
          });
        }
      });

      OtherLoanBatchInfo.push({
        totalOtherLoanPrincipalSum: totalOtherLoanPrincipalSum,
        meetingHeldOnDate: meetingHeldOnDate,
        memberId: "All Members",
        memberName: "All Members",
      });

      if (allOtherLoanCashTransactions) {
        OtherLoanArray.push({
          otherLoanAmount: totalOtherLoanTakenAmtCash,
          memberId: "All Members",
          memberName: "All Members",
          meetingHeldOnDate: meetingHeldOnDate,
        });
      } else {
        OtherLoanArray = OtherLoanArray.concat(memberCashData);
      }
    });
    await calculateInternalRepayment(test_data, calculateOtherLoanRepayment);
  };

  const calculateInternalRepayment = async (
    test_data,
    calculateOtherLoanRepayment
  ) => {
    let repaymentSummary = {}; // Use an object to group by memberId

    test_data.forEach((batchRecord) => {
      let meetingHeldOnDate = batchRecord.batchMeetingHeldOnDate;

      batchRecord.childRecords.forEach((child) => {
        let internalRepaymentMade = false; // Default to false
        let totalPrincipalCash = 0;
        let totalPrincipalBank = 0;

        if (child.internalRepayment && child.internalRepayment.length > 0) {
          child.internalRepayment.forEach((internalRepayment) => {
            // Extract the principal paid (cash and bank)
            let principalPaidCash = parseFloat(
              internalRepayment.internalPrincipalPaid.cash
            );
            let principalPaidBank = parseFloat(
              internalRepayment.internalPrincipalPaid.bankAmt
            );

            // Sum up the principal repayments for cash and bank
            totalPrincipalCash += principalPaidCash;
            totalPrincipalBank += principalPaidBank;

            // Check if any repayment is made
            if (principalPaidCash > 0 || principalPaidBank > 0) {
              internalRepaymentMade = true; // If any repayment, set to true
            }
          });
        }

        // If the member already exists in the repaymentSummary object, aggregate the results
        if (!repaymentSummary[child.memberId]) {
          repaymentSummary[child.memberId] = {
            memberId: child.memberId,
            memberName: child.memberName,
            internalRepaymentMade: internalRepaymentMade,
            totalPrincipalCash: totalPrincipalCash,
            totalPrincipalBank: totalPrincipalBank,
            totalRepaymentAmount: totalPrincipalCash + totalPrincipalBank,
            meetingHeldOnDate: meetingHeldOnDate,
          };
        } else {
          // If the member already exists, add the new repayments to the existing totals
          repaymentSummary[
            child.memberId
          ].totalPrincipalCash += totalPrincipalCash;
          repaymentSummary[
            child.memberId
          ].totalPrincipalBank += totalPrincipalBank;
          repaymentSummary[child.memberId].totalRepaymentAmount +=
            totalPrincipalCash + totalPrincipalBank;
          repaymentSummary[child.memberId].internalRepaymentMade =
            repaymentSummary[child.memberId].internalRepaymentMade ||
            internalRepaymentMade;
        }
      });
    });

    // Convert the repaymentSummary object to an array for easier processing or output
    InternalRepaymentArray = Object.values(repaymentSummary);

    await calculateOtherLoanRepayment(test_data); // Call the next function (if required)
  };

  const calculateOtherLoanRepayment = async (test_data) => {
    test_data.forEach((batchRecord) => {
      let meetingHeldOnDate = batchRecord.batchMeetingHeldOnDate;

      // Create a dictionary to accumulate other loan repayment information for the same `meetingHeldOnDate`
      let otherLoanRepaymentsByMeetingDate = {};

      batchRecord.childRecords.forEach((child) => {
        // Handling otherLoan
        if (child.otherLoan && child.otherLoan.length > 0) {
          child.otherLoan.forEach((otherLoan) => {
            let otherLoanPaidCash = parseFloat(otherLoan.otherLoanPaid.cash);
            let otherLoanPaidBank = parseFloat(otherLoan.otherLoanPaid.bankAmt);
            let otherInterestPaidCash = parseFloat(
              otherLoan.otherInterestPaid.cash
            );
            let otherInterestPaidBank = parseFloat(
              otherLoan.otherInterestPaid.bankAmt
            );

            // Initialize the record for this meetingHeldOnDate if it doesn't exist yet
            if (!otherLoanRepaymentsByMeetingDate[meetingHeldOnDate]) {
              otherLoanRepaymentsByMeetingDate[meetingHeldOnDate] = {
                totalOtherLoanPaidCash: 0,
                totalOtherLoanPaidBank: 0,
                totalOtherInterestPaidCash: 0,
                totalOtherInterestPaidBank: 0,
                members: [],
                allCash: true, // Flag to track if all transactions for this date are cash-only
              };
            }

            // Accumulate the amounts for cash and bank transactions
            otherLoanRepaymentsByMeetingDate[
              meetingHeldOnDate
            ].totalOtherLoanPaidCash += otherLoanPaidCash;
            otherLoanRepaymentsByMeetingDate[
              meetingHeldOnDate
            ].totalOtherLoanPaidBank += otherLoanPaidBank;
            otherLoanRepaymentsByMeetingDate[
              meetingHeldOnDate
            ].totalOtherInterestPaidCash += otherInterestPaidCash;
            otherLoanRepaymentsByMeetingDate[
              meetingHeldOnDate
            ].totalOtherInterestPaidBank += otherInterestPaidBank;

            // If there are any bank transactions, mark that it's not all cash
            if (otherLoanPaidBank !== 0 || otherInterestPaidBank !== 0) {
              otherLoanRepaymentsByMeetingDate[
                meetingHeldOnDate
              ].allCash = false;
            }

            // Add the member info to the respective meetingHeldOnDate
            otherLoanRepaymentsByMeetingDate[meetingHeldOnDate].members.push({
              memberId: child.memberId,
              memberName: child.memberName,
            });
          });
        }
      });

      // Now, process the accumulated other loan repayments by meetingHeldOnDate
      Object.keys(otherLoanRepaymentsByMeetingDate).forEach((date) => {
        let repayment = otherLoanRepaymentsByMeetingDate[date];

        // If all repayments for this meetingHeldOnDate are cash-only, add a combined record
        if (repayment.allCash) {
          OtherLoanRepaymentArray.push({
            totalOtherLoanPaidCash: repayment.totalOtherLoanPaidCash,
            totalOtherInterestPaidCash: repayment.totalOtherInterestPaidCash,
            totalRepayment:
              repayment.totalOtherLoanPaidCash +
              repayment.totalOtherInterestPaidCash,
            memberId: "All Members", // Use "All Members" for combined record
            memberName: "All Members",
            meetingHeldOnDate: date,
          });
        } else {
          // Otherwise, keep separate records for each member (if there are bank transactions)
          repayment.members.forEach((member) => {
            OtherLoanRepaymentArray.push({
              totalOtherLoanPaidCash: repayment.totalOtherLoanPaidCash,
              totalOtherLoanPaidBank: repayment.totalOtherLoanPaidBank,
              totalOtherInterestPaidCash: repayment.totalOtherInterestPaidCash,
              totalOtherInterestPaidBank: repayment.totalOtherInterestPaidBank,
              totalRepayment:
                repayment.totalOtherLoanPaidCash +
                repayment.totalOtherLoanPaidBank +
                repayment.totalOtherInterestPaidCash +
                repayment.totalOtherInterestPaidBank,
              memberId: member.memberId,
              memberName: member.memberName,
              meetingHeldOnDate: date,
            });
          });
        }
      });
    });


    // console.log("OtherLoanRepaymentArray",OtherLoanRepaymentArray)
  };

  calculateInternalLoan(
    test_data,
    calculateOtherLoan,
    calculateInternalRepayment,
    calculateOtherLoanRepayment
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const calculateInternalDifference = () => {
    let resultArray = [];

    InternalRepaymentArray.forEach((repayment) => {
      if (repayment.internalRepaymentMade) {
        const matchingLoan = InternalLoanArray.find(
          (loan) => loan.memberId === repayment.memberId
        );

        // If no matching loan is found (i.e., memberId not in InternalLoanArray)
        if (!matchingLoan) {
          resultArray.push({
            memberId: repayment.memberId,
            memberName: repayment.memberName,
            internalRepaymentMade: repayment.internalRepaymentMade,
            totalPrincipalCash: repayment.totalPrincipalCash,
            totalPrincipalBank: repayment.totalPrincipalBank,
            ErrorType: "Internal Loan",
            errorMessage: "Loan not sanctioned but repayment has been made",
            solution: "Please contact Pinnacle for assistance.",
            meetingDate: repayment.meetingHeldOnDate, // From repayment
          });
        }
      }
    });

    internalLoanDifferenceArray = resultArray;
  };

  const calculateOtherLoanDifference = () => {
    let totalOtherLoan = 0;
    let totalOtherRepayment = 0;

    OtherLoanArray.forEach((loan, index) => {
      totalOtherLoan += loan.otherLoanAmount || 0;
    });

    OtherLoanRepaymentArray.forEach((repayment, index) => {
      totalOtherRepayment += repayment.totalRepayment || 0;
    });

    const difference = totalOtherLoan - totalOtherRepayment;
  };

  const calculateOtherLoanSanctioned = async (test_data) => {
    //////////////////////otherLoanSanctioned details start/////////////////////////////////
    test_data.forEach((batchRecord) => {
      if (
        batchRecord.otherLoanBatchTransaction != null &&
        Array.isArray(batchRecord.otherLoanBatchTransaction)
      ) {
        let totalSanctionedCash = 0;
        let totalSanctionedBankAmt = 0;

        batchRecord.otherLoanBatchTransaction.forEach((transaction) => {
          if (transaction.otherLoanSanctionedToShg) {
            let sanctionedToShgCash =
              parseFloat(transaction.otherLoanSanctionedToShg.cash) || 0;
            let sanctionedToShgBankAmt =
              parseFloat(transaction.otherLoanSanctionedToShg.bankAmt) || 0;

            totalSanctionedCash += sanctionedToShgCash;
            totalSanctionedBankAmt += sanctionedToShgBankAmt;
          }
        });

        otherLoanSanctionedInfo.push({
          totalSanctionedCash: totalSanctionedCash,
          totalSanctionedBankAmt: totalSanctionedBankAmt,
          totalSanctioned: totalSanctionedCash + totalSanctionedBankAmt,
          batchMeetingHeldOnDate: batchRecord.batchMeetingHeldOnDate,
        });
      }
    });
    ////////////////////otherLoanSanctioned details end////////////////////////////////////

    ////////////////////sum of total other loan sanctiond by batch and member start///////
    const totalSanctionedFromBatchInfo = otherLoanSanctionedInfo.reduce(
      (acc, item) => acc + item.totalSanctioned,
      0
    );
    const totalOtherLoanPrincipalSumFromMemberInfo = OtherLoanBatchInfo.reduce(
      (acc, item) => acc + item.totalOtherLoanPrincipalSum,
      0
    );
    //////////////////sum of total other loan sanctioned by batch and member end ///////
    // Filter the entries where totalSanctioned is greater than 0
    const datesWithSanctionedAmount = otherLoanSanctionedInfo
      .filter((item) => item.totalSanctioned > 0)
      .map((item) => item.batchMeetingHeldOnDate);

    ///////////////////calling function to calculate income total////////////////////////
    await calculate_Income_total(test_data);
  };
  /////////////////////////////////calculation loan repayment starts here///////////////////////////

  const internalRepaymentArray = [];
  const dateMap = {};
  test_data.forEach((batch) => {
    batch.childRecords.forEach((childRecord) => {
      if (
        childRecord.internalRepayment &&
        Array.isArray(childRecord.internalRepayment)
      ) {
        const batchDateKey = batch.batchMeetingHeldOnDate;

        if (!dateMap[batchDateKey]) {
          dateMap[batchDateKey] = {
            batchMeetingHeldOnDate: batchDateKey,
            internalRepayments: [],
          };
        }

        dateMap[batchDateKey].internalRepayments.push({
          memberId: childRecord.memberId,
          memberName: childRecord.memberName,
          internalRepayment: childRecord.internalRepayment,
        });
      }
    });
  });
  Object.keys(dateMap).forEach((date) => {
    internalRepaymentArray.push(dateMap[date]);
  });
  //////////////////////////////////////calculation loan repayment ends here///////////////////////////

  ///////////////////check the difference between repayments start ///////////////////////////////////////////
  const mismatchArray = [];
  curMemMettingLoanRepayment.forEach((curMember) => {
    internalRepaymentArray.forEach((batch) => {
      if (curMember.isLoanType === "Internal") {
        batch.internalRepayments.forEach((member) => {
          // Check if the member's paidDate matches the batch's batchMeetingHeldOnDate
          if (
            member.memberId === curMember._id.memberId &&
            batch.batchMeetingHeldOnDate === curMember.meetingHeldOnDate
          ) {
            // Loop through the repayments array in curMember to check for a matching paidDate
            const matchingRepayment = curMember.Repayment.find((repayment) =>
              repayment.some(
                (payment) => payment.paidDate === batch.batchMeetingHeldOnDate
              )
            );

            // Only add to mismatchArray if a matching repayment is found
            // and the internalRepayment length is 0 (indicating a missing repayment)
            if (matchingRepayment != undefined && member.internalRepayment.length === 0) {
              mismatchArray.push({
                meetingDate: batch.batchMeetingHeldOnDate,
                memberName: member.memberName,
                ErrorType: "Internal Loan",
                errorMessage: `Repayment missing, mismatch detected!`,
                solution: `Please contact Pinnacle for assistance.`,
              });
            }
          }
        });
      }
    });
  });

  ///////////////////check the difference between repayments end ///////////////////////////////////////////

  ////////////////////////**********code for income total. start*************////////////////////////////////////////
  let totalIncomeByDate = {};
  const calculate_Income_total = async (test_data) => {
    for (let record of test_data) {
      // Get the meeting date for this record
      let meetingDate = record.batchMeetingHeldOnDate;

      // Check if the date already exists in the totalIncomeByDate object
      if (!totalIncomeByDate[meetingDate]) {
        totalIncomeByDate[meetingDate] = { cash: 0, bank: 0 }; // Initialize the total for that date if not already present
      }

      // Add the income values for the record for the current date (both cash and bankAmt)
      totalIncomeByDate[meetingDate].cash +=
        (record.batchInterestPaid.cash || 0) +
        (record.batchLoanPaid.cash || 0) +
        (record.batchSavingAmt.cash || 0) +
        (record.batchSubAmt.cash || 0) +
        (record.otherLoanPaidToShg || 0) +
        (record.batchSecurityDepositMemToShg.cash || 0) +
        (record.batchOtherContribution.cash || 0) +
        (record.batchOtherIncome.cash || 0) +
        (record.batchMembershipFees.cash || 0) +
        (record.batchOtherLoanInterestShg || 0) +
        (record.deactiveMemberSaving.cash || 0) +
        (record.seedMoney.cash || 0);

      totalIncomeByDate[meetingDate].bank +=
        (record.batchInterestPaid.bankAmt || 0) +
        (record.batchLoanPaid.bankAmt || 0) +
        (record.batchSavingAmt.bankAmt || 0) +
        (record.batchSubAmt.bankAmt || 0) +
        (record.batchSecurityDepositMemToShg.bankAmt || 0) +
        (record.batchOtherContribution.bankAmt || 0) +
        (record.batchOtherIncome.bankAmt || 0) +
        (record.batchMembershipFees.bankAmt || 0) +
        (record.batchLoanAmt.bankAmt || 0) +
        (record.deactiveMemberSaving.bankAmt || 0) +
        (record.seedMoney.bankAmt || 0);
    }
    // console.log("totalIncomeByDate",totalIncomeByDate)
    ////////////////////////calling function to calculate expense total////////////////////////
    await calculate_Expense_total(test_data);
  };
  ////////////////////////************code for income  total. end**************////////////////////////////////////////

  ////////////////////////////////**********code for expense total. start***********////////////////////////////////////////
  let totalExpensesByDate = {};
  const calculate_Expense_total = async (test_data) => {
    for (let record of test_data) {
      // Get the meeting date for this record
      let meetingDate = record.batchMeetingHeldOnDate;

      // Check if the date already exists in the totalExpensesByDate object
      if (!totalExpensesByDate[meetingDate]) {
        totalExpensesByDate[meetingDate] = { cash: 0, bank: 0 }; // Initialize totals for that date
      }

      // Add the expense values for the record (both cash and bankAmt)
      totalExpensesByDate[meetingDate].cash +=
        (record.batchSavingWithdrawals.cash || 0) +
        (record.donationToOutsider.cash || 0) +
        (record.otherExpenses.cash || 0) +
        (record.paidToMahasangha.cash || 0) +
        (record.sdSHGToUnion.cash || 0) +
        (record.serviceCharges.cash || 0) +
        (record.stationaryExpenses.cash || 0) +
        (record.subsidyDistributed || 0) +
        (record.batchLoanAmt.cash || 0) +
        (record.otherLoanPaidToDonor || 0) +
        (record.batchOtherLoanInterestDonor || 0) +
        (record.travellingExpenses.cash || 0);

      totalExpensesByDate[meetingDate].bank +=
        (record.batchSavingWithdrawals.bankAmt || 0) +
        (record.donationToOutsider.bankAmt || 0) +
        (record.otherExpenses.bankAmt || 0) +
        (record.paidToMahasangha.bankAmt || 0) +
        (record.sdSHGToUnion.bankAmt || 0) +
        (record.serviceCharges.bankAmt || 0) +
        (record.stationaryExpenses.bankAmt || 0) +
        (record.subsidyDistributed || 0) +
        (record.batchLoanAmt.bankAmt || 0) +
        (record.travellingExpenses.bankAmt || 0);
    }
    // console.log("totalExpensesByDate",totalExpensesByDate)
    /////////////////////calling function to calculate income - expense difference////////////////////////
    await calculate_Income_Expense_Difference(
      totalIncomeByDate,
      totalExpensesByDate,
      test_data
    );
  };
  ////////////////////////////////**********code for expense total. end*******////////////////////////////////////////

  ////////////////////////////////**********code for getting minus figures.for inocome & expense start*******////////////////////////////////////////
  const negativeDifferences = [];
  const calculate_Income_Expense_Difference = async (
    totalIncomeByDate,
    totalExpensesByDate,
    test_data
  ) => {

    

    ////////////////////////bank transaction amount start///////////////////////
// Using reduce to group by date and calculate the bank and cash
const result = test_data.reduce((acc, item) => {
  const { batchMeetingHeldOnDate, batchBankDeposit, batchBankWithdrawals } = item;

  // console.log("test_data",test_data)
  // Initialize the date entry if it doesn't exist yet
  if (!acc[batchMeetingHeldOnDate]) {
    acc[batchMeetingHeldOnDate] = {
      deposite: 0,
      withdraw: 0,
    };
  }

  // Add deposit and withdrawals to the bank and cash
  acc[batchMeetingHeldOnDate].deposite += batchBankDeposit;
  acc[batchMeetingHeldOnDate].withdraw += batchBankWithdrawals;

  return acc;
}, {});
    ///////////////////////bank trnasation amount end//////////////////////////'
 
    ///////////////////////calculation of income & expnse wth previous cashInahnd start////////////////////////
// Sort test_data by batchMeetingHeldOnDate in ascending order
test_data.sort(function(a, b) {
  // Convert dates to actual Date objects to ensure proper comparison
  const dateA = new Date(a.batchMeetingHeldOnDate);
  const dateB = new Date(b.batchMeetingHeldOnDate);
  return dateA - dateB;  // Compare the two Date objects
});

let previousCashInHand = 0;  // Initialize to 0 for the first record or start with the first record's cashInHand

for (let i = 0; i < test_data.length; i++) {
  const currentRecord = test_data[i];  // Get the current record from test_data
  const date = currentRecord.batchMeetingHeldOnDate;  // Use the date as key for `totalIncomeByDate` and `totalExpensesByDate`

  const income = totalIncomeByDate[date];
  const expense = totalExpensesByDate[date];

  // Get withdrawAmount and depositeAmount from the result object, handle undefined values with 0
  const withdrawAmount = result[date] && result[date].withdraw ? result[date].withdraw : 0;
  const depositeAmount = result[date] && result[date].deposite ? result[date].deposite : 0;

  // Get the current record's cashInHand, default to 0 if cashInHand is not available
  const currentCashInHand = currentRecord.cashInHand || 0;

  // Calculate cashDifference by adding previousCashInHand
  const cashDifference = ((income.cash - expense.cash) + withdrawAmount) - depositeAmount + previousCashInHand;
  const bankDifference = income.bank - expense.bank;


  // Update negativeDifferences if necessary
  if (cashDifference < 0) {
      negativeDifferences.push({ date, cash: cashDifference });
  }
  if (bankDifference < 0) {
      negativeDifferences.push({ date, bank: bankDifference });
  }

  // Set previousCashInHand to the current record's cashInHand for the next iteration
  previousCashInHand = currentCashInHand;  // Update previousCashInHand with the current record's cashInHand
  // console.log(`${date}`, "previousCashInHand", previousCashInHand);
}

    ////////////////////calculation of income and expense with previous cashInhand end////////////////////////

    // console.log("negativeDifferences",negativeDifferences)
    /////////////////////calling function to calculate cash in hand////////////////////////
    
    await calculate_CashInHand(negativeDifferences, test_data);
  };
  ////////////////////////////////**********code for getting minus figures. for inocome & expense end*******/////////////////////////

  ///////////////////////////////************code for getting minus figuer for cashInHand start ******////////////////////////
  const calculate_CashInHand = async (params, test_data) => {
    let matchingTestData = []; // To store all matching records

    // Loop through all the params
    for (let param of params) {
      const paramDate = param.date;

      // Find all matching records from test_data (instead of just one with find, use filter for multiple matches)
      let filteredData = test_data.filter(
        (data) => data.batchMeetingHeldOnDate === paramDate
      );

      // If there are any matching records, push them to matchingTestData
      if (filteredData.length > 0) {
        matchingTestData.push(...filteredData); // Add all matching records to the array

        // Loop over each matching record and calculate the cashInHand for each param
        filteredData.forEach((data) => {
          const cashInHand = data.cashInHand || 0; // Default to 0 if no cashInHand field
          param.cash += cashInHand; // Update the param's cash value
        });
      }
    }
    // After processing all params, pass matchingTestData to the next function



    await CashInHand_BankBalance_Tally(params, matchingTestData);
  };
  ///////////////////////////////************code for getting minus figuer for cashInHand end ******////////////////////////

  ///////////////////////////////************code for cashInHand bankBalance tally start ******////////////////////////////
  let cashInHandBankBalanceTallyResults = [];

  const CashInHand_BankBalance_Tally = async (params, matchingTestData) => {

    if (Array.isArray(matchingTestData)) {
      // Loop through each item in the matchingTestData array
      matchingTestData.forEach((data) => {
        let bankDeposit = data.batchBankDeposit || 0;
        let bankWithdrawal = data.batchBankWithdrawal || 0;
        let bankCommission = data.bankCommission || 0;
        let bankInterest = data.bankInterest || 0;

        // Total bank amount calculation
        let totalBank = Number(bankDeposit) + Number(bankWithdrawal);
        let batchMeetingHeldOnDate = data.batchMeetingHeldOnDate;
         
        // console.log("totalBank",totalBank);
        // Loop through params array
        for (let param of params) {
          const paramDate = param.date;
          // console.log("param.cash",param.cash)
          const paramCashInHand = param.cash - totalBank; // Calculate cash in hand after total bank subtraction

          // Check if the batchMeetingHeldOnDate matches the paramDate
          if (batchMeetingHeldOnDate === paramDate) {

            const totalBankAmt = totalBank;

            // Check if the total bank amount exceeds the cash in hand
            // console.log("totalBankAmt",totalBankAmt);
            // console.log("paramCashInHand",paramCashInHand)
            if (totalBankAmt > paramCashInHand) {
              cashInHandBankBalanceTallyResults.push({
                meetingDate: paramDate,
                batchName: data.batchName, // Add batch name from matchingTestData
                ErrorType: "Bank Details",
                errorMessage: `Total bank amount (${totalBankAmt}) is higher than cash in hand (${paramCashInHand})`,
                solution: `Income and expense details need to be verified.`,
              });
            } else {
             
              // cashInHandBankBalanceTallyResults.push({
              //   meetingDate: paramDate,
              //   batchName: data.batchName, // Add batch name from matchingTestData
              //   errorMessage: `No error: For date ${paramDate}, total bank amount is sufficient.`,
              // });
            }
          }
        }
      });
    } else {
      // If matchingTestData is not an array, handle the case accordingly
      // console.log('Error: matchingTestData is not an array.');
      return;
    }
    // console.log("Bank Balance Tally Results:", cashInHandBankBalanceTallyResults);

    // Return the result array
    calculateNegativeCashInhandValue();
    return cashInHandBankBalanceTallyResults;
  };
  ///////////////////////////////************code for cashInHand bankBalance tally end ******////////////////////////////


const calculateNegativeCashInhandValue = ()=>{

  const result = test_data.reduce((acc, item) => {
    const { batchMeetingHeldOnDate, batchBankDeposit, batchBankWithdrawals,batchName } = item;
  
    // console.log("test_data",test_data)
    // Initialize the date entry if it doesn't exist yet
    if (!acc[batchMeetingHeldOnDate]) {
      acc[batchMeetingHeldOnDate] = {
       
        deposite: 0,
        withdraw: 0,
      };
    }
  
    // Add deposit and withdrawals to the bank and cash
    acc[batchMeetingHeldOnDate].deposite += batchBankDeposit;
    acc[batchMeetingHeldOnDate].withdraw += batchBankWithdrawals;
  
    return acc;
  }, {});

 // Sort test_data by batchMeetingHeldOnDate in ascending order
test_data.sort(function(a, b) {
  // Convert dates to actual Date objects to ensure proper comparison
  const dateA = new Date(a.batchMeetingHeldOnDate);
  const dateB = new Date(b.batchMeetingHeldOnDate);
  return dateA - dateB;  // Compare the two Date objects
});

let previousCashInHand = 0;  // Initialize to 0 for the first record or start with the first record's cashInHand

for (let i = 0; i < test_data.length; i++) {
  const currentRecord = test_data[i];  // Get the current record from test_data
  const date = currentRecord.batchMeetingHeldOnDate;  // Use the date as key for `totalIncomeByDate` and `totalExpensesByDate`

  const income = totalIncomeByDate[date];
  const expense = totalExpensesByDate[date];

  // Get withdrawAmount and depositeAmount from the result object, handle undefined values with 0
  const withdrawAmount = result[date] && result[date].withdraw ? result[date].withdraw : 0;
  const depositeAmount = result[date] && result[date].deposite ? result[date].deposite : 0;
  
  // Get the current record's cashInHand, default to 0 if cashInHand is not available
  const currentCashInHand = currentRecord.cashInHand || 0;
  // Calculate cashDifference by adding previousCashInHand
  const cashDifference = ((income.cash - expense.cash)  + previousCashInHand) - depositeAmount;
  const bankDifference = income.bank - expense.bank;


  // Update negativeDifferences if necessary
  if (cashDifference < 0) {
      negativeDifferences.push({ date, cash: cashDifference });
      cashInHandBankBalanceTallyResults.push({
        meetingDate: date,
        // batchName: batchName, // Add batch name from matchingTestData
        ErrorType: "Cash In Hand",
        errorMessage: `The Cash-In-Hand for this meeting ${cashDifference}`,
        solution: `Rectify your Cash-In-Hand for ${date}`,
      });

  }
  if (bankDifference < 0) {
      negativeDifferences.push({ date, bank: bankDifference });
  }
  // console.log(`${date}`,"cashDifference",cashDifference)

  // Set previousCashInHand to the current record's cashInHand for the next iteration
  previousCashInHand = currentCashInHand;  // Update previousCashInHand with the current record's cashInHand
}


}



  calculateOtherLoanSanctioned(test_data);
  calculateOtherLoanDifference();
  calculateInternalDifference();

  setTimeout(() => {
    setLoading(false);
  }, 5000);

  useEffect(() => {
    const combinedArray = [
      ...mismatchArray,
      ...cashInHandBankBalanceTallyResults,
      ...internalLoanDifferenceArray,
    ];
    const sortedArray = combinedArray.sort((a, b) => {
      return new Date(a.meetingDate) - new Date(b.meetingDate);
    });

    setError(sortedArray);
  }, [loading]);

  return loading ? (
    <div>
      <div className="col-lg-12 mt-5"></div>
      <div className="col-lg-12 mt-5"></div>
      <div className="col-lg-12 mt-5"></div>
      <div className="row">
        <div className="col-lg-11 col-md-11 col-sm-11 text-center mt-5 ">
          <h5 className="" style={{ fontWeight: "bolder", color: "#144c5a" }}>
            Scanning Errors of {scanData}
          </h5>
        </div>
      </div>
      <Spinner />
    </div>
  ) : (
    <div className="col-lg-11 col-md-11 col-sm-11 col-11 ">
      <h2 className="heading_color">{scanData}</h2>
      {/* <div className="col-lg-12 mt-5"></div> */}
      <div className="col-lg-12">
        <Link to="/batchwise-report">
          <img
            className="img_icon_size log float-right ml-3"
            src={require("../../static/images/back.png")}
            alt="Back"
            title="Back"
          />
        </Link>
      </div>
      <div className="col-lg-12 mt-5"></div>
      <table
        className="table table-bordered table-striped table-hover"
        id="datatable2"
      >
        <thead>
          <tr>
            <th>MeetingDate</th>
            <th>Member Name</th>
            <th>Error Type </th>
            <th>Error Message</th>
            <th>Error Rectifications</th>
          </tr>
        </thead>
        {error && error.length > 0 ? (
          <tbody>
            {error &&
              error.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {item.meetingDate
                        .split("-")
                        .reverse()
                        .join("-")}
                    </td>
                    <td>{item.memberName}</td>
                    <td>{item.ErrorType}</td>
                    <td>{item.errorMessage}</td>
                    <td>{item.solution}</td>
                  </tr>
                );
              })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={5} className="text-center">
                {" "}
                <h5
                  className=""
                  style={{ fontWeight: "bolder", color: "#144c5a" }}
                >
                  No Errors Found
                </h5>
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

TestReport.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  area: state.area,
});

export default connect(mapStateToProps, {
  getTestData,
  getLoanRepaymentOfMeber,
})(TestReport);
