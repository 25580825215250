import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";

const AllReports = ({ auth: { allUser, isAuthenticated, user, users } }) => {
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );
  const allAccess = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.access &&
    loggedUserInstitutionData.access.map((access) =>
      allAccess.push(access.accessName)
    );

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">All Reports </h2>
          <hr />
        </div>

        <section className="all_report">
          {/* {allPermission && allPermission.includes("SingleSkill") && (
            <div className="card reportCard card-content ">
              <cetner>
              
                <Link to="/skill-training-report">
                  <img
                    className="ReportImg log"
                    src={require("../../static/images/skill.png")}
                    alt="Skill Report"
                    title="Skill Report"
                  />
                  <h4>Skill Report </h4>
                </Link>
              </cetner>
            </div>
          )} */}

          <div className="card reportCard card-content ">
            <cetner>
              {/* Batchwise Report */}
              <Link
                to="/batchwise-report"
                onClick={localStorage.removeItem("selBatchwiseReport")}
              >
                <img
                  className="ReportImg log"
                  src={require("../../static/images/batches.png")}
                  alt="Batchwise Report"
                  title="Batchwise Report"
                />
                <h5>Batchwise Report</h5>
              </Link>
            </cetner>
          </div>

          <div className="card reportCard card-content ">
            <center>
              {/* Income and Expenditure Report */}
              <Link to="/income-expense-report">
                <img
                  className="ReportImg log"
                  src={require("../../static/images/incomeexpense1.png")}
                  alt="incomeexpense Report"
                  title="incomeexpense Report"
                />
                <h5>Income and Expenditure Report</h5>
              </Link>
            </center>
          </div>

          <div className="card reportCard card-content ">
            <center>
              {/* Monthwise I & E Report */}
              <Link to="/monthly-income-expense-report">
                <img
                  className="ReportImg log"
                  src={require("../../static/images/incomeexpense.png")}
                  alt="Monthwise Income&Expense Report"
                  title="Monthwise Income&Expense Report"
                />
                <h5>Monthwise I & E Report</h5>
              </Link>
            </center>
          </div>

          <div className="card reportCard card-content ">
            <center>
              {/* Profit and Loss Report */}
              <Link to="/profit-loss-report">
                <img
                  className="ReportImg log"
                  src={require("../../static/images/profitloss.png")}
                  alt="Profit and Loss Report"
                  title="Profit and Loss Report"
                />
                <h4>Profit and Loss Report </h4>
              </Link>
            </center>
          </div>

        {allPermission && allPermission.includes("Incharge_Module") && (
           <div className="card reportCard card-content ">
           <center>
             {/* Incharge wise report */}
             <Link to="/inchargewise-report">
               <img
                 className="ReportImg log"
                 src={require("../../static/images/incharge.png")}
                 alt="Inchargewise Report"
                 title="Inchargewise Report"
               />
               <h4>Inchargewise Report</h4>
             </Link>
           </center>
         </div>
        )}
        
          {allPermission && allPermission.includes("Occupation") && (
            <div className="card reportCard card-content ">
              <center>
                <Link to="/occupation-reports">
                  <img
                    className="ReportImg log"
                    src={require("../../static/images/occupation.png")}
                    alt="Occupation Report"
                    title="Occupation Report"
                  />
                  <h4>Occupation Report</h4>
                </Link>
              </center>
            </div>
          )}

          {user && user.institutionId != "63009e55d95ce47f30877a6a" &&
            (allPermission && allPermission.includes("Category")) &&  (
            // Categorywise Report
            <div className="card reportCard card-content ">
              <center>
                <Link to="/Shg-Categorywise-Report">
                  <img
                    className="ReportImg log"
                    src={require("../../static/images/ShgCategories.png")}
                    alt="Categorywise Report"
                    title="Categorywise Report"
                  />
                  <h4>Categorywise Report</h4>
                </Link>
              </center>
            </div>
          )}

          {allPermission && allPermission.includes("Donor_Report") && (
            // Donor Report
              <div className="card reportCard card-content ">
                <center>
                  <Link to="/Shg-Donor-Report">
                    <img
                      className="ReportImg log"
                      src={require("../../static/images/donor_report.png")}
                      alt="Donor Report"
                      title="Donor Report"
                    />
                    <h4>Donor Report</h4>
                  </Link>
                </center>
              </div>
          )}

          {allPermission && allPermission.includes("skill") && (
            // Skill Report 
            <div className="card reportCard card-content ">
              <center>
                <Link to="/skill-training-report">
                  <img
                    className="ReportImg log"
                    src={require("../../static/images/skill.png")}
                    alt="Skill Report"
                    title="Skill Report"
                  />
                  <h4>Skill Report </h4>
                </Link>
              </center>
            </div>
          )}

          {allPermission && allPermission.includes("Memberwise-Loan-Report") && (
            // Memberwise Loan Report
            <div className="card reportCard card-content">
              <center>
                <Link to="/Memberwise-loan-report">
                  <img
                    className="ReportImg log"
                    src={require("../../static/images/memrep.png")}
                    alt="Memberwise loan report"
                    title="Memberwise loan report"
                  />
                  <h4 className="pt-4">Memberwise Loan Report</h4>
                </Link>
              </center>
            </div>
          )}

          {allPermission && allPermission.includes("AssaReports") && (
            <>
              {/* Income & Expenses Report (Customized)  */}
              <div className="card reportCard card-content ">
                <Link to="/AssaIncomeExpenseReport">
                  <center>
                    <img
                      className="ReportImg log"
                      src={require("../../static/images/ie.png")}
                      alt="Income & Expenses Report "
                      title="Income & Expenses Report "
                    />
                    <h4>Income & Expenses Report (Customized) </h4>
                  </center>
                </Link>
              </div>

              {/* Loan Status Report (Customized) */}
              <div className="card reportCard card-content ">
                <center>
                  <Link to="/SeeAllMemLoan">
                    <img
                      className="ReportImg log mt-2"
                      src={require("../../static/images/wayoff.png")}
                      alt="Income & Expenses Report "
                      title="Income & Expenses Report "
                      style={{ height: "160px", width: "160px" }}
                    />
                    <h4>Loan Status Report (Customized)</h4>
                  </Link>
                </center>
              </div>

              {/* Assets & Liabilities Report (Customized)  */}
              <div className="card reportCard card-content ">
                <center>
                  <Link to="/AssetsandLiabilities">
                    <img
                      className="ReportImg log mt-1 "
                      src={require("../../static/images/al.jpg")}
                      alt="Assets and Liability Report"
                      title="Assets and Liability Report"
                    />
                    <h4>Assets & Liabilities Report (Customized) </h4>
                  </Link>
                </center>
              </div>
            </>
          )}

          {allPermission && allPermission.includes("Attendance") && (

            // Attendence Report
            <div className="card reportCard card-content ">
              <center>
                <Link to="/attendence-report">
                  <img
                    className="ReportImg log"
                    src={require("../../static/images/attendence.png")}
                    alt="Attendence Report"
                    title="Attendence Report"
                    style={{ height: "190px", width: "190px" }}
                  />
                  <h3 style={{ marginTop: "-10px" }}>Attendence Report</h3>
                </Link>
              </center>
            </div>
          )}
        </section>
      </div>
    </Fragment>
  );
};

AllReports.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(AllReports);
{
  /* <div className="row col-lg-11 col-md-11 col-sm-12 col-12 py-5  ">
            {allPermission && allPermission.includes("Memberwise-Loan-Report") && (
              <div
                className={
                  allAccess && allAccess.includes("skill")
                    ? "col-lg-3 col-md-6 col-sm-12 col-12 mt-5"
                    : "col-lg-3 col-md-6 col-sm-12 col-12"
                }
              > */
}
{
  /* <div className="card card-content ">
                  <center>
                    <Link to="/edit-meeting">
                      <img
                        className="ReportImg log"
                        src={require("../../static/images/edit_meeting1.jpg")}
                        alt="Edit Meeting"
                        title="Edit Meeting"
                      />
                      <h4 className="pt-4">Edit Meeting</h4>
                    </Link>
                  </center>
                </div> */
}
{
  /* ):(<></>) */
}
{
  /* </div>
            )}
          </div> */
}
{
  /* <div className="row col-lg-11 col-md-11 col-sm-12 col-12 py-5"> */
}

{
  /* </div> */
}

{
  /* <div className="col-lg-2 col-md-6 col-sm-12 col-12 ">
                 <div className="card card-content ">
                   <center>
                     <Link to="/skill-training-report">
                     <img
                       className="ReportImg log"
                        src={require("../../static/images/report.png")}
                       alt="Skill Report"
                       title="Skill Report"
                     />
                     <h4>Member Wise Loan Report</h4>
                     </Link>
                   </center>
                 </div>
               </div> */
}
