import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {
  addBatchMeetingData,
  editBatchMeetingData,
  addOnlyBatchWiseMeetingData,
  editOnlyBatchWiseMeetingData,
  deleteCashTransaction,
} from "../../../actions/shg";
import { useHistory } from "react-router-dom";

export const BatchBankDetTab = ({
  activeBank,
  batchData,
  NextBackBtn,
  formData,
  setFormData,
  setShowNewBankBalnce,
  PrevCashInHand,
  addOnlyBatchWiseMeetingData,
  editOnlyBatchWiseMeetingData,
  selectedBatchData,
  fromPage,
  selectedDate,
  batchTransactionData,
  allRights,
  allPermission,
  deleteCashTransaction,
  newBankBalanceTotal,
  setNewTrailBankBalances,
  prevBankBalance,
  formattedDate,
  selectedDates,
  auth: { isAuthenticated, user, users, loading },
}) => {
  const history = useHistory();
  let {
    cashInHand,
    //Expense
    internalBankExpense,
    travellingExpenses, //bc
    paidToMahasangha, //bc
    stationaryExpenses, //bc
    serviceCharges, //bc
    batchDividendDistributed, //bc
    otherExpenses, //bc
    loanGivenToOtherSHG, //bc
    batchSavingWithdrawals, //bc
    batchSecurityDeposit, //bc
    donationToOutsider, //bc
    otherLoanBatchTransaction, //Array //Exp batchOtherLoanInterestDonor subsidyDistributed otherLoanPaidToDonor   //Income otherLoanSanctionedToShg
    sdSHGToUnion,

    //Income
    batchSavingAmt, //bc
    batchSubAmt, //bc
    batchOtherContribution, //bc
    batchMembershipFees, //bc
    batchLoanPaid, //bc
    batchInterestPaid, //bc

    otherLoanPaidToShg, //only
    batchOtherLoanInterestShg, //only

    deactiveMemberSaving, //bc
    subsidyReceived, //bc
    batchOtherIncome, //bc
    seedMoney, //bc
    loanRepaidByOtherSHG, //bc
    batchSecurityDepositMemToShg, //bc
    sdUnionToSHG, //bc
  } = formData;
  const [DBBankArray, setDBBankArray] = useState(
    formData ? formData.bankTransactions : []
  );
  ///////////////////////////////////////tooltip for income & expense start////////////////////////////////////////////////
  // Creating arrays to hold income and expense details
  const incomeArray = [
    {
      fieldName: "Interest Paid",
      bankAmt: formData.batchInterestPaid.bankAmt,
      cash: formData.batchInterestPaid.cash,
      bankName: formData.batchInterestPaid.bankName || "",
    },
    {
      fieldName: "Loan Paid",
      bankAmt: formData.batchLoanPaid.bankAmt,
      cash: formData.batchLoanPaid.cash,
      bankName: formData.batchLoanPaid.bankName || "",
    },
    {
      fieldName: "Membership Fees",
      bankAmt: formData.batchMembershipFees.bankAmt,
      cash: formData.batchMembershipFees.cash,
      bankName: formData.batchMembershipFees.bankName || "",
    },
    {
      fieldName: "Other Contribution",
      bankAmt: formData.batchOtherContribution.bankAmt,
      cash: formData.batchOtherContribution.cash,
      bankName: formData.batchOtherContribution.bankName || "",
    },
    {
      fieldName: "Other Income",
      bankAmt: formData.batchOtherIncome.bankAmt,
      cash: formData.batchOtherIncome.cash,
      bankName: formData.batchOtherIncome.bankName || "",
    },
    {
      fieldName: "Saving Amount",
      bankAmt: formData.batchSavingAmt.bankAmt,
      cash: formData.batchSavingAmt.cash,
      bankName: formData.batchSavingAmt.bankName || "",
    },
    {
      fieldName: "SD Member-SHG",
      bankAmt: formData.batchSecurityDepositMemToShg.bankAmt,
      cash: formData.batchSecurityDepositMemToShg.cash,
      bankName: formData.batchSecurityDepositMemToShg.bankName || "",
    },
    {
      fieldName: "Subscription Amount",
      bankAmt: formData.batchSubAmt.bankAmt,
      cash: formData.batchSubAmt.cash,
      bankName: formData.batchSubAmt.bankName || "",
    },
    {
      fieldName: "Deactive Member Saving",
      bankAmt: formData.deactiveMemberSaving.bankAmt,
      cash: formData.deactiveMemberSaving.cash,
      bankName: formData.deactiveMemberSaving.bankName || "",
    },
    {
      fieldName: "Loan Repaid by Other SHG",
      bankAmt: formData.loanRepaidByOtherSHG.bankAmt,
      cash: formData.loanRepaidByOtherSHG.cash,
      bankName: formData.loanRepaidByOtherSHG.bankName || "",
    },
    {
      fieldName: "SD Union-SHG",
      bankAmt: formData.sdUnionToSHG.bankAmt,
      cash: formData.sdUnionToSHG.cash,
      bankName: formData.sdUnionToSHG.bankName || "",
    },
    {
      fieldName: "Seed Money",
      bankAmt: formData.seedMoney.bankAmt,
      cash: formData.seedMoney.cash,
      bankName: formData.seedMoney.bankName || "",
    },
    {
      fieldName: "Subsidy Received",
      bankAmt: formData.subsidyReceived.bankAmt,
      cash: formData.subsidyReceived,
      bankName: formData.subsidyReceived.bankName || "",
    }, // Assuming subsidyReceived has no bankAmt
    {
      fieldName: "OL Paid To SHG",
      bankAmt: formData.otherLoanPaidToShg ,
      cash: 0,
      bankName:  "",
    }, // Assuming subsidyReceived has no bankAmt
    {
      fieldName: "OL Interest Paid To SHG",
      bankAmt: formData.batchOtherLoanInterestShg,
      cash: 0,
      bankName:  "",
    }, // Assuming subsidyReceived has no bankAmt


  ];

  const expenseArray = [
    {
      fieldName: "Dividend Distributed",
      bankAmt: formData.batchDividendDistributed.bankAmt,
      cash: formData.batchDividendDistributed.cash,
      bankName: formData.batchDividendDistributed.bankName || "",
    },
    {
      fieldName: "Sugamya Bank savings ",
      bankAmt: Number(formData.internalBankExpense || 0),
      cash: 0,
      bankName: "",
    },
    {
      fieldName: "Saving Withdrawals",
      bankAmt: formData.batchSavingWithdrawals.bankAmt,
      cash: formData.batchSavingWithdrawals.cash,
      bankName: formData.batchSavingWithdrawals.bankName || "",
    },
    {
      fieldName: "Security Deposite",
      bankAmt: formData.batchSecurityDeposit.bankAmt,
      cash: formData.batchSecurityDeposit.cash,
      bankName: formData.batchSecurityDeposit.bankName || "",
    },
    {
      fieldName: "Donation To Outsider",
      bankAmt: formData.donationToOutsider.bankAmt,
      cash: formData.donationToOutsider.cash,
      bankName: formData.donationToOutsider.bankName || "",
    },
    {
      fieldName: "Other Expense",
      bankAmt: formData.otherExpenses.bankAmt,
      cash: formData.otherExpenses.cash,
      bankName: formData.otherExpenses.bankName || "",
    },
    {
      fieldName: "Paid To Mahasanga",
      bankAmt: formData.paidToMahasangha.bankAmt,
      cash: formData.paidToMahasangha.cash,
      bankName: formData.paidToMahasangha.bankName || "",
    },
    {
      fieldName: "SD SHG-Union",
      bankAmt: formData.sdSHGToUnion.bankAmt,
      cash: formData.sdSHGToUnion.cash,
      bankName: formData.sdSHGToUnion.bankName || "",
    },
    {
      fieldName: "Service Charges",
      bankAmt: formData.serviceCharges.bankAmt,
      cash: formData.serviceCharges.cash,
      bankName: formData.serviceCharges.bankName || "",
    },
    {
      fieldName: "stationary Expense",
      bankAmt: formData.stationaryExpenses.bankAmt,
      cash: formData.stationaryExpenses.cash,
      bankName: formData.stationaryExpenses.bankName || "",
    },
    {
      fieldName: "Subsidy Distributed",
      bankAmt: formData.subsidyDistributed.bankAmt,
      cash: formData.subsidyDistributed,
      bankName: formData.subsidyDistributed.bankName || "",
    }, // Assuming subsidyDistributed has no bankAmt
    {
      fieldName: "Travelling Expense",
      bankAmt: formData.travellingExpenses.bankAmt,
      cash: formData.travellingExpenses.cash,
      bankName: formData.travellingExpenses.bankName || "",
    },
    {
      fieldName: "Loan Given To Other SHG",
      bankAmt: formData.loanGivenToOtherSHG.bankAmt,
      cash: formData.loanGivenToOtherSHG.cash,
      bankName: formData.loanGivenToOtherSHG.bankName || "",
    },

    {
      fieldName: "OL Paid To Donor",
      bankAmt: formData.otherLoanPaidToDonor,
      cash: 0,
      bankName:"",
      
    },
    {
      fieldName: "OL Interest Paid To Donor",
      bankAmt: formData.batchOtherLoanInterestDonor,
      cash: 0,
      bankName:"",
      
    },
  ];

  // console.log("formData",formData)
  const [isVisible, setIsVisible] = useState(false);
  const [isExpenseVisible, setIsExpenseVisible] = useState(false);
  const tooltipRef = useRef(null);

  // Close tooltip if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current) {
        setIsVisible(false);
        setIsExpenseVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const showDetails = () => {
    setIsVisible(!isVisible);
  };
  const showExpenseDetails = () => {
    setIsExpenseVisible(!isExpenseVisible);
  };

  const Incomearray = (data) => {
    if (data.data === "Income") {
      return (
        <div className="tooltipFroIncome_Expense-Income_Expensecontainer">
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            onClick={showDetails}
            title="Income Transaction Breakdown"
          />
          {isVisible && (
            <div className="tooltipFroIncome_Expense">
              <table
                className="tabllll table table-bordered table-striped table-hover"
                id="datatable2"
              >
                <thead>
                  <th colSpan={4}>Income</th>
                  <tr>
                    <th>Fields</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {incomeArray.map((item, index) => {
                    if (item.bankAmt != 0 || item.cash != 0) {
                      return (
                        <tr key={index}>
                          <td>{item.fieldName}</td>
                          <td>{(item.bankAmt || 0) + (item.cash || 0)}</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="tooltipFroIncome_Expense-Income_Expensecontainer">
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            onClick={showExpenseDetails}
            title="Expense Transaction Breakdown"
          />
          {isExpenseVisible && (
            <div className="tooltipFroIncome_Expense">
              <table
                className="tabllll table table-bordered table-striped table-hover"
                id="datatable2"
              >
                <thead>
                  <th colSpan={4}>Expense</th>
                  <tr>
                    <th>Fields</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {expenseArray.map((item, index) => {
                    if (item.bankAmt != 0 || item.cash != 0) {
                      return (
                        <tr key={index}>
                          <td>{item.fieldName}</td>
                          <td>{(item.bankAmt || 0) + (item.cash || 0)}</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      );
    }
  };

  ////////////////////////////////////////income & expense tooltip end///////////////////////////////////////////////
  const totExpenseCash =
    Number(internalBankExpense || 0) +
    Number(travellingExpenses ? travellingExpenses.cash : 0) +
    Number(paidToMahasangha ? paidToMahasangha.cash : 0) +
    Number(stationaryExpenses ? stationaryExpenses.cash : 0) +
    Number(serviceCharges ? serviceCharges.cash : 0) +
    Number(batchDividendDistributed ? batchDividendDistributed.cash : 0) +
    Number(otherExpenses ? otherExpenses.cash : 0) +
    Number(loanGivenToOtherSHG ? loanGivenToOtherSHG.cash : 0) +
    Number(batchSavingWithdrawals ? batchSavingWithdrawals.cash : 0) +
    Number(batchSecurityDeposit ? batchSecurityDeposit.cash : 0) +
    Number(donationToOutsider ? donationToOutsider.cash : 0) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanInterestDonor.cash)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.subsidyDistributed.cash)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanPaidToDonor.cash)),
      0
    ) +
    Number(sdSHGToUnion ? sdSHGToUnion.cash : 0);

  const totExpenseBank =
    Number(travellingExpenses ? travellingExpenses.bankAmt : 0) +
    Number(paidToMahasangha ? paidToMahasangha.bankAmt : 0) +
    Number(stationaryExpenses ? stationaryExpenses.bankAmt : 0) +
    Number(serviceCharges ? serviceCharges.bankAmt : 0) +
    Number(batchDividendDistributed ? batchDividendDistributed.bankAmt : 0) +
    Number(otherExpenses ? otherExpenses.bankAmt : 0) +
    Number(loanGivenToOtherSHG ? loanGivenToOtherSHG.bankAmt : 0) +
    Number(batchSavingWithdrawals ? batchSavingWithdrawals.bankAmt : 0) +
    Number(batchSecurityDeposit ? batchSecurityDeposit.bankAmt : 0) +
    Number(donationToOutsider ? donationToOutsider.bankAmt : 0) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanInterestDonor.bankAmt || 0)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.subsidyDistributed.bankAmt || 0)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanPaidToDonor.bankAmt || 0)),
      0
    ) +
    Number(sdSHGToUnion ? sdSHGToUnion.bankAmt : 0);

  const totIncomeCash =
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanPaidToShg.cash || 0)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanInterestShg.cash || 0)),
      0
    ) +
    Number(batchSavingAmt ? batchSavingAmt.cash : 0) +
    Number(batchSubAmt ? batchSubAmt.cash : 0) +
    Number(batchOtherContribution ? batchOtherContribution.cash : 0) +
    Number(batchMembershipFees ? batchMembershipFees.cash : 0) +
    Number(batchLoanPaid ? batchLoanPaid.cash : 0) +
    Number(batchInterestPaid ? batchInterestPaid.cash : 0) +
    Number(deactiveMemberSaving ? deactiveMemberSaving.cash : 0) +
    Number(
      otherLoanBatchTransaction.reduce(
        (acu, cur) => (acu += Number(cur.subsidyReceived.cash || 0)),
        0
      )
    ) +
    Number(batchOtherIncome ? batchOtherIncome.cash : 0) +
    Number(seedMoney ? seedMoney.cash : 0) +
    Number(loanRepaidByOtherSHG ? loanRepaidByOtherSHG.cash : 0) +
    Number(
      batchSecurityDepositMemToShg ? batchSecurityDepositMemToShg.cash : 0
    ) +
    Number(sdUnionToSHG ? sdUnionToSHG.cash : 0);

  const totIncomeBank =
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanPaidToShg.bankAmt || 0)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanInterestShg.bankAmt || 0)),
      0
    ) +
    Number(batchSavingAmt ? batchSavingAmt.bankAmt : 0) +
    Number(batchSubAmt ? batchSubAmt.bankAmt : 0) +
    Number(batchOtherContribution ? batchOtherContribution.bankAmt : 0) +
    Number(batchMembershipFees ? batchMembershipFees.bankAmt : 0) +
    Number(batchLoanPaid ? batchLoanPaid.bankAmt : 0) +
    Number(batchInterestPaid ? batchInterestPaid.bankAmt : 0) +
    Number(deactiveMemberSaving ? deactiveMemberSaving.bankAmt : 0) +
    Number(
      otherLoanBatchTransaction.reduce(
        (acu, cur) => (acu += Number(cur.subsidyReceived.bankAmt || 0)),
        0
      )
    ) +
    Number(batchOtherIncome ? batchOtherIncome.bankAmt : 0) +
    Number(seedMoney ? seedMoney.bankAmt : 0) +
    Number(loanRepaidByOtherSHG ? loanRepaidByOtherSHG.bankAmt : 0) +
    Number(
      batchSecurityDepositMemToShg ? batchSecurityDepositMemToShg.bankAmt : 0
    ) +
    Number(sdUnionToSHG ? sdUnionToSHG.bankAmt : 0);

  const [CashInHand, setCashInhand] = useState(
    formData.cashInHand != 0
      ? formData.cashInHand
      : DBBankArray &&
        DBBankArray.find(
          (ele) =>
            (ele.transactionType === "C" || ele.transactionType === "" || ele.transactionType === undefined) &&
            ele.indBatchBankDeposit ==
              (PrevCashInHand || 0) +
                (Number(totIncomeCash) - Number(totExpenseCash))
        )
      ? 0
      : (PrevCashInHand || 0) + (Number(totIncomeCash) - Number(totExpenseCash))
  );

  const [localBankDet, setlocalBankDet] = useState({
    batchBankNameObj: "",
    indBankInterest: 0,
    indBankCommission: 0,
    indBatchBankDeposit: 0,
    indBatchBankWithdrawals: 0,
    transactionType: "C",
  });

  let {
    batchBankNameObj,
    indBankInterest,
    indBankCommission,
    indBatchBankDeposit,
    indBatchBankWithdrawals,
  } = localBankDet;
  const [withdrawDisable, setWithDrawDisable] = useState(false);
  const[lowbalanceError,setLowBalanceError] = useState("")

  
  let  bankDeposits =
  DBBankArray &&
  DBBankArray.filter((ele) => ele.transactionType === "C" || ele.transactionType === "" || ele.transactionType === undefined )
    .map((ele) => ele.indBatchBankDeposit)
    .reduce((acc, curr) => Number(acc) + Number(curr), 0);

  let bankWith = DBBankArray && DBBankArray.map((ele) => ele.indBatchBankWithdrawals)
    .reduce((acc, curr) => Number(acc) + Number(curr), 0); //this is needed bcz to update the withdrawable amount as per new bankBanalance
    const [finalBankBalance,setFinalBankBalance] = useState((Number(prevBankBalance || 0) + Number(bankDeposits || 0)) - Number(bankWith) )



  const inputBankDetChange = (e, selectedName = null) => {
    setFinalBankBalance((Number(prevBankBalance || 0) + Number(bankDeposits || 0)) - Number(bankWith) )
    let netTotal =
      Number(totIncomeCash) -
      Number(totExpenseCash) +
      Number(PrevCashInHand || 0);
      let withdrawTotal = Number(finalBankBalance || 0);

    let depositeTotal = 0;

    DBBankArray &&
      DBBankArray.forEach((ele) => {
        if(ele.transactionType === "C"){

          depositeTotal += Number(ele.indBatchBankDeposit);
        }
      });
    if (selectedName) {
      setlocalBankDet({
        ...localBankDet,
        batchBankNameObj: e,
      });
    } else {
      setlocalBankDet({
        ...localBankDet,
        [e.target.name]: e.target.value,
      });
      if (e.target.name === "indBatchBankDeposit") {
        let bankDeposits =DBBankArray &&  DBBankArray.filter((ele) => ele.transactionType === "C" || ele.transactionType === "" || ele.transactionType === undefined)
        .map((ele) => ele.indBatchBankDeposit)
        .reduce((acc, curr) => Number(acc) + Number(curr), 0);
        // setCashInhand(
        //   (Number(netTotal) - Number(depositeTotal)) - Number(e.target.value)
        // );
        setCashInhand((Number(netTotal) - Number(bankDeposits || 0)) - Number(e.target.value));

      }
      if (e.target.name === "indBatchBankWithdrawals") {
        if (e.target.value > withdrawTotal) {
          setWithDrawDisable(true);
          setLowBalanceError("Bank Balance is Less")
        } else {
          setWithDrawDisable(false);
          setLowBalanceError("")
        }
      }
    }
  };

  useEffect(() => {
    if (!formData) return;

    const existingTransactionsMap = new Map(
      DBBankArray &&
        DBBankArray.map((transaction) => [
          transaction.batchBankNameId,
          transaction,
        ])
    );
    let updatedRecords = [];
    // Prepare an array to hold the updated transactions
    if (Array.isArray(DBBankArray)) {
      updatedRecords = [...DBBankArray];
    } else {
     
    }
    // updatedRecords = [...DBBankArray];

    const updateOrAddRecords = (newRecords) => {
      newRecords.forEach((newRecord) => {
        const existingRecord = existingTransactionsMap.get(
          newRecord.batchBankNameId
        );

        if (existingRecord) {
          // Check transactionType and amount before updating
          if (
            existingRecord.transactionType === newRecord.transactionType &&
            existingRecord.indBatchBankDeposit !==
              newRecord.indBatchBankDeposit &&
            existingRecord.indBatchBankWithdrawals !==
              newRecord.indBatchBankWithdrawals
          ) {
            // Update existing record
            const updatedRecord = {
              ...existingRecord,
              indBatchBankDeposit: newRecord.indBatchBankDeposit,
              indBatchBankWithdrawals: newRecord.indBatchBankWithdrawals,
            };

            const index = updatedRecords.findIndex(
              (record) => record.batchBankNameId === newRecord.batchBankNameId
            );
            if (index !== -1) {
              updatedRecords[index] = updatedRecord;
            }
          }
        } else {
          // Add new record if it doesn't exist
          updatedRecords.push(newRecord);
        }
      });
    };

    updateOrAddRecords(formData.batchIncomeAndExpenseArray);
    updateOrAddRecords(formData.olRepaymentBankArray);
    setDBBankArray(updatedRecords);

    let totalDeposit = 0;
    let totalWithdrawals = 0;
    let totalInterest = 0; 
    let totalCommission = 0;
    updatedRecords.forEach((entry) => {

      if (entry.indBatchBankDeposit) {
        totalDeposit += Number(entry.indBatchBankDeposit || 0);
      }
      if (entry.indBatchBankWithdrawals) {
        totalWithdrawals += Number(entry.indBatchBankWithdrawals || 0);
      }
      if (entry.indBankInterest) {
        totalInterest += Number(entry.indBankInterest || 0); // Assuming Interest is part of the entry
      }
      if (entry.indBankCommission) {
        totalCommission += Number(entry.indBankCommission || 0); // Assuming Commission is part of the entry
      }
    });

    const calculatedNewBankBalanceTotal = (
      (Number(totalDeposit || 0) + Number(totalInterest || 0)) + (Number(prevBankBalance || 0) - (totalCommission + totalWithdrawals))
    );
  
      setNewTrailBankBalances(calculatedNewBankBalanceTotal || 0);   
      setCurNewBankBalanceTotal(calculatedNewBankBalanceTotal || 0);
    //////////////////////////////////////new bankbalanace calculation end/////////////////////////

   
    setCashInhand(
      formData.cashInHand != 0
        ? formData.cashInHand
        : DBBankArray &&
          DBBankArray.find(
            (ele) =>
              (ele.transactionType === "C" || ele.transactionType === "")&&
              ele.indBatchBankDeposit ==
                (PrevCashInHand || 0) +
                  (Number(totIncomeCash) - Number(totExpenseCash))
          )
        ? 0
        : (Number(totIncomeCash) - Number(totExpenseCash)) - (Number(bankDeposits || 0))
   
    );

    let netTotal =
    Number(totIncomeCash) -
    Number(totExpenseCash) +
    Number(PrevCashInHand || 0);
    setCashInhand((Number(netTotal) - Number(bankDeposits || 0)));

    // console.log("formData",CashInHand)
  }, [formData]);

  setShowNewBankBalnce(formData ? formData.bankTransactions : []);
  const [curNewBankBalanceTotal, setCurNewBankBalanceTotal] = useState(0);

  const onInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onAddBankDet = () => {
    setShowNewBankBalnce([
      ...DBBankArray,
      {
        batchBankNameId: localBankDet.batchBankNameObj.bankId,
        batchBankName: localBankDet.batchBankNameObj.label,
        transactionType: "C",
        ...localBankDet,
      },
    ]);
    setFormData({
      ...formData,
      bankTransactions: [
        ...DBBankArray,
        {
          batchBankNameId: localBankDet.batchBankNameObj.bankId,
          batchBankName: localBankDet.batchBankNameObj.label,
          ...localBankDet,
        },
      ],
    });
    setDBBankArray([
      ...DBBankArray,
      {
        batchBankNameId: localBankDet.batchBankNameObj.bankId,
        batchBankName: localBankDet.batchBankNameObj.label,
        transactionType: "C",
        ...localBankDet,
      },
    ]);

    let Interest = Number(localBankDet.indBankInterest || 0);
    let Commission = Number(localBankDet.indBankCommission || 0);
    let BankDeposit = Number(localBankDet.indBatchBankDeposit || 0);
    let BankWithdrawals = Number(localBankDet.indBatchBankWithdrawals || 0);


    setNewTrailBankBalances((Number(prevBankBalance) + Number(BankDeposit) + Number(Interest)) - ((Number(Commission)) + Number(BankWithdrawals)) || 0
    );
    setCurNewBankBalanceTotal(
      Number(prevBankBalance) +
        Number(BankDeposit) +
        Number(Interest) +
        Number(Commission) -
        Number(BankWithdrawals)
    );

    setlocalBankDet({
      transactionType: "C",
      batchBankNameObj: "",
      indBankInterest: 0,
      indBankCommission: 0,
      indBatchBankDeposit: 0,
      indBatchBankWithdrawals: 0,
    });
  };

  const [deletedData, setDeletedData] = useState("");

  const onDeleteBankDet = (data) => {
    let Interest = Number(data.indBankInterest);
    let Commission = Number(data.indBankCommission);
    let BankDeposit = Number(data.indBatchBankDeposit);
    let BankWithdrawals = Number(data.indBatchBankWithdrawals);
    let transactionType = data.transactionType;
    setCashInhand((PrevCashInHand || 0)  + (Number(totIncomeCash) - Number(totExpenseCash)) +Number(BankWithdrawals || 0) )

    setNewTrailBankBalances(
      Number(curNewBankBalanceTotal) -
        (Number(BankDeposit) + Number(Interest)) +
        (Number(Commission) + Number(BankWithdrawals)) || 0
    );

    setDeletedData(data);
    if (data.transactionType === undefined) {
      setDBBankArray(
        DBBankArray.filter(
          (ele) =>
            !(
              ele.batchBankNameId === data.batchBankNameId &&
              ele.batchBankName === data.batchBankName &&
              ele.indBankInterest === data.indBankInterest &&
              ele.indBankCommission === data.indBankCommission &&
              ele.indBatchBankDeposit === data.indBatchBankDeposit &&
              ele.indBatchBankWithdrawals === data.indBatchBankWithdrawals
            )
        )
      );
    } else {
      setDeletedData(data);
      setDBBankArray(
        DBBankArray.filter(
          (ele) =>
            !(
              ele.transactionType === "C" &&
              ele.batchBankName === data.batchBankName &&
              ele.indBankInterest === data.indBankInterest &&
              ele.indBankCommission === data.indBankCommission &&
              ele.indBatchBankDeposit === data.indBatchBankDeposit &&
              ele.indBatchBankWithdrawals === data.indBatchBankWithdrawals
            )
        )
      );

      let finalData = {
        batchId: formData.batchId,
        meetingHeldOnDate: formData.batchMeetingHeldOnDate,
        trasanctionType: "C",
        indBankInterest: data.indBankInterest,
        indBankCommission: data.indBankCommission,
        indBatchBankDeposit: data.indBatchBankDeposit,
        indBatchBankWithdrawals: data.indBatchBankWithdrawals,
      };

      deleteCashTransaction(finalData);
    }

    //////////////////////////new code //////////////////////////////////
    let bankDeposits =
    DBBankArray &&
    DBBankArray.map((ele) => ele.indBatchBankDeposit).reduce(
      (acc, curr) => Number(acc) + Number(curr),
      0
    );
  let withdraw =
    DBBankArray &&
    DBBankArray.map((ele) => ele.indBatchBankWithdrawals).reduce(
      (acc, curr) => Number(acc) + Number(curr),
      0
    );
  let commision =
    DBBankArray &&
    DBBankArray.map((ele) => ele.indBankCommission).reduce(
      (acc, curr) => Number(acc) + Number(curr),
      0
    );
  let interest =
    DBBankArray &&
    DBBankArray.map((ele) => ele.indBankInterest).reduce(
      (acc, curr) => Number(acc) + Number(curr),
      0
    );


 

  let depositeTot = Number(bankDeposits) - Number(BankDeposit);
  let withdawtot = Number(withdraw) - Number(BankWithdrawals);
  let interestTot = Number(interest) - Number(Interest);
  let commissionTot = Number(commision) - Number(Commission);

  let bankWith =
    DBBankArray &&
    DBBankArray.map((ele) => ele.indBatchBankWithdrawals).reduce(
      (acc, curr) => Number(acc) + Number(curr),
      0
    ); //this is needed bcz to update the withdrawable amount as per new bankBanalance
  let newBal = Number(prevBankBalance || 0) + Number(BankDeposit || 0);

  setFinalBankBalance(newBal);
  setNewTrailBankBalances(
    Number(prevBankBalance) +
      (depositeTot + interestTot) -
      (withdawtot + commissionTot)
  );

  if (data.transactionType !== undefined) {
    if (transactionType === "C") {
      if (CashInHand < 0) {
        setCashInhand(
          CashInHand + Number(BankDeposit || 0) + Number(BankWithdrawals || 0)
        );
      } else {
        setCashInhand(
          CashInHand + Number(BankDeposit || 0) - Number(BankWithdrawals || 0)
        );
      }
    } else {
      setCashInhand(
        Number(totIncomeCash || 0) -
          Number(totExpenseCash) -
          (Number(bankDeposits || 0) - BankDeposit)
      );
    }
  }

  ////////////////////////////new code end////////////////////////////////
    // let bankDeposits = DBBankArray &&  DBBankArray.map((ele) => ele.indBatchBankDeposit).reduce((acc, curr) => Number(acc) + Number(curr), 0);
    // let withdraw = DBBankArray &&  DBBankArray.map((ele) => ele.indBatchBankWithdrawals).reduce((acc, curr) => Number(acc) + Number(curr), 0);
    // let commision = DBBankArray &&  DBBankArray.map((ele) => ele.indBankCommission).reduce((acc, curr) => Number(acc) + Number(curr), 0);
    // let interest = DBBankArray &&  DBBankArray.map((ele) => ele.indBankInterest).reduce((acc, curr) => Number(acc) + Number(curr), 0);
    // let transactionType = data.transactionType;

    // if(transactionType === 'C'){
  
    //   setCashInhand(
    //   CashInHand + Number(BankDeposit || 0)) 
       
    // }else{
    //   setCashInhand(
    //     (Number(totIncomeCash || 0) - Number(totExpenseCash))- (Number(bankDeposits || 0) - BankDeposit ) 
    //    );
    // }
    // setCashInhand(
    //   PrevCashInHand || 0 + (Number(totIncomeCash) - Number(totExpenseCash))
    // );

    // let finalData = {
    //   batchId:formData.batchId,
    //   meetingHeldOnDate:formData.batchMeetingHeldOnDate,
    //   trasanctionType:"C",
    //   indBankInterest:data.indBankInterest,
    //   indBankCommission:data.indBankCommission,
    //   indBatchBankDeposit:data.indBatchBankDeposit,
    //   indBatchBankWithdrawals:data.indBatchBankWithdrawals,
    // }

    // deleteCashTransaction(finalData);

    // setShowNewBankBalnce(
    //   DBBankArray.filter((ele) => ele.batchBankNameId != bankId)
    // );
    // setDBBankArray(DBBankArray.filter((ele) => ele.batchBankNameId != bankId));

    // // beta code
    // setFormData({
    //   ...formData,
    //   bankTransactions: DBBankArray.filter(
    //     (ele) => ele.batchBankNameId != bankId
    //   ),
    // });
  };

  const fianlTabFinish = async (meetingStatus) => {
   
    if (formData._id == null) {
      let finalFormData = {
        ...formData,
        cashInHand: CashInHand,
        bankTransactions: DBBankArray,
        bankInterest: DBBankArray.reduce(
          (ac, cur) => (ac += Number(cur.indBankInterest)),
          0
        ),
        bankCommission: DBBankArray.reduce(
          (ac, cur) => (ac += Number(cur.indBankCommission)),
          0
        ),
        batchBankDeposit: DBBankArray.reduce(
          (ac, cur) => (ac += Number(cur.indBatchBankDeposit)),
          0
        ),
        batchBankWithdrawals: DBBankArray.reduce(
          (ac, cur) => (ac += Number(cur.indBatchBankWithdrawals)),
          0
        ),
        batchId: selectedBatchData[0]._id,
        batchName: selectedBatchData[0].batchName,
        batchMeetingHeldOnDate: selectedDate,
      };

      addOnlyBatchWiseMeetingData({
        mdStatus: "Submitted",
        ...finalFormData,
        meetingDates: formattedDate,
        selectedDates: selectedDates,
      });

      history.push("/landing-page");
    } else {
      if (meetingStatus == "Verified") {
        editOnlyBatchWiseMeetingData({
          mdStatus: "Verified",
          meetingDates: formattedDate,
          selectedDates: selectedDates,
          verifiedById: user._id,
          verifiedByDateAndTime: new Date(),
          ...formData,
          cashInHand: CashInHand,
          bankTransactions: DBBankArray,
          bankInterest: DBBankArray.reduce(
            (ac, cur) => (ac += Number(cur.indBankInterest)),
            0
          ),
          bankCommission: DBBankArray.reduce(
            (ac, cur) => (ac += Number(cur.indBankCommission)),
            0
          ),
          batchBankDeposit: DBBankArray.reduce(
            (ac, cur) => (ac += Number(cur.indBatchBankDeposit)),
            0
          ),
          batchBankWithdrawals: DBBankArray.reduce(
            (ac, cur) => (ac += Number(cur.indBatchBankWithdrawals)),
            0
          ),
        });
      } else {
        editOnlyBatchWiseMeetingData({
          mdStatus: "Submitted",
          ...formData,
          meetingDates: formattedDate,
          selectedDates: selectedDates,
          bankTransactions: DBBankArray,
          cashInHand: CashInHand,
          bankInterest: DBBankArray.reduce(
            (ac, cur) => (ac += Number(cur.indBankInterest)),
            0
          ),
          bankCommission: DBBankArray.reduce(
            (ac, cur) => (ac += Number(cur.indBankCommission)),
            0
          ),
          batchBankDeposit: DBBankArray.reduce(
            (ac, cur) => (ac += Number(cur.indBatchBankDeposit)),
            0
          ),
          batchBankWithdrawals: DBBankArray.reduce(
            (ac, cur) => (ac += Number(cur.indBatchBankWithdrawals)),
            0
          ),
        });
      }
      history.push("/landing-page");
    }
  };

  let bankDepositsForCond = 0;
  let bankWithdraw = 0;
  if (Number(totIncomeCash) - Number(totExpenseCash) < 0) {
    bankWithdraw =
      DBBankArray &&
      DBBankArray.filter((ele) => ele.transactionType === "C")
        .map((ele) => ele.indBatchBankWithdrawals)
        .reduce((acc, curr) => Number(acc) + Number(curr), 0);
  } else {
    bankDepositsForCond =
      DBBankArray &&
      DBBankArray.filter((ele) => ele.transactionType === "C" || ele.transactionType === "" || ele.transactionType === undefined )
        .map((ele) => ele.indBatchBankDeposit)
        .reduce((acc, curr) => Number(acc) + Number(curr), 0);
  }

  let depositeTotal = 0;
  let withdrawTotal = 0;

  DBBankArray &&
    DBBankArray.forEach((ele) => {
      depositeTotal += Number(ele.indBatchBankDeposit);
    });
  DBBankArray &&
    DBBankArray.forEach((ele) => {
      withdrawTotal += Number(ele.indBatchBankWithdrawals);
    });

  let bankWithdrawals =
    DBBankArray &&
    DBBankArray.filter((ele) => ele.transactionType === "C")
      .map((ele) => ele.indBatchBankWithdrawals)
      .reduce((acc, curr) => Number(acc) + Number(curr), 0);


  return (
    <div ref={tooltipRef}>
      {activeBank.length > 0 ? (
        <>
          <form>
            <div className="row card-new  py-3">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <h5>Bank Details</h5>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12 col-12 py-2">
                <h5>
                  Income&nbsp;:&nbsp;
                  <Incomearray data={"Income"} />
                </h5>
                Cash&nbsp;:&nbsp;
                <span class="mx-2">{totIncomeCash}</span> <br />
                {allPermission && allPermission.includes("batchCashBank") && (
                  <>
                    Bank&nbsp;:&nbsp;
                    <span class="mx-2">{totIncomeBank}</span>
                  </>
                )}
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12 col-12 py-2">
                <h5>
                  Expense&nbsp;:&nbsp;
                  <Incomearray data={"expense"} />
                </h5>
                Cash&nbsp;:&nbsp;
                <span class="mx-2">{totExpenseCash}</span> <br />
                {allPermission && allPermission.includes("batchCashBank") && (
                  <>
                    Bank&nbsp;:&nbsp;
                    <span class="mx-2">{totExpenseBank}</span>
                  </>
                )}
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12 col-12 py-2">
                <h5>
                  Net Total&nbsp;:&nbsp;
                  {/* <Incomearray data={"expense"}/> */}
                </h5>
                Cash&nbsp;:
                <span className="mx-2">
                  {Number(totIncomeCash) - Number(totExpenseCash)}
                </span>
                <br />
                {allPermission && allPermission.includes("batchCashBank") && (
                  <>
                    Bank&nbsp;:
                    <span className="mx-2">
                      {Number(totIncomeBank) - Number(totExpenseBank)}
                    </span>
                    <br />
                  </>
                )}
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <label className="label-control">Cash In Hand* :</label>
                <input
                  type="number"
                  name="cashInHand"
                  value={CashInHand}
                  className="form-control"
                  disabled
                  onWheel={(e) => e.currentTarget.blur()}
                  // onChange={(e) => onInputChange(e)}
                  //   onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 col-12 "></div>
              <div className="col-lg-3 col-md-12 col-sm-12 col-12 "></div>
              <div className="col-lg-3 col-md-12 col-sm-12 col-12 "></div>

              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <label
                  className="label-control"
                  // style={bankErrorStyle}
                >
                  Select Bank :
                </label>
                <Select
                  name="selBanks"
                  options={activeBank}
                  isSearchable={true}
                  placeholder="Select Bank"
                  onChange={(e) => inputBankDetChange(e, "selBanks")}
                  value={batchBankNameObj}
                />
              </div>

              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <label className="label-control">Bank Interest : </label>
                <input
                  type="number"
                  name="indBankInterest"
                  value={indBankInterest}
                  className="form-control"
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => inputBankDetChange(e)}
                />
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <label className="label-control">
                  Bank Commission&nbsp;:&nbsp;
                </label>
                <input
                  type="number"
                  name="indBankCommission"
                  value={indBankCommission}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => inputBankDetChange(e)}
                  //   onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <label
                  // style={myerror}
                  className="label-control"
                >
                  Bank Deposit&nbsp;:
                </label>
                <input
                  type="number"
                  name="indBatchBankDeposit"
                  onWheel={(e) => e.currentTarget.blur()}
                  value={indBatchBankDeposit}
                  className="form-control"
                  onChange={(e) => inputBankDetChange(e)}
                  //   onKeyDown={(e) => funcKeyDown(e)}
                  required
                />
              </div>

              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <label className="label-control">Bank Withdrawal :</label>
                <input
                  type="number"
                  name="indBatchBankWithdrawals"
                  value={indBatchBankWithdrawals}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => inputBankDetChange(e)}
                />
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                {Number(totIncomeCash) -
                  Number(totExpenseCash) +
                  Number(PrevCashInHand || 0) ==
                Number(CashInHand) + Number(indBatchBankDeposit) + Number(bankDepositsForCond || 0) ? (
                  <>
                    {CashInHand < 0 || withdrawDisable ? (
                      <input
                        type="button"
                        name="Submit"
                        value="ADD"
                        style={{background:"white",color:"black",cursor:"no-drop"}}
                        className="btn sub_form btn_continue blackbrd Save float-right"
                        disabled={true}
                      />
                    ) : 
                     (
                      <input
                        type="button"
                        name="Submit"
                        value="ADD"
                        className="btn sub_form btn_continue blackbrd Save float-right"
                        onClick={() => {
                          onAddBankDet();
                        }}
                        style={
                          DBBankArray &&
                          DBBankArray.map(
                            (ele) => ele.batchBankNameId
                          ).includes(batchBankNameObj.bankId)
                            ? {}
                            : {}
                        }
                        disabled={batchBankNameObj == ""}
                      />
                    ) }
                  </>
                ) : (
                  <input
                    type="button"
                    name="Submit"
                    value="ADD"
                    style={{background:"white",color:"black",cursor:"no-drop"}}
                    className="btn sub_form btn_continue blackbrd Save float-right"
                    disabled={true}
                  />
                )}
              </div>
              <label className="col-12 text-right p-1" style={{color:"red"}}>{lowbalanceError}</label>
            </div>

            <div className="body-inner no-padding table-responsive">
              <table
                className="tabllll table table-bordered table-striped table-hover"
                id="datatable2"
              >
                <thead>
                  <tr>
                    <th>Page</th>
                    <th>Bank Name</th>
                    <th>Bank Interest</th>
                    <th>Bank Commission</th>
                    <th>Bank Deposit Amount</th>
                    <th>Bank Withdrawal Amount</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {DBBankArray &&
                    DBBankArray.map((ele, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{ele.transactionType}</td>
                          <td>{ele.batchBankName}</td>
                          <td>{ele.indBankInterest}</td>
                          <td>{ele.indBankCommission}</td>
                          <td>{ele.indBatchBankDeposit}</td>
                          <td>{ele.indBatchBankWithdrawals}</td>
                          <td>
                            {ele.transactionType === "C" || ele.transactionType == null ? (
                              <img
                                className="img_icon_size log"
                                src={require("../../../static/images/delete.png")}
                                alt="Delete"
                                title="Delete"
                                onClick={() => onDeleteBankDet(ele)}
                              />
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </form>
        </>
      ) : (
        <>
          <form>
            <div className="row card-new py-3">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <h5>Bank Details</h5>
              </div>

              <div className="col-lg-5 col-md-12 col-sm-12 col-12 py-2">
                <span>Income&nbsp;:</span>
              </div>

              <div className="col-lg-5 col-md-12 col-sm-12 col-12 py-2">
                <span>Expense&nbsp;:</span>
              </div>

              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <label className="label-control">Cash In Hand* :</label>
                <input
                  type="number"
                  name="cashInHand"
                  // value={cashInHand}
                  className="form-control"
                  // onChange={(e) => onInputChange(e)}
                  //   onChange={(e) => onInputChange(e)}
                  //   onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
          </form>
        </>
      )}

      <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
        {fromPage == "EditBatchMeeting" ? (
          <>
            {batchTransactionData && batchTransactionData.verifiedById ? (
              <>
                {allRights &&
                allRights.includes(user.userGroupName + "EditReport") ? (
                  <input
                    type="button"
                    name="Submit"
                    value="Submit"
                    disabled={CashInHand < 0 ?true:false}
                    onClick={() => fianlTabFinish("Verified")}
                    className="btn sub_form btn_continue Save float-right"
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                {allRights &&
                allRights.includes(user.userGroupName + "VerifyMeeting") ? (
                  <>
                    <input
                      type="button"
                      disabled={CashInHand < 0 ?true:false}
                      onClick={() => fianlTabFinish("Verified")}
                      name="Verify"
                      value="Verify"
                      className="btn sub_form btn_continue Save float-right"
                    />
                  </>
                ) : (
                  ""
                )}

                {allRights &&
                allRights.includes(user.userGroupName + "EditMeeting") ? (
                  <>
                    <input
                      type="button"
                      onClick={() => fianlTabFinish("Submitted")}
                      disabled={CashInHand < 0 ?true:false}

                      name="Update"
                      value="Update"
                      className="btn sub_form btn_continue Save float-right"
                    />
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        ) : (
          <>
            <input
              type="button"
              onClick={() => fianlTabFinish("Submitted")}
              name="Update"
              value="Update"
              className="btn sub_form btn_continue Save float-right"
            />
          </>
        )}

        <button
          type="button"
          className="btn sub_form btn_continue Save float-right"
          onClick={() => NextBackBtn(7)}
        >
          Previous
        </button>
      </div>

      {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <button
          className="btn sub_form btn_continue Save float-right"
          // onClick={() => nextOfBatchLoanSanc()}
          onClick={() => {
            alert("hello");

            fianlTabFinish("Submitted");
          }}
        >
          Submit
        </button>
      </div> */}
    </div>
  );
};

BatchBankDetTab.propTypes = {
  //   second: PropTypes.third,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

const mapDispatchToProps = {
  editBatchMeetingData,
  addBatchMeetingData,
  addOnlyBatchWiseMeetingData,
  editOnlyBatchWiseMeetingData,
  deleteCashTransaction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchBankDetTab);
