import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { editPermissionModuleDetails } from "../../actions/setting";
import Spinner from "../layout/Spinner";
import Select from "react-select"
const EditPermission = ({
  editpermissiondata,
  onUpdateModalChange,
  auth: { isAuthenticated, user, users, loading },
  editPermissionModuleDetails,
}) => {
  //formData
  const [formData, setFormData] = useState({
    permissionName:
      editpermissiondata && editpermissiondata.permissionName
        ? editpermissiondata.permissionName
        : "",
    permissionDescription:
      editpermissiondata && editpermissiondata.permissionDescription
        ? editpermissiondata.permissionDescription
        : "",
    isSubmitted: false,
  });

    const [subscriptions,setSubscription] = useState([
        {label:"Basic",value:"B"},
        {label:"Standard",value:"BS"},
        {label:"Advanced",value:"BSA"},
        {label:"Ultimate",value:"BSAU"}
      ]);
    
    // console.log("editpermissiondata",editpermissiondata)
    let subModal = editpermissiondata.SubscriptionType ? editpermissiondata.SubscriptionType.label : ""
    const[subscriptionModal,setSubscriptionModal] = useState(subscriptions.filter((ele)=> ele.label === subModal ));

  const { permissionName, permissionDescription } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: editpermissiondata ? editpermissiondata._id : "",
      permissionName: permissionName,
      permissionDescription: permissionDescription,
      permissionEditedById: user._id,
      SubscriptionType:subscriptionModal,
      permissionEditedDateTime: new Date().toLocaleString("en-GB"),
    };
    editPermissionModuleDetails(finalData);
    onUpdateModalChange(true);
    setFormData({
      ...formData,
      permissionName: "",
      permissionDescription: "",
      isSubmitted: true,
    });
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form className="row" onSubmit={(e) => onSubmit(e)} autoComplete="off">
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="col-lg-10 col-md-12 col-sm-12 col-12">
              <label className="label-control">Subscription Type* :</label>
              <Select
                    name="subscriptionModal"
                    options={subscriptions}
                    isSearchable={true}
                    value={subscriptionModal}
                    placeholder="Subscription"
                    onChange={(e) => setSubscriptionModal(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
            </div>
            <div className="col-lg-10 col-md-12 col-sm-12 col-12">
              <label className="label-control"> Module Name* :</label>
              <input
                type="text"
                name="permissionName"
                value={permissionName}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
            <div className="col-lg-10 col-md-12 col-sm-12 col-12">
              <label className="label-control">Description* :</label>
              <textarea
                name="permissionDescription"
                value={permissionDescription}
                id="permissionDescription"
                className="textarea form-control"
                rows="4"
                placeholder="Description"
                onChange={(e) => onInputChange(e)}
                style={{ width: "100%" }}
                required
              ></textarea>
            </div>
          </div>

          <div className="col-md-10 col-lg-10 col-sm-12 col-12 text-left">
            {loading ? (
              <button
                className="btn sub_form btn_continue Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="submit"
                value="Update"
                className="btn sub_form btn_continue Save float-right"
              />
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

EditPermission.propTypes = {
  auth: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
  editPermissionModuleDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  setting: state.setting,
});

export default connect(mapStateToProps, {
  editPermissionModuleDetails,
})(EditPermission);
