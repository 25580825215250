import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { getActiveModule, getActivePermissions } from "../../actions/setting";
import { editInstitution } from "../../actions/shg";
import Select from "react-select";
import Spinner from "../layout/Spinner";
import FileBase64 from "react-file-base64";
import { RadioGroup, RadioButton } from "react-radio-buttons";

const EditInstitutionDetails = ({
  auth: { isAuthenticated, user, users, loading },
  getActiveModule,
  getActivePermissions,
  setting: { activemoduleData, activepermissionData },
  onUpdateModalChange,
  editInstitution,
  institutionsdata,
}) => {
  useEffect(() => {
    getActiveModule();
  }, [getActiveModule]);
  useEffect(() => {
    getActivePermissions();
  }, [getActivePermissions]);

  //formData
  const [formData, setFormData] = useState({
    institutionName:
      institutionsdata && institutionsdata.institutionName
        ? institutionsdata.institutionName
        : "",
    stateName:
      institutionsdata && institutionsdata.stateName
        ? institutionsdata.stateName
        : "",
    institutionAddress:
      institutionsdata && institutionsdata.institutionAddress
        ? institutionsdata.institutionAddress
        : "",
    missionStatement:
      institutionsdata && institutionsdata.missionStatement
        ? institutionsdata.missionStatement
        : "",
    aboutOrganization:
      institutionsdata && institutionsdata.aboutOrganization
        ? institutionsdata.aboutOrganization
        : "",
    institutionDesc:
      institutionsdata && institutionsdata.institutionDesc
        ? institutionsdata.institutionDesc
        : "",

    institutionInterestRate:
      institutionsdata && institutionsdata.institutionInterestRate
        ? institutionsdata.institutionInterestRate
        : "",
    institutionType:
      institutionsdata && institutionsdata.institutionType
        ? {
            value: institutionsdata.institutionType,
            label: institutionsdata.institutionType,
          }
        : "",
    institutionLogo:
      institutionsdata && institutionsdata.institutionProfile
        ? institutionsdata.institutionProfile
        : "",

    aggrementAmount:
      institutionsdata.output && institutionsdata.output.aggrementAmount
        ? institutionsdata.output.aggrementAmount
        : "",
    // aggrementStartDate:
    //   institutionsdata &&
    //   institutionsdata.output &&
    //   institutionsdata.output &&
    //   institutionsdata.output.aggrementStartDate
    //     ? institutionsdata.output.aggrementStartDate
    //     : "",

    // aggrementEndDate:
    //   institutionsdata.output && institutionsdata.output.aggrementEndDate
    //     ? institutionsdata.output.aggrementEndDate
    //     : "",
    ChequeNoOrDDNo:
      institutionsdata.output && institutionsdata.output.ChequeNoOrDDNo
        ? institutionsdata.output.ChequeNoOrDDNo
        : "",

    aggrementBankName:
      institutionsdata.output && institutionsdata.output.aggrementBankName
        ? institutionsdata.output.aggrementBankName
        : "",

    aggrementChequeDate:
      institutionsdata.output && institutionsdata.output.aggrementChequeDate
        ? institutionsdata.output.aggrementChequeDate
        : "",
    aggrementPaymentMode:
      institutionsdata.output && institutionsdata.output.aggrementPaymentMode
        ? {
            value: institutionsdata.output.aggrementPaymentMode,
            label: institutionsdata.output.aggrementPaymentMode,
          }
        : "",
    isSubmitted: false,
  });

  const {
    aggrementPaymentMode,
    aggrementAmount,
    // aggrementStartDate,
    // aggrementEndDate,
    ChequeNoOrDDNo,
    aggrementBankName,
    // aggrementChequeDate,

    institutionName,
    institutionAddress,
    missionStatement,
    aboutOrganization,
    institutionDesc,
    institutionType,
    institutionInterestRate,
    institutionLogo,
  } = formData;
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const UserZone = [
    { value: "Zoned", label: "Zoned" },
    { value: "FreeZone", label: "FreeZone" },
  ];

  const [aggrementStartDate, setStartDate] = useState(
    institutionsdata &&
      institutionsdata.output &&
      institutionsdata.output &&
      institutionsdata.output.aggrementStartDate
      ? institutionsdata.output.aggrementStartDate
      : ""
  );

  const [aggrementEndDate, setEndDate] = useState(
    institutionsdata &&
      institutionsdata.output &&
      institutionsdata.output &&
      institutionsdata.output.aggrementEndDate
      ? institutionsdata.output.aggrementEndDate
      : ""
  );
  const [aggrementChequeDate, setChequeDate] = useState(
    institutionsdata &&
      institutionsdata.output &&
      institutionsdata.output &&
      institutionsdata.output.aggrementChequeDate
      ? institutionsdata.output.aggrementChequeDate
      : ""
  );
  const onStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const onEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const onChequeDateChange = (e) => {
    setChequeDate(e.target.value);
  };

  const [showHide, setShowHide] = useState({
    showChequenoSection:
      institutionsdata.output &&
      institutionsdata.output.aggrementPaymentMode === "Cheque"
        ? true
        : false,
    // showChequenoSection: false,
  });
  const { showChequenoSection } = showHide;

  const PaymentMethods = [
    { value: "Cash", label: "Cash" },
    { value: "Cheque", label: "Cheque" },
    { value: "Online Transaction", label: "Online Transaction" },
  ];

  const onPaymentModeChange = (e) => {
    if (e) {
      setFormData({
        ...formData,
        aggrementPaymentMode: e,
      });
    }
    if (e.value === "Cheque") {
      setShowHide({
        ...showHide,
        showChequenoSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showChequenoSection: false,
      });
    }
  };
  const onInstituteTypeChange = (e) => {
    if (e) {
      setFormData({
        ...formData,
        institutionType: e,
      });
    }
  };

  const [isDemo, setIsDemo] = useState(
    institutionsdata && institutionsdata.accountType
      ? institutionsdata.accountType
      : false
  );
  //sttrt
  const [addDataInstruction, setInstructionFormDatas] = useState({
    accessId: "",
    accessName: "",
    isChecked: 1,
  });

  const { accessId, isChecked, accessName } = addDataInstruction;

  const [AddedInstructionDetails, AddInstructionDetails] = useState(
    institutionsdata.access
  );
  const [accessList, AddAccessList] = useState([]);

  //For Checkbox
  const onAccesscheckSelect = (e, activemoduleData) => {

    if (e.target.checked) {
      const addDataInstruction = {
        accessId: activemoduleData._id,
        accessName: activemoduleData.accessName,
        accessStatus: isChecked,
      };
      setInstructionFormDatas({
        ...addDataInstruction,
        accessId: "",
        accessName: "",
        isChecked: 1,
      });
      let temp = [];
      AddedInstructionDetails
        ? temp.push(...AddedInstructionDetails, addDataInstruction)
        : temp.push(addDataInstruction);

      AddInstructionDetails(temp);
      let temp2 = [];
      temp2.push(...accessList, activemoduleData._id);
      AddAccessList(temp2);
    } else {
      onRemoveChange(activemoduleData);
    }
  };

  const onRemoveChange = (activemoduleData) => {
    setInstructionFormDatas({
      ...addDataInstruction,
      accessId: "",
      accessName: "",
      isChecked: 0,
    });

    const removeList = AddedInstructionDetails.filter(
      (AddInstructionDetails) =>
        AddInstructionDetails.accessId !== activemoduleData._id
    );
    AddInstructionDetails(removeList);
    const index = accessList.indexOf(activemoduleData._id);
    if (index > -1) accessList.splice(index, 1);
  };

  const [addDataInstruction1, setInstructionFormDatas1] = useState({
    permissionId: "",
    permissionName: "",
    isChecked1: 1,
  });

  const { permissionId, isChecked1, permissionName } = addDataInstruction1;
  const [permissionList, AddPermissionList] = useState([]);

  const [AddedInstructionDetails1, AddInstructionDetails1] = useState(
    institutionsdata.permission
  );




  const onPermissioncheckSelect = (e, activepermissionData) => {
    if (e.target.checked) {
      const addDataInstruction1 = {
        permissionId: activepermissionData._id,
        permissionName: activepermissionData.permissionName,
        permissionStatus: isChecked1,
      };
      setInstructionFormDatas1({
        ...addDataInstruction1,
        permissionId: "",
        permissionName: "",
        isChecked1: 1,
      });

      let temp = [];
      AddedInstructionDetails1
        ? temp.push(...AddedInstructionDetails1, addDataInstruction1)
        : temp.push(addDataInstruction1);

      AddInstructionDetails1(temp);
      let temp2 = [];
      temp2.push(...permissionList, activepermissionData._id);
      AddPermissionList(temp2);
    } else {
      onRemoveChange1(activepermissionData);
    }
  };

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const onRemoveChange1 = (activepermissionData) => {
    setInstructionFormDatas1({
      ...addDataInstruction1,
      permissionId: "",
      permissionName: "",
      isChecked: 0,
    });

    const removeList = AddedInstructionDetails1.filter(
      (AddInstructionDetails1) =>
        AddInstructionDetails1.permissionId !== activepermissionData._id
    );
    AddInstructionDetails1(removeList);
    const index = permissionList.indexOf(activepermissionData._id);
    if (index > -1) permissionList.splice(index, 1);
  };

  //end

  // code for next previous tabing starts
  const [tabIndex, setTabIndex] = useState(0);

  const NextBackBtn = (tabIndex) => {
    setTabIndex(tabIndex);
  };


  const[subscriptionType,setSubscriptionType] = useState(institutionsdata.subscriptionType ? institutionsdata.subscriptionType : "B");
  const[permissionInfo,setPermissionInfo] = useState([]);

  const [rightsList, AddRightsList] = useState(institutionsdata.groupRights);
  const onRightsCheckSelect = (e) => {
    if (e.target.checked) {
      let temp = [];
      rightsList
        ? temp.push(...rightsList, e.target.name)
        : temp.push(e.target.name);
      AddRightsList(temp);
    } else {
      const removeList = rightsList.filter(
        (rights) => rights !== e.target.name
      );
      AddRightsList(removeList);
    }
  };

  const onUpdate = (e) => {

    const uniquePermission =[...AddedInstructionDetails1,...permissionInfo].filter((value,index,self)=>self.findIndex((t)=>(t.permissionId === value.permissionId))===index)

    e.preventDefault();
    const finalData = {
      recordId: institutionsdata ? institutionsdata._id : "",
      institutionName: institutionName,
      institutionAddress: institutionAddress,
      institutionType: institutionType.value,
      institutionInterestRate: institutionInterestRate,
      missionStatement: missionStatement,
      aboutOrganization: aboutOrganization,
      institutionDesc: institutionDesc,
      institutionProfile: institutionLogo,
      aggrementAmount: aggrementAmount,
      aggrementStartDate: aggrementStartDate,
      aggrementEndDate: aggrementEndDate,
      aggrementPaymentMode: aggrementPaymentMode.value,
      ChequeNoOrDDNo: ChequeNoOrDDNo,
      aggrementBankName: aggrementBankName,
      aggrementChequeDate: aggrementChequeDate,
      access: AddedInstructionDetails,
      permission:  uniquePermission ,
      accountType: isDemo,
      groupRights: rightsList,
      subscriptionType: subscriptionType,
    };

    editInstitution(finalData);
    onUpdateModalChange(true);
  };
  const [newPermissionList,setNewPermissionList] = useState([]);

  useEffect(() => {
    let matchedPermissions = []; 
    let permissions = [];
  
    if (activepermissionData) {
      activepermissionData.forEach((permissionData) => {
        let isChecked = "1"; 
        let modules = subscriptionType; 
  
        if (
          permissionData.SubscriptionType &&
          permissionData.SubscriptionType.value
        ) {
          const permissionModule = permissionData.SubscriptionType.value;
  
          if (modules.includes(permissionModule)) {
            AddedInstructionDetails1 &&
              AddedInstructionDetails1.forEach((institutionsData) => {
                if (
                  institutionsData.permissionName === permissionData.permissionName
                ) {
                  isChecked = institutionsData.permissionStatus; 
                }
              });
  
            matchedPermissions.push(
              // permissionId: 
              permissionData._id, 
              // permissionName: permissionData.permissionName,
              // permissionStatus: isChecked, 
            );

            permissions.push({
               permissionId: permissionData._id, 
               permissionName: permissionData.permissionName,
               permissionStatus: isChecked, 
            })
          }
        }
      });
    }
    
    // Update the state with the final list of permissions
    setNewPermissionList(matchedPermissions);
    setPermissionInfo(permissions)
  
  }, [subscriptionType,activepermissionData]); 
  

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <Tabs selectedIndex={tabIndex}>
        <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
          <TabList>
            <Tab tabfor="0">Company Info</Tab>
            <Tab tabfor="1">Permissions</Tab>
            <Tab tabfor="2">Group Rights</Tab>
            <Tab tabfor="3">Agreement Info</Tab>
            {/* <Tab tabfor="3">Access</Tab> */}
          </TabList>
        </div>
        <TabPanel tabId="0">
          <div className=" col-md-12 col-lg-12 col-sm-12 col-12 ">
            <form onSubmit={(e) => NextBackBtn(1)}>
              <div className="row col-lg-12 col-md-12 col-sm-12 col-12 ">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
                  <div className="row card-new">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h5>Basic Info</h5>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-4 col-12">
                      <label className="label-control">
                        Institution Name* :
                      </label>
                      <input
                        type="text"
                        name="institutionName"
                        value={institutionName}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-4 col-12">
                      <label className="label-control">
                        Institution Interest Rate :
                      </label>
                      <input
                        type="text"
                        name="institutionInterestRate"
                        value={institutionInterestRate}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-4 col-12">
                      <label className="label-control">
                        Institution belongs to :
                      </label>
                      <Select
                        name="institutionTypes"
                        options={UserZone}
                        value={institutionType}
                        isSearchable={false}
                        placeholder="Select Type"
                        onChange={(e) => onInstituteTypeChange(e)}
                        theme={(theme) => ({
                          ...theme,
                          height: 26,
                          minHeight: 26,
                          borderRadius: 1,
                          colors: {
                            ...theme.colors,
                            primary: "black",
                          },
                        })}
                      />
                    </div>
                    <div className="col-lg-12 col-md-4 col-sm-4 col-12">
                      <label className="label-control">
                        Institution Address* :
                      </label>
                      <textarea
                        name="institutionAddress"
                        value={institutionAddress}
                        id="institutionAddress"
                        className="textarea form-control"
                        rows="4"
                        placeholder="Address"
                        onChange={(e) => onInputChange(e)}
                        style={{ width: "100%" }}
                        required
                      ></textarea>
                    </div>
                    <div className="row col-lg-12 col-md-4 col-sm-4 col-12 py-3">
                      <label className="label-control">
                        Institution Logo :
                      </label>

                      <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                        <FileBase64
                          type="file"
                          multiple={false}
                          onDone={({ base64 }) =>
                            setFormData({
                              ...formData,
                              institutionLogo: base64,
                            })
                          }
                        />
                        <img
                          className="log_size"
                          alt="Pinnacle Media"
                          src={`${institutionLogo}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="row card-new ">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h5>Additional Info</h5>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <label>Mission* :</label>
                      <textarea
                        name="missionStatement"
                        value={missionStatement}
                        id="missionStatement"
                        className="textarea form-control"
                        rows="3"
                        placeholder="Mission"
                        onChange={(e) => onInputChange(e)}
                        style={{ width: "100%" }}
                        required
                      ></textarea>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <label className="label-control">About :</label>
                      <textarea
                        name="aboutOrganization"
                        value={aboutOrganization}
                        id="aboutOrganization"
                        className="textarea form-control"
                        rows="3"
                        placeholder="About"
                        onChange={(e) => onInputChange(e)}
                        style={{ width: "100%" }}
                        required
                      ></textarea>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                      <label className="label-control">Additional Info :</label>
                      <textarea
                        name="institutionDesc"
                        value={institutionDesc}
                        id="institutionDesc"
                        className="textarea form-control"
                        rows="3"
                        placeholder="Additional Info"
                        onChange={(e) => onInputChange(e)}
                        style={{ width: "100%" }}
                        required
                      ></textarea>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                      <label className="label-control">
                        Is Demo Account* :
                      </label>
                      <input
                        style={{
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                          display: "block",
                        }}
                        name="demoCheck"
                        type="checkbox"
                        id="demoCheck"
                        checked={isDemo}
                        onChange={(e) => setIsDemo(!isDemo)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <input
                    type="submit"
                    name="submit"
                    value="Next"
                    className="btn sub_form btn_continue Save float-right"
                  />
                </div>
              </div>
              {/* </div> */}
            </form>
          </div>
        </TabPanel>

        <TabPanel tabId="1">
          <form onSubmit={(e) => NextBackBtn(2)}>
            <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                <div className="row card-new  py-3">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="col-lg-4 col-md-12 col-sm-12">
                      <h5>Give Permissions</h5>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                      <RadioGroup
                        onChange={(e) => setSubscriptionType(e)}
                        className="radio-group patient-page"
                        horizontal
                        value={subscriptionType}
                        required
                      >
                        <RadioButton
                          padding={10}
                          rootColor="#144c5a"
                          pointColor="#144c5a"
                          iconSize={20}
                          iconInnerSize={10}
                          value="B"
                        >
                          Basic
                        </RadioButton>
                        <RadioButton
                          padding={10}
                          rootColor="#144c5a"
                          pointColor="#144c5a"
                          iconSize={20}
                          iconInnerSize={10}
                          value="BS"
                        >
                          Standard
                        </RadioButton>
                        <RadioButton
                          padding={10}
                          rootColor="#144c5a"
                          pointColor="#144c5a"
                          iconSize={20}
                          iconInnerSize={10}
                          value="BSA"
                        >
                          Advanced
                        </RadioButton>
                        <RadioButton
                          padding={10}
                          rootColor="#144c5a"
                          pointColor="#144c5a"
                          iconSize={20}
                          iconInnerSize={10}
                          value="BSAU"
                        >
                          Ultimate
                        </RadioButton>
                      </RadioGroup>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 body-inner no-padding  table-responsive">
                    <table
                      className="table table-bordered table-striped table-hover"
                      id="datatable2"
                    >
                      <thead>
                        <tr>
                          <th>Permissions Name</th>
                          <th>Description</th>
                          <th>Default Permissions</th>
                          <th>Additional Permissions</th>
                        </tr>
                      </thead>

                      <tbody>
                      {activepermissionData &&
                              activepermissionData.map(
                                (activepermissionData, idx) => {
                                  let isChecked = 0;                                  
                                    AddedInstructionDetails1 &&
                                      AddedInstructionDetails1.map(
                                        (institutionsdata) => {
                                          if (
                                            institutionsdata.permissionName ===
                                            activepermissionData.permissionName
                                          ) {
                                            isChecked =
                                              institutionsdata.permissionStatus;
                                          }
                                          return <></>;
                                        }
                                      );
                                  return (
                                    <tr key={idx}>
                                      <td style={{ maxWidth:"50px" }}>
                                        
                                        {
                                          activepermissionData.permissionName
                                        }
                                      </td>
                                      <td style={{maxWidth:"150px",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"wrap"}}>
                                        {
                                          activepermissionData.permissionDescription
                                        }
                                      </td>

                                      <td  style={{ maxWidth:"50px" }}>
                                        <center>
                                          <input
                                            style={{
                                              height: "40px",
                                              width: "40px",
                                              borderRadius: "50%",
                                              display: "block",
                                            }}
                                            name="permissionId"
                                            type="checkbox"
                                            className="permissionclass"
                                            id="permissionId"
                                            checked={newPermissionList.includes(
                                              activepermissionData._id
                                            )}
                                            
                                           
                                          />
                                        </center>
                                      </td>

                                      <td style={{ maxWidth:"50px" }}>
                                        <center>
                                          <input
                                            style={{
                                              height: "40px",
                                              width: "40px",
                                              borderRadius: "50%",
                                              display: "block",
                                            }}
                                            name="permissionId"
                                            type="checkbox"
                                            className="permissionclass"
                                            id="permissionId"
                                            disabled={newPermissionList.includes(
                                              activepermissionData._id
                                            )}
                                            checked={isChecked === 0 ? false :
                                              newPermissionList.includes(activepermissionData._id) ? false :  true
                                            }
                                            
                                            onChange={(e) =>
                                              onPermissioncheckSelect(
                                                e,
                                                activepermissionData
                                              )
                                            }
                                          />
                                        </center>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <input
                  type="submit"
                  name="submit"
                  value="Next"
                  className="btn sub_form btn_continue Save float-right"
                />
                <button
                  className="btn sub_form btn_continue Save float-right"
                  onClick={() => NextBackBtn(0)}
                >
                  Previous
                </button>
              </div>
            </div>
          </form>
        </TabPanel>


        <TabPanel tabId="2">
          <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
            <div className="col-lg-11 col-md-12 col-sm-12 col-12 py-3">
              <div className="row card-new  py-3">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <h5>Group Rights</h5>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <h4> Admin</h4>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <input
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                    name="AdminEditMeeting"
                    type="checkbox"
                    id="AdminEditMeeting"
                    checked={
                      rightsList && rightsList.includes("AdminEditMeeting")
                    }
                    onChange={(e) => onRightsCheckSelect(e)}
                  />
                  <label>Edit Meeting</label>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <input
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                    name="AdminVerifyMeeting"
                    type="checkbox"
                    id="AdminVerifyMeeting"
                    checked={
                      rightsList && rightsList.includes("AdminVerifyMeeting")
                    }
                    onChange={(e) => onRightsCheckSelect(e)}
                  />{" "}
                  <label>Verify Meeting </label>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <input
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                    name="AdminEditReport"
                    type="checkbox"
                    id="AdminEditReport"
                    checked={
                      rightsList && rightsList.includes("AdminEditReport")
                    }
                    onChange={(e) => onRightsCheckSelect(e)}
                  />{" "}
                  <label>Edit Report </label>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <input
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                    name="AdminTrainingVideo"
                    type="checkbox"
                    id="AdminTrainingVideo"
                    checked={
                      rightsList && rightsList.includes("AdminTrainingVideo")
                    }
                    onChange={(e) => onRightsCheckSelect(e)}
                  />{" "}
                  <label>Training Videos </label>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <h4> Co-ordinator</h4>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <input
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                    name="Co-ordinatorEditMeeting"
                    type="checkbox"
                    id="Co-ordinatorEditMeeting"
                    checked={
                      rightsList &&
                      rightsList.includes("Co-ordinatorEditMeeting")
                    }
                    onChange={(e) => onRightsCheckSelect(e)}
                  />{" "}
                  <label>Edit Meeting </label>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <input
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                    name="Co-ordinatorVerifyMeeting"
                    type="checkbox"
                    id="Co-ordinatorVerifyMeeting"
                    checked={
                      rightsList &&
                      rightsList.includes("Co-ordinatorVerifyMeeting")
                    }
                    onChange={(e) => onRightsCheckSelect(e)}
                  />{" "}
                  <label>Verify Meeting </label>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <input
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                    name="Co-ordinatorEditReport"
                    type="checkbox"
                    id="Co-ordinatorEditReport"
                    checked={
                      rightsList &&
                      rightsList.includes("Co-ordinatorEditReport")
                    }
                    onChange={(e) => onRightsCheckSelect(e)}
                  />{" "}
                  <label>Edit Report </label>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <input
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                    name="cordiTrainingVideo"
                    type="checkbox"
                    id="cordiTrainingVideo"
                    checked={
                      rightsList && rightsList.includes("cordiTrainingVideo")
                    }
                    onChange={(e) => onRightsCheckSelect(e)}
                  />{" "}
                  <label>Training Videos </label>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <h4> Incharge</h4>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <input
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                    name="InchargeEditMeeting"
                    type="checkbox"
                    id="InchargeEditMeeting"
                    checked={
                      rightsList && rightsList.includes("InchargeEditMeeting")
                    }
                    onChange={(e) => onRightsCheckSelect(e)}
                  />{" "}
                  <label>Edit Meeting </label>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <input
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                    name="InchargeVerifyMeeting"
                    type="checkbox"
                    id="InchargeVerifyMeeting"
                    checked={
                      rightsList && rightsList.includes("InchargeVerifyMeeting")
                    }
                    onChange={(e) => onRightsCheckSelect(e)}
                  />{" "}
                  <label>Verify Meeting </label>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <input
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                    name="InchargeEditReport"
                    type="checkbox"
                    id="InchargeEditReport"
                    checked={
                      rightsList && rightsList.includes("InchargeEditReport")
                    }
                    onChange={(e) => onRightsCheckSelect(e)}
                  />{" "}
                  <label>Edit Report </label>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <input
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                    name="InchargeTrainingVideo"
                    type="checkbox"
                    id="InchargeTrainingVideo"
                    checked={
                      rightsList && rightsList.includes("InchargeTrainingVideo")
                    }
                    onChange={(e) => onRightsCheckSelect(e)}
                  />{" "}
                  <label>Training Videos </label>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <button
                className="btn sub_form btn_continue Save float-right"
                onClick={() => NextBackBtn(3)}
              >
                Next
              </button>
              <button
                className="btn sub_form btn_continue Save float-right"
                onClick={() => NextBackBtn(1)}
              >
                Previous
              </button>
            </div>
          </div>
           
        </TabPanel>

        <TabPanel tabId="3">
          
          <form className="row" onSubmit={(e) => onUpdate(e)}>

            <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Agreement Start Date :</label>
                <input
                  type="date"
                  placeholder="dd/mm/yyyy"
                  className="form-control cpp-input datevalidation"
                  name="instistartDate"
                  value={aggrementStartDate}
                  onChange={(e) => onStartDateChange(e)}
                  style={{
                    width: "75%",
                  }}
                  required
                />
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Agreement End Date :</label>
                <input
                  type="date"
                  placeholder="dd/mm/yyyy"
                  className="form-control cpp-input datevalidation"
                  name="instiendDate"
                  value={aggrementEndDate}
                  onChange={(e) => onEndDateChange(e)}
                  style={{
                    width: "75%",
                  }}
                  required
                />
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Agreement Amount :</label>
                <input
                  type="number"
                  name="aggrementAmount"
                  value={aggrementAmount}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  required
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-4 col-12">
                <label>Mode Of Payment* :</label>
                <Select
                  name="aggrementPaymentMode"
                  options={PaymentMethods}
                  isSearchable={false}
                  value={aggrementPaymentMode}
                  placeholder="Select"
                  onChange={(e) => onPaymentModeChange(e)}
                  theme={(theme) => ({
                    ...theme,
                    height: 26,
                    minHeight: 26,
                    borderRadius: 1,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                  })}
                />
              </div>
              {showChequenoSection && (
                <>
                  <div className="col-lg-4  col-md-4 col-sm-4 col-12 py-3">
                    <label>Cheque No/DD No :</label>
                    <input
                      type="number"
                      name="ChequeNoOrDDNo"
                      value={ChequeNoOrDDNo}
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => funcKeyDown(e)}
                      required
                    />
                  </div>

                  <div className="col-lg-4  col-md-4 col-sm-4 col-12 py-3">
                    <label>Bank Name :</label>
                    <input
                      type="text"
                      name="aggrementBankName"
                      value={aggrementBankName}
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                      required
                    />
                  </div>

                  <div className="col-lg-4  col-md-4 col-sm-4 col-12 py-3">
                    <label>ChequeDate :</label>
                    <br />
                    <input
                      type="date"
                      placeholder="dd/mm/yyyy"
                      className="form-control cpp-input datevalidation"
                      name="aggrementChequeDate"
                      value={aggrementChequeDate}
                      onChange={(e) => onChequeDateChange(e)}
                      style={{
                        width: "75%",
                      }}
                    />
                  </div>
                </>
              )}
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <input
                  type="submit"
                  name="submit"
                  value="Submit"
                  className="btn sub_form btn_continue Save float-right"
                />
                <button
                  className="btn sub_form btn_continue Save float-right"
                  onClick={() => NextBackBtn(2)}
                >
                  Previous
                </button>
              </div>
            </div>
          </form>
        </TabPanel>

        {/* <TabPanel tabId="3">
          <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
              <div className="row card-new  py-3">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <h5>Give Access</h5>
                </div>
                <div className="col-lg-10 col-md-12 col-sm-12 col-12 body-inner no-padding  table-responsive">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>Module Name</th>
                        <th>Description</th>
                        <th>Give Access</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activemoduleData &&
                        activemoduleData.map((activemoduleData, idx) => {
                          let isChecked = 0;
                          {
                            AddedInstructionDetails &&
                              AddedInstructionDetails.map(
                                (institutionsdata) => {
                                  if (
                                    institutionsdata.accessName ===
                                    activemoduleData.accessName
                                  ) {
                                    isChecked = institutionsdata.accessStatus;
                                  }
                                  return <></>;
                                }
                              );
                            return (
                              <tr key={idx}>
                                <td> {activemoduleData.accessName} </td>
                                <td>{activemoduleData.accessDescription} </td>
                                <td>
                                  <center>
                                    {isChecked === 0 ? (
                                      <input
                                        style={{
                                          height: "40px",
                                          width: "40px",
                                          borderRadius: "50%",
                                          display: "block",
                                        }}
                                        name="moduleaccessId"
                                        type="checkbox"
                                        className="moduleaccessclass"
                                        id="moduleaccessId"
                                        onChange={(e) =>
                                          onAccesscheckSelect(
                                            e,
                                            activemoduleData
                                          )
                                        }
                                      />
                                    ) : (
                                      <input
                                        style={{
                                          height: "40px",
                                          width: "40px",
                                          borderRadius: "50%",
                                          display: "block",
                                        }}
                                        name="moduleaccessId"
                                        type="checkbox"
                                        className="moduleaccessclass"
                                        id="moduleaccessId"
                                        checked
                                        onChange={(e) =>
                                          onAccesscheckSelect(
                                            e,
                                            activemoduleData
                                          )
                                        }
                                      />
                                    )}
                                  </center>
                                </td>
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <form className="row" onSubmit={(e) => NextBackBtn(4)}>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <input
                type="submit"
                name="Save"
                value="Next"
                className="btn sub_form btn_continue Save float-right"
              />
              <button
                className="btn sub_form btn_continue Save float-right"
                onClick={() => NextBackBtn(2)}
              >
                Previous
              </button>
            </div>
          </form>
        </TabPanel> */}

       
      </Tabs>
      <section className="sub_reg">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <label className="label-control colorRed">
            * Indicates mandatory fields, Please fill mandatory fields before
            Submit
          </label>
        </div>
      </section>
    </Fragment>
  );
};

EditInstitutionDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  editInstitution: PropTypes.func.isRequired,
  getActiveModule: PropTypes.func.isRequired,
  getActivePermissions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  setting: state.setting,
});

export default connect(mapStateToProps, {
  editInstitution,
  getActiveModule,
  getActivePermissions,
})(EditInstitutionDetails);
